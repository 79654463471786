import { Component, OnInit } from "@angular/core";
import { Store, select } from "@ngrx/store";
import * as fromReducers from "../../store/reducers";
import { UntypedFormGroup, UntypedFormControl, Validators } from "@angular/forms";
import * as AuthActions from "../../store/actions/auth.actions";
import { confirmPasswordValidator } from "src/app/directives/confirm-password-validator.directive";
import { ThemeService } from "src/app/services/theme.service";
import { Title } from "@angular/platform-browser";
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.scss"],
})
export class ProfileComponent implements OnInit {
  constructor(
    private store: Store<fromReducers.State>,
    private themeService: ThemeService,
    private titleService: Title
  ) {
    this.titleService.setTitle("Profile");
  }
  // loggedInUser$ = this.store.pipe(select(fromReducers.getLoggedInUser));
  contacts$ = this.store.pipe(select(fromReducers.getContactsAll));

  userFirstName;
  userLastName;
  userId;
  userNameSubscription;

  isChangingPassword = false;
  isChangingPassword$ = this.store.pipe(
    select(fromReducers.getIsChangingPassword)
  );

  changePasswordForm = new UntypedFormGroup(
    {
      currentPasswordControl: new UntypedFormControl("", [Validators.required]),
      newPasswordControl: new UntypedFormControl("", [Validators.required]),
      confirmPasswordControl: new UntypedFormControl("", [Validators.required]),
    },
    { validators: confirmPasswordValidator }
  );

  loggedInUser$ = combineLatest(
    this.store.pipe(select(fromReducers.getLoggedInUser)),
    this.contacts$,
  ).pipe(
    map(([user, contacts]) => {
      if (
        user == null ||
        contacts == null
      ) {
        return;
      }
      let userContact = contacts.find(c=>c.emailAddress==user.email)
      return {
        ...user,
        firstName: userContact.firstName,
        lastName: userContact.lastName
      };
    })
  );

  cookiesPermission;
  cookiesAllowed;

  ngOnInit() {
    this.cookiesPermission = localStorage.getItem("cookies-allowed");
    this.cookiesAllowed = this.cookiesPermission == "true";
    // this.store.dispatch(new fromContactActions.GetContacts());

    // this should really combine user and contacts to filter for the contact
    // but I could't get that working
    this.userNameSubscription = this.contacts$.subscribe((contacts) => {
      if (contacts != undefined && contacts.length > 0) {
        this.userFirstName = contacts[0].firstName;
        this.userLastName = contacts[0].lastName;
      }
    });
  }

  ngOnDestroy() {
    if (this.userNameSubscription != null) {
      this.userNameSubscription.unsubscribe();
    }
  }

  updateCookies(){
    var boolString: string = String(!this.cookiesAllowed)
    this.cookiesAllowed = !this.cookiesAllowed;
    localStorage.setItem("cookies-allowed",boolString);

  }

  cancelChangePassword() {
    this.isChangingPassword = false;
    this.changePasswordForm.reset();
  }

  changePassword() {
    if (this.changePasswordForm.invalid) {
      return;
    }
    // this.dialogRef.close();
    this.store.dispatch(
      new AuthActions.ChangePassword({
        currentPassword: this.changePasswordForm.get("currentPasswordControl")
          .value,
        newPassword: this.changePasswordForm.get("newPasswordControl").value,
      })
    );
  }

  get otherTheme(): boolean {
    return this.themeService.otherTheme;
  }
  changeTheme() {
    this.themeService.changeTheme();
  }
}
