<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
<div *ngIf="loggedInUser$ | async" [class.alternative]="otherTheme">
    <footer>
        
        <br>
        <div class="container">
            <div class="row">
                <div class="col-lg-4" style="text-align: center;"><a mat-button href="mailto:help@simpson-associates.co.uk"><mat-icon> email</mat-icon> help@simpson-associates.co.uk</a></div>
                <div class="col-lg-4" style="text-align: center;"><a mat-button href="tel:+441904234539"><mat-icon> phone</mat-icon> 01904 234539</a></div>
                <div class="col-lg-4" style="text-align: center;"><a mat-button [routerLink]="['/help']">  <mat-icon>help_outline</mat-icon> How to use the portal</a></div>

            </div>
            <!-- <p style="text-align: center;">
                <mat-icon> email</mat-icon> help@simpson-associates.co.uk &nbsp;&nbsp;&nbsp;
                <mat-icon> phone</mat-icon>+44 (0) 1904 234 510 &nbsp;&nbsp;&nbsp;
                <a mat-button [routerLink]="['/help']"> How to use the portal <mat-icon>help_outline</mat-icon></a>
            </p> -->
            <hr>
            <p style="text-align: center;margin-top: 10px;">
                <a href="https://www.simpson-associates.co.uk" target="_blank" aria-label="https://www.simpson-associates.co.uk">
                    <button mat-fab color="primary">
                        <img
                            src="../../assets/logos/logo_new_small.svg"
                            id="icon"
                            height="35px"
                            width="35px"
                            alt="Simpson Associates"
                        />
                    </button>
                </a>
                <a href="https://twitter.com/simassoc" target="_blank" aria-label="https://twitter.com/simassoc">
                    <button mat-fab color="primary">
                        <i class="fa fa-twitter"style="font-size:35px;"></i>
                    </button>
                </a>
                <a href="https://www.linkedin.com/company/simpson-associates" target="_blank" aria-label="https://www.linkedin.com/company/simpson-associates">
                    <button mat-fab color="primary">
                        <i class="fa fa-linkedin"style="font-size:35px;"></i>
                    </button>
                </a>
            </p>
        </div>
        
        <br>
    </footer>
</div>