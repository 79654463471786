import { Action } from "@ngrx/store";
import { Case } from "src/app/models/case.model";
import { CloseAddNewPortalUserDialog } from "./user.actions";
import { SupportActivity } from "src/app/models/support-activity.model";
import { Attachment } from "src/app/models/attachment.model";

export enum SupportActivityActionTypes {
  
//   GetSupportActivitesForCase = "[Case] Get Support Activities For Case",
//   GetSupportActivitesForCaseSuccess = "[Case] Get Support Activities For Case Success",
//   GetSupportActivitesForCaseFailure = "[Case] Get Support Activities For Case Failure",
//   OpenUploadFileDialog = "[Case] Open 'Upload File' Dialog",
//   CloseUploadFileDialog = "[Case] Close 'Upload File' Dialog",

  GetSupportActivityAttachments = "[Support Activity] Get Support Activity Attachments",
  GetSupportActivityAttachmentsSuccess = "[Support Activity] Get Support Activity Attachments Success",
  GetSupportActivityAttachmentsFailure = "[Support Activity] Get Support Activity Attachments Failure",
  GetAttachmentsForCase = "[Support Activity] Get All Attachments For Case",
  GetAttachmentsForCaseSuccess = "[Support Activity] Get All Attachments For Case Success",
  GetAttachmentsForCaseFailure = "[Support Activity] Get All Attachments For Case Failure",
  DownloadSupportActivityAttachment = "[Support Activity] Download Attachment",
  UploadSupportActivityAttachment = "[Support Activity] Upload Attachment",
  UploadSupportActivityAttachmentSuccess = "[Support Activity] Upload Attachment Success",
  UploadSupportActivityAttachmentFailure = "[Support Activity] Upload Attachment Failure",
  OpenAddNewSupportActivityDialog = "[Support Activity] Open 'Add New Support Activity' Dialog",
  CloseAddNewSupportActivityDialog = "[Support Activity] Close 'Add New Support Activity' Dialog",
  AddNewSupportActivity = "[Support Activity] Add New Support Activity",
  AddNewSupportActivitySuccess = "[Support Activity] Add New Support Activity Success",
  AddNewSupportActivityFailure = "[Support Activity] Add New Support Activity Failure",
  GetSupportActivitesForCase = "[Case] Get Support Activities For Case",
  GetSupportActivitesForCaseSuccess = "[Case] Get Support Activities For Case Success",
  GetSupportActivitesForCaseFailure = "[Case] Get Support Activities For Case Failure"
}


export class GetSupportActivitesForCase implements Action {
  readonly type = SupportActivityActionTypes.GetSupportActivitesForCase;
  constructor(public payload: { caseCrmId: string }) {}
}
export class GetSupportActivitesForCaseSuccess implements Action {
  readonly type = SupportActivityActionTypes.GetSupportActivitesForCaseSuccess;
  constructor(public payload: { activities: SupportActivity[] }) {}
}
export class GetSupportActivitesForCaseFailure implements Action {
  readonly type = SupportActivityActionTypes.GetSupportActivitesForCaseFailure;
  constructor(public payload: { error: string }) {}
}

export class GetSupportActivityAttachments implements Action {
  readonly type = SupportActivityActionTypes.GetSupportActivityAttachments;
  constructor(public payload: { caseCrmId: string; activityCrmId: string }) {}
}
export class GetSupportActivityAttachmentsSuccess implements Action {
  readonly type = SupportActivityActionTypes.GetSupportActivityAttachmentsSuccess;
  constructor(public payload: { attachments: Attachment[] }) {}
}
export class GetSupportActivityAttachmentsFailure implements Action {
  readonly type = SupportActivityActionTypes.GetSupportActivityAttachmentsFailure;
}

export class GetAttachmentsForCase implements Action {
  readonly type = SupportActivityActionTypes.GetAttachmentsForCase;
  constructor(public payload: { caseCrmId: string }) {}
}
export class GetAttachmentsForCaseSuccess implements Action {
  readonly type = SupportActivityActionTypes.GetAttachmentsForCaseSuccess;
  constructor(public payload: { attachments: Attachment[] }) {}
}
export class GetAttachmentsForCaseFailure implements Action {
  readonly type = SupportActivityActionTypes.GetAttachmentsForCaseFailure;
}

export class DownloadSupportActivityAttachment implements Action {
  readonly type = SupportActivityActionTypes.DownloadSupportActivityAttachment;
  constructor(
    public payload: {
      caseCrmId: string;
      activityCrmId: string;
      attachmentCrmId: string;
    }
  ) {}
}

export class UploadSupportActivityAttachment implements Action {
  readonly type = SupportActivityActionTypes.UploadSupportActivityAttachment;
  constructor(
    public payload: {
      caseCrmId: string;
      activityCrmId: string;
    }
  ) {}
}
export class UploadSupportActivityAttachmentSuccess implements Action {
  readonly type = SupportActivityActionTypes.UploadSupportActivityAttachmentSuccess;
}
export class UploadSupportActivityAttachmentFailure implements Action {
  readonly type = SupportActivityActionTypes.UploadSupportActivityAttachmentFailure;
}

export class OpenAddNewSupportActivityDialog implements Action {
  readonly type = SupportActivityActionTypes.OpenAddNewSupportActivityDialog;
  constructor(public payload: { currentCase: Case }) {}
}
export class CloseAddNewSupportActivityDialog implements Action {
  readonly type = SupportActivityActionTypes.CloseAddNewSupportActivityDialog;
}

export class AddNewSupportActivity implements Action {
  readonly type = SupportActivityActionTypes.AddNewSupportActivity;
  constructor(public payload: { activity: SupportActivity, openActivity: Boolean }) {}
}
export class AddNewSupportActivitySuccess implements Action {
  readonly type = SupportActivityActionTypes.AddNewSupportActivitySuccess;
  constructor(public payload: { activity: SupportActivity }) {}
}
export class AddNewSupportActivityFailure implements Action {
  readonly type = SupportActivityActionTypes.AddNewSupportActivityFailure;
}

export type SupportActivityActions =
  | GetSupportActivitesForCase
  | GetSupportActivitesForCaseSuccess
  | GetSupportActivitesForCaseFailure
  | GetSupportActivityAttachments
  | GetSupportActivityAttachmentsSuccess
  | GetSupportActivityAttachmentsFailure
  | DownloadSupportActivityAttachment
  | UploadSupportActivityAttachment
  | UploadSupportActivityAttachmentSuccess
  | UploadSupportActivityAttachmentFailure
  | OpenAddNewSupportActivityDialog
  | CloseAddNewSupportActivityDialog
  | AddNewSupportActivity
  | AddNewSupportActivitySuccess
  | AddNewSupportActivityFailure
  | GetAttachmentsForCase
  | GetAttachmentsForCaseSuccess
  | GetAttachmentsForCaseFailure;
