import { Component, OnInit } from "@angular/core";
import { Store, select } from "@ngrx/store";
import * as fromReducers from "../../../store/reducers";
import * as fromCustomerActions from "../../../store/actions/customer.actions";
import * as fromContactActions from "../../../store/actions/contact.actions";
import * as fromUserActions from "../../../store/actions/user.actions";
import * as fromMetaDataActions from "../../../store/actions/metadata.actions";
import { combineLatest } from "rxjs";
import { map } from "rxjs/operators";
import { ThemeService } from 'src/app/services/theme.service';
import { Title } from '@angular/platform-browser';
import { User } from 'src/app/models/user.model';
import { ExportUser } from 'src/app/models/export-models/export-user.model';
import { ExportToCsvService } from 'src/app/services/export-to-csv.service';
import { NotificationService } from 'src/app/services/notification.service';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';

@Component({
  selector: "app-users",
  templateUrl: "./users.component.html",
  styleUrls: ["./users.component.scss"]
})
export class UsersComponent implements OnInit {
  constructor(
    private store: Store<fromReducers.State>,
    private themeService : ThemeService,
    private titleService : Title,
    private exportService : ExportToCsvService,
    private notificationService : NotificationService
    ) {
      this.titleService.setTitle("Manage Users");
    }

  showFilters = false;
  rows: User[] = [];
  temp: User[] = [];
  customerVal = "";
  emailVal = "";

  exportTitle = "SP Users Table";
  exportData = [];
  hideAdminsBool = false;

  userSubscription: any;
  simpAdminString = "Simpson Associates Admin";

  loggedInUser$ = this.store.pipe(select(fromReducers.getLoggedInUser));
  loggedInUserSubscrip;
  loggedInUser: User;
  currentUserId: number;

  isLoading$ = this.store.pipe(select(fromReducers.getUsersIsLoading));

  contacts$ = this.store.pipe(select(fromReducers.getContactsAll));

  users$ = combineLatest(
    this.store.pipe(select(fromReducers.getUsersAll)),
    this.contacts$,
    this.loggedInUser$
  ).pipe(
    map(([users, contacts, loggedInUser]) => {
      return users.map(u => {
        const crmContact = contacts.find(x => x.id === u.crmContactId);
        if(u.email == loggedInUser.email){
          this.currentUserId = +u.id;
        }
        return {
          ...u,
          crmContact
        };
        
      });
    })
  );

  getRowClass(row) {
    return {
      'inactive-user': (!row.isActive)
    };
  }

  ngOnInit() {
    this.store.dispatch(new fromCustomerActions.GetCustomers());
    this.store.dispatch(new fromContactActions.GetContacts());
    this.store.dispatch(new fromUserActions.GetUsers());
    this.userSubscription = this.users$.subscribe(users =>{
      this.rows = users as User[];
      this.temp = users as User[];
      // this.exportData = users.map(user=>this.convertUserToExportUser(user));
      if(this.hideAdminsBool){
        this.rows = this.rows.filter(u=>u.role.id!=3);
      }
    });
    this.loggedInUserSubscrip = this.loggedInUser$.subscribe(user=>{
      if(user != null){
        this.loggedInUser = user;
      }
    });
  }
  ngOnDestroy(): void {
    if (this.userSubscription != null) {
      this.userSubscription.unsubscribe();
    };
    if (this.loggedInUserSubscrip != null) {
      this.loggedInUserSubscrip.unsubscribe();
    }
  }

  convertUserToExportUser(user: User){
      let name, customer, role = "";
      if (user.crmContact != undefined){
        if(user.crmContact.firstName != undefined && user.crmContact.lastName != undefined){
          name = user.crmContact.firstName+" "+user.crmContact.lastName;
        }
        else if(user.crmContact.firstName != undefined){
          name = user.crmContact.firstName;
        }
        else if(user.crmContact.lastName != undefined){
          name = user.crmContact.lastName;
        }
      };
      if (user.crmContact != undefined && user.crmContact.customer != undefined){
        customer = user.crmContact.customer.name;
      };
      if (user.role != undefined){
        role = user.role.name;
      }
      return {
        active: user.isActive,
        name: name,
        email: user.email,
        customer: customer,
        role: role
      } as ExportUser;
  }

  openDialog() {
    this.store.dispatch(new fromUserActions.OpenAddNewPortalUserDialog());
  }
  openRequestUserDialog() {
    this.store.dispatch(new fromUserActions.OpenRequestUserDialog({currentUser: this.loggedInUser}));
  }

  toggleUserIsActive(userId: number){
    this.store.dispatch(new fromUserActions.ToggleUserIsActivate({userId, currentUserId: this.currentUserId}))
  }

  changeCustomerUserType(user: User){
    this.store.dispatch(new fromUserActions.OpenConfirmChangeUserTypeDialog({user}));
  }

  get otherTheme(): boolean {
    return this.themeService.otherTheme;
  }
  changeTheme(){
    this.themeService.changeTheme();
  }

  toggleFilterBox(){
    this.showFilters = !this.showFilters;
    this.hideAdminsBool = false;
    // reset filters
    this.rows = this.temp;
    this.customerVal ="";
    this.emailVal ="";

  }

  updateFilter(event,inputType) {
    if (inputType == 1){
      this.customerVal = event.target.value.toLowerCase();
    }
    else if(inputType == 2){
      this.emailVal = event.target.value.toLowerCase();
    };
    const customerVal = this.customerVal;
    const emailVal = this.emailVal;


    // filter our data
    const temp1 = this.temp.filter(function (d: User) {
      if(d != undefined && d.crmContact != null){
        return d.crmContact.customer.name.toLowerCase().indexOf(customerVal) !== -1 || !customerVal;
      }
      else if (customerVal == ""){
        return true;
      };
    });
    const temp2 = temp1.filter(function (d: User) {
      if(d != undefined && d.email != null){
        return d.email.toLowerCase().indexOf(emailVal) !== -1 || !emailVal;
      }
      else if (emailVal == ""){
        return true;
      };
      
    });

    // update the rows
    this.rows = temp2;
    // Whenever the filter changes, always go back to the first page
    // this.table.offset = 0;
  }

  

  export(title) {
    this.exportData = this.rows.map(user=>this.convertUserToExportUser(user));
    if (this.exportData.length>0){
      this.exportService.exportAsCSV(this.exportData,title);
    } else{
      this.notificationService.showError("Sorry there has been a problem exporting data: No Data", "Close");
    };
  }

  // impersonateUser(userId: number){
  //   console.log("Impersonating user with Id: "+userId);
  //   let chosenUser = this.temp.find(x=>x.id==userId);
  //   this.notificationService.showSuccess("Viewing the support portal as "+chosenUser.email,"close")
  // }

  refreshUsers(){
    this.store.dispatch(new fromCustomerActions.GetCustomers());
    this.store.dispatch(new fromContactActions.GetContacts());
    this.store.dispatch(new fromUserActions.GetUsers());
  }

  deleteUser(user: User){
    this.store.dispatch(new fromUserActions.OpenConfirmDeleteDialog({user: user}));
    // this.store.dispatch(new fromUserActions.DeleteUser({userId: userId}));
  }

  sendWelcomeEmail(user: User){
    this.store.dispatch(new fromUserActions.OpenConfirmSendEmailDialog({user: user}));
  }

  hideAdminsBoolForm: UntypedFormGroup = new UntypedFormGroup({
    hideAdminsBool: new UntypedFormControl("")
  });

  hideAdminsUpdate(){
    this.showFilters = false;
    if(!this.hideAdminsBool){
      this.rows = this.rows.filter(u=>u.role.id!=3);
    }
    else{
      this.rows = this.temp;
      this.showFilters = false;
    };
  }

  showUserDisableInfo(user: User){
    let lastDisabledUser = this.temp.find(u=>+u.id == user.lastDisabledUserId);
    if(user.lastDisabledDate != null && lastDisabledUser != null){
      this.store.dispatch(new fromMetaDataActions.OpenInformationDialog({title:"User Info",information:"Disabled on: "+new Date(user.lastDisabledDate).toLocaleString()+" by User: "+lastDisabledUser.email}));
    }
    else {
      this.store.dispatch(new fromMetaDataActions.OpenInformationDialog({title:"User Info",information:"Sorry, no information on when this user was disabled yet."}));
    }
  }

}
