<div [class.alternative]="otherTheme">
  <div class="page-container">
    <div class="row">
      <div class="col-md-2">
        <form [formGroup]="customerForm" *ngIf="(loggedInUser$ | async)?.isSimpsonsAdmin">
          <mat-form-field class="full-width">
            <mat-label>Customer</mat-label>
            <input
              matInput
              type="text"
              formControlName="customerControl"
              [typeahead]="customers$ | async"
              [typeaheadOptionsLimit]="8"
              [typeaheadLatinize]="true"
              typeaheadOptionField="name"
              [typeaheadItemTemplate]="customerSearchTemplate"
              (typeaheadOnSelect)="updateSelectedCustomer($event)"
              autocomplete="off"
            />
          </mat-form-field>
        </form>
      </div>
      <div class="col-md-8">
        <h1 style="text-align: center;">Manage Tokens</h1>
      </div>
      <div class="col-md-2"></div>
    </div>
    <hr />
    <h5 style="margin-top: 0.5rem;text-align: center;">Hourly Support Token Balance: <strong style="color: #2196f3;">{{tokenBalance}}</strong> Tokens </h5>

    <!-- Token Graph -->
    <div class="row" >
      <div class="col-lg-2">
      </div>
      <div class="col-lg-8">
        <div style="overflow-x:auto;">
          <div style="min-width: 700px;min-height: 400px">
            <ngx-charts-bar-vertical-2d
              [results]="tokenEventData$ | async"
              [legend]="true"
              [showXAxisLabel]="true"
              [showYAxisLabel]="true"
              [xAxis]="true"
              [yAxis]="true"
              [yAxisLabel]="'Tokens'"
              [barPadding]="2"
              [groupPadding]="2"
              [animations]="false"
              [scheme]="colorScheme2"
            >
            </ngx-charts-bar-vertical-2d>
          </div>
        </div>
      </div>
      <div class="col-lg-2"></div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        
        <!-- Information card for table -->
        <mat-card *ngIf="caseFilterEvent" style="max-width: 900px;margin: auto;" class="mb-2">
          <div class="row">
            <div class="col-sm-6">Token Activities for case</div>
            <div class="col-sm-5">Last Activity Date</div>
            <div class="col-sm-1"><button mat-button (click)="caseFilterEvent = null">Hide</button></div>
          </div>
          <hr>
          <div class="row">
            <div class="col-sm-6"><a [routerLink]="['/cases', caseFilterEvent.id]">Case: {{caseFilterEvent.caseNumber}}</a></div>
            <div class="col-sm-5">{{caseFilterEvent.createdOn | date: "dd/MM/yyyy HH:mm"}}</div>
            <div class="col-sm-1"></div>
          </div>
        </mat-card>
              
        <!-- Buttons above table -->
        <button 
          mat-raised-button
          (click)="back()"
          class="btn-primary mb-2"
          *ngIf="caseFilterEvent"
        >
          <mat-icon >arrow_back</mat-icon>Back
        </button>
        <button 
          mat-button
          aria-label="Export the table data"
          (click)="export(false)"
        >
          <mat-icon> get_app</mat-icon>Export
        </button>
        <button 
          mat-button
          aria-label="Export the table data"
          (click)="export(true)"
        >
          <mat-icon> get_app</mat-icon>Export All
        </button>
        <button
          mat-button
          (click)="refresh()"
        >
          <mat-icon>refresh</mat-icon>Refresh
        </button>
              
        <!-- Token Events table -->
        <div>
          <div style="overflow-x: auto;">
            <ngx-datatable
              class="material"
              [rows]="rows"
              [columnMode]="'flex'"
              [headerHeight]="50"
              [footerHeight]="50"
              [rowHeight]="50"
              [limit]="10"
              [loadingIndicator]="isLoadingTokenEvents$ |async"
              style="min-width: 850px;"
              [sorts]="[{ prop: 'createdOn', dir: 'desc' }]"
            >
              <ngx-datatable-column name="Event" [flexGrow]="1" prop="event">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  <div *ngIf="row.caseNumber != null">
                    <button mat-button color="primary" (click)="updateFilter(row)">
                      {{value}}
                    </button>
                  </div>
                  <div *ngIf="row.caseNumber == null" style="padding:0 16px;">
                    {{value}}
                  </div>
                  
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="Title" [flexGrow]="1" prop="title">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  <div  matTooltip="{{ row.title }}" >
                    {{value}}
                  </div>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="Date" [flexGrow]="1" prop="createdOn">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  <div  matTooltip=" {{ row.createdOn | date: 'dd/MM/yyyy HH:mm' }}" >
                    {{ row.createdOn | date: "dd/MM/yyyy" }}
                  </div>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="Tokens Purchased" [flexGrow]="1" prop="tokensAdded">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  <strong>{{value}}</strong>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="Tokens Used" [flexGrow]="1" prop="tokensDebited">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  <strong>{{value}}</strong>
                </ng-template>
              </ngx-datatable-column>
            </ngx-datatable>
          </div>
        </div>
      
      </div>
    </div>

  </div>
</div>

<ng-template #customerSearchTemplate let-customer="item" let-index="index">
  <p>
    {{ customer.name }}
  </p>
</ng-template>
  
    