import { Action } from "@ngrx/store";
import { Case } from "src/app/models/case.model";
import { CloseAddNewPortalUserDialog } from "./user.actions";
import { SupportActivity } from "src/app/models/support-activity.model";
import { Attachment } from "src/app/models/attachment.model";

export enum CaseActionTypes {
  GetCompanyCases = "[Case] Get Company Cases",
  GetCompanyCasesSuccess = "[Case] Get Company Cases Success",
  GetCompanyCasesFailure = "[Case] Get Company Cases Failure",
  SelectCase = "[Case] Select Case",
  CreateNewCase = "[Case] Create New Case",
  CreateNewCaseSuccess = "[Case] Create New Case Success",
  CreateNewCaseFailure = "[Case] Create New Case Failure",
  OpenCreateCaseDialog = "[Case] Open 'Create Case' Dialog",
  CloseCreateCaseDialog = "[Case] Close 'Create Case' Dialog",
  OpenCloseCaseDialog = "[Case] Open 'Close Case' Dialog",
  CloseCloseCaseDialog = "[Case] Close 'Close Case' Dialog",
  CloseCase = "[Case] Close Case",
  CloseCaseSuccess = "[Case] Close Case Success",
  CloseCaseFailure = "[Case] Close Case Failure",
  CustomerCloseCase = "[Case] Customer Close Case",
  CustomerCloseCaseSuccess = "[Case] Customer Close Case Success",
  CustomerCloseCaseFailure = "[Case] Customer Close Case Failure",
  OpenUploadFileDialog = "[Case] Open 'Upload File' Dialog",
  CloseUploadFileDialog = "[Case] Close 'Upload File' Dialog",
  OpenQuickUploadDialog = "[Case] Open Quick Upload Dialog",
  CloseQuickUploadDialog = "[Case] Close Quick Upload Dialog",
  OpenConfirmCustomerCloseDialog = "[Case] Open Confirm Customer Close Dialog"
}

export class GetCompanyCases implements Action {
  readonly type = CaseActionTypes.GetCompanyCases;
  constructor(public payload: { userCrmId: string }) {}
}
export class GetCompanyCasesSuccess implements Action {
  readonly type = CaseActionTypes.GetCompanyCasesSuccess;
  constructor(public payload: { cases: Case[] }) {}
}
export class GetCompanyCasesFailure implements Action {
  readonly type = CaseActionTypes.GetCompanyCasesFailure;
  constructor(public payload: { error: string }) {}
}

export class SelectCase implements Action {
  readonly type = CaseActionTypes.SelectCase;
  constructor(public payload: { caseId: string }) {}
}

export class CreateNewCase implements Action {
  readonly type = CaseActionTypes.CreateNewCase;
  constructor(public payload: { case: Case, redirect: boolean }) {}
}
export class CreateNewCaseSuccess implements Action {
  readonly type = CaseActionTypes.CreateNewCaseSuccess;
  constructor(public payload: { case: Case }) {}
}
export class CreateNewCaseFailure implements Action {
  readonly type = CaseActionTypes.CreateNewCaseFailure;
}

export class OpenCreateCaseDialog implements Action {
  readonly type = CaseActionTypes.OpenCreateCaseDialog;
}
export class CloseCreateCaseDialog implements Action {
  readonly type = CaseActionTypes.CloseCreateCaseDialog;
}

export class OpenCloseCaseDialog implements Action {
  readonly type = CaseActionTypes.OpenCloseCaseDialog;
  constructor(public payload: { caseCrmId: string }) {}
}
export class CloseCloseCaseDialog implements Action {
  readonly type = CaseActionTypes.CloseCloseCaseDialog;
}

export class CloseCase implements Action {
  readonly type = CaseActionTypes.CloseCase;
  constructor(public payload: { caseCrmId: string; closureReason: string; statusCode: number; }) {}
}
export class CloseCaseSuccess implements Action {
  readonly type = CaseActionTypes.CloseCaseSuccess;
  constructor(public payload: { closedCase: Case }) {}
}
export class CloseCaseFailure implements Action {
  readonly type = CaseActionTypes.CloseCaseFailure;
}

export class CustomerCloseCase implements Action {
  readonly type = CaseActionTypes.CustomerCloseCase;
  constructor(public payload: { caseCrmId: string; }) {}
}
export class CustomerCloseCaseSuccess implements Action {
  readonly type = CaseActionTypes.CustomerCloseCaseSuccess;
  constructor(public payload: { closedCase: Case }) {}
}
export class CustomerCloseCaseFailure implements Action {
  readonly type = CaseActionTypes.CustomerCloseCaseFailure;
}

export class OpenUploadFileDialog implements Action {
  readonly type = CaseActionTypes.OpenUploadFileDialog;
  constructor(public payload: { caseCrmId: string; activityCrmId: string }) {}
}
export class CloseUploadFileDialog implements Action {
  readonly type = CaseActionTypes.CloseUploadFileDialog;
}

export class OpenQuickUploadDialog implements Action {
  readonly type = CaseActionTypes.OpenQuickUploadDialog;
  constructor(public payload: { case: Case  }) {}
}
export class CloseQuickUploadDialog implements Action {
  readonly type = CaseActionTypes.CloseQuickUploadDialog;
}
export class OpenConfirmCustomerCloseDialog implements Action {
  readonly type = CaseActionTypes.OpenConfirmCustomerCloseDialog;
  constructor(public payload: { caseId: string }) {}
}

export type CaseActions =
  | GetCompanyCases
  | GetCompanyCasesSuccess
  | GetCompanyCasesFailure
  | SelectCase
  | CreateNewCase
  | CreateNewCaseSuccess
  | CreateNewCaseFailure
  | OpenCreateCaseDialog
  | CloseCreateCaseDialog
  | OpenCloseCaseDialog
  | CloseCloseCaseDialog
  | CloseCase
  | CloseCaseSuccess
  | CloseCaseFailure
  | CustomerCloseCase
  | CustomerCloseCaseSuccess
  | CustomerCloseCaseFailure
  // | GetSupportActivitesForCase
  // | GetSupportActivitesForCaseSuccess
  // | GetSupportActivitesForCaseFailure
  | OpenUploadFileDialog
  | CloseUploadFileDialog
  | OpenConfirmCustomerCloseDialog;
