<div class="row mb-5" style="width: 100%;">
    <div class="col-lg-4">
      <mat-card
        class="mat-elevation-z8 text-center clickable-card hover-colour"
        [routerLink]="['/cases/my-cases']"
      >
        <h6>My Cases</h6>
      </mat-card>
    </div>
    <div class="col-lg-4">
      <mat-card
        class="mat-elevation-z8 text-center clickable-card hover-colour"
        [routerLink]="['/cases/company-cases']"
      >
        <h6>Company Cases</h6>
      </mat-card>
    </div>
    <div class="col-lg-4">
      <mat-card
        class="mat-elevation-z8 text-center clickable-card hover-colour"
        (click)="openNewCaseDialog()"
      >
        <h6>Create New Case</h6>
      </mat-card>
    </div>
  </div>