import { BrowserModule, Title } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { WelcomeComponent } from "./components/welcome/welcome.component";
import { StoreModule } from "@ngrx/store";
import { reducers, metaReducers } from "./store/reducers";
import { EffectsModule } from "@ngrx/effects";

import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AuthService } from "./services/auth.service";
import { AuthEffects } from "./store/effects/auth.effects";
import { HttpClientModule, HttpClientXsrfModule } from "@angular/common/http";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import {
  TokenInterceptor,
  ErrorInterceptor,
} from "./services/token.interceptor";

import { AuthGuardService as AuthGuard } from "./services/auth-guard.service";
import { NavbarComponent } from "./components/navbar/navbar.component";
import { NewCaseComponent } from "./components/cases/new-case/new-case.component";
import { MyCasesComponent } from "./components/cases/my-cases/my-cases.component";
import { CompanyCasesComponent } from "./components/cases/company-cases/company-cases.component";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { TokensComponent } from "./components/manage/tokens/tokens.component";
import { ReportsComponent } from "./components/manage/reports/reports.component";
import { UsersComponent } from "./components/manage/users/users.component";
import { ProfileComponent } from "./components/profile/profile.component";
import { AuthCallbackComponent } from "./components/auth-callback/auth-callback.component";
import { DashboardComponent } from "./components/dashboard/dashboard.component";
import { CaseEffects } from "./store/effects/case.effects";
import { environment } from "src/environments/environment";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { CaseComponent } from "./components/cases/case/case.component";
import { SharedModule } from "./modules/shared.module";
import { ConfirmPasswordValidatorDirective } from "./directives/confirm-password-validator.directive";
import { LoadingComponent } from "./components/loading/loading.component";
import { MetadataEffects } from "./store/effects/metadata.effects";
import { ContactEffects } from "./store/effects/contact.effects";
import { CustomerEffects } from "./store/effects/customer.effects";
import { UserEffects } from "./store/effects/user.effects";
import { AddUserDialogComponent } from "./components/manage/users/add-user-dialog/add-user-dialog.component";
import { NgxChartsModule } from "@swimlane/ngx-charts";
import { CloseCaseDialogComponent } from "./components/cases/case/close-case-dialog/close-case-dialog.component";
import { CasesTableComponent } from "./components/cases/cases-table/cases-table.component";
import { SupportActivitiesComponent } from "./components/cases/case/support-activities/support-activities.component";
import { SupportActivityComponent } from "./components/cases/case/support-activities/support-activity/support-activity.component";
import { UploadFileDialogComponent } from "./components/cases/case/support-activities/support-activity/upload-file-dialog/upload-file-dialog.component";
import { NgxUploaderModule } from "ngx-uploader";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatMenuModule } from "@angular/material/menu";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatExpansionModule } from "@angular/material/expansion";
import { AddActivityDialogComponent } from "./components/cases/case/support-activities/add-activity-dialog/add-activity-dialog.component";
import { SupportActivityEffects } from "./store/effects/support-activity.effects";
import { DashboardNavComponent } from "./components/dashboard/dashboard-nav/dashboard-nav.component";
import { ThemeService } from "./services/theme.service";
import { ExportToCsvService } from "./services/export-to-csv.service";
import { FooterComponent } from "./components/footer/footer.component";

import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { ConfirmDialogComponent } from "./components/confirm-dialog/confirm-dialog.component";
import { QuickUploadDialogComponent } from "./components/cases/case/quick-upload-dialog/quick-upload-dialog.component";
import { HelpComponent } from "./components/help/help.component";
import { EntitlementEffects } from "./store/effects/entitlement.effects";
import { TokenCaseTableComponent } from "./components/manage/tokens/token-case-table/token-case-table.component";
import { RequestUserDialogComponent } from "./components/manage/users/request-user-dialog/request-user-dialog.component";
import { InformationDialogComponent } from "./components/information-dialog/information-dialog.component";
import { CookieBannerComponent } from "./components/cookie-banner/cookie-banner.component";

@NgModule({
    declarations: [
        AppComponent,
        WelcomeComponent,
        NavbarComponent,
        NewCaseComponent,
        MyCasesComponent,
        CompanyCasesComponent,
        TokensComponent,
        ReportsComponent,
        UsersComponent,
        ProfileComponent,
        AuthCallbackComponent,
        DashboardComponent,
        CaseComponent,
        ConfirmPasswordValidatorDirective,
        LoadingComponent,
        AddUserDialogComponent,
        CloseCaseDialogComponent,
        CasesTableComponent,
        SupportActivitiesComponent,
        SupportActivityComponent,
        UploadFileDialogComponent,
        AddActivityDialogComponent,
        DashboardNavComponent,
        FooterComponent,
        ConfirmDialogComponent,
        QuickUploadDialogComponent,
        HelpComponent,
        TokenCaseTableComponent,
        RequestUserDialogComponent,
        InformationDialogComponent,
        CookieBannerComponent,
    ],
    imports: [
        BrowserModule,
        // FormsModule,
        FontAwesomeModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        HttpClientModule,
        HttpClientXsrfModule.withOptions({
            cookieName: "_SA.SupportPortal.Antiforgery.Request",
            // The below is the default value for the header Angular will send the XSRF token in, although added here for completeness
            headerName: "X-XSRF-TOKEN",
        }),
        NgxDatatableModule,
        NgxChartsModule,
        NgxUploaderModule,
        MatIconModule,
        MatInputModule,
        MatProgressBarModule,
        MatMenuModule,
        MatToolbarModule,
        MatExpansionModule,
        MatTooltipModule,
        EffectsModule.forRoot([
            AuthEffects,
            CaseEffects,
            MetadataEffects,
            ContactEffects,
            CustomerEffects,
            UserEffects,
            SupportActivityEffects,
            EntitlementEffects,
        ]),
        RouterModule,
        StoreModule.forRoot(reducers, {
            metaReducers,
            runtimeChecks: {
                strictStateImmutability: true,
                strictActionImmutability: true,
            },
        }),
        !environment.production ? StoreDevtoolsModule.instrument() : [],
        SharedModule,
    ],
    providers: [
        Title,
        AuthService,
        ThemeService,
        ExportToCsvService,
        AuthGuard,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorInterceptor,
            multi: true,
        },
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
