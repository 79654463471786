<!-- <mat-card class="new-case-form-card mat-elevation-z8"> -->
  <!-- <div [class.alternative]="otherTheme"> -->
  <h3 class="text-center">New Case</h3>
  <hr />
  <form [formGroup]="newCaseForm">
    <mat-vertical-stepper linear #stepper>
      <mat-step
        formGroupName="caseTypeGroup"
        [stepControl]="newCaseForm.get('caseTypeGroup')"
      >
        <ng-template matStepLabel
          >What kind of case are you logging?</ng-template
        >
        <div class="row">
          <div class="col-lg-12">
            <mat-form-field class="full-width">
              <mat-label>Case Type</mat-label>
              <mat-select formControlName="caseTypeControl" required>
                <mat-option
                  *ngFor="let caseType of caseTypes"
                  [value]="caseType.value"
                  (click)="updateChoice()"
                >
                  {{ caseType.label }}
                </mat-option>
              </mat-select>
              <mat-error>You must select a case type</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-lg-12">
            <button class="btn-block" mat-button matStepperNext type="button">
              Next
            </button>
          </div>
        </div>
      </mat-step>
      <mat-step
        formGroupName="priorityGroup"
        [stepControl]="newCaseForm.get('priorityGroup')"
      >
        <ng-template matStepLabel
          >Please help us to prioritise your case</ng-template
        >
        <div class="row">
          <div class="col-lg-12">
            <mat-form-field class="full-width">
              <mat-label>Priority</mat-label>
              <mat-select formControlName="priorityControl" required>
                <mat-option
                  *ngFor="let priority of priorityTypes"
                  [value]="priority.value"
                >
                  {{ priority.label }}
                </mat-option>
              </mat-select>
              <mat-error>You must select a priority for the case</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <mat-hint
              *ngIf="
                (newCaseForm.get('priorityGroup').get('priorityControl').value == 1) ||
                (newCaseForm.get('priorityGroup').get('priorityControl').value == 2)
              "
            >
              <p style="max-width: 400px;color: red;">
              To help us assist with your case as fast as possible,
              we recommend calling us on
              <a href="tel:+441904234539">01904 234539</a>
              </p>
            </mat-hint>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-lg-12">
            <button class="btn-block" mat-button matStepperNext type="button">
              Next
            </button>
          </div>
        </div>
      </mat-step>
      <mat-step
        formGroupName="detailsGroup"
        [stepControl]="newCaseForm.get('detailsGroup')"
      >
        <ng-template matStepLabel>{{q3Message}}</ng-template>
        <div class="row">
          <div class="col-lg-12">
            <mat-form-field class="full-width">
              <mat-label>Title</mat-label>
              <input
                matInput
                placeholder="Please enter a case title"
                formControlName="titleControl"
                required
                autocomplete="off"
              />
              <mat-error>You must provide a case title</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <mat-form-field class="full-width">
              <mat-label>Description</mat-label>
              <textarea
                matInput
                placeholder="Please enter a description to help us better understand your issue"
                formControlName="descriptionControl"
                required
                autocomplete="off"
                rows="4"
              ></textarea>
              <mat-error
                >Please provide a description of your issue so we can assist as
                much as possible</mat-error
              >
            </mat-form-field>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-lg-6">
            <button
              class="btn-block"
              mat-button
              matStepperPrevious
              type="button"
            >
              Back
            </button>
          </div>
          <div class="col-lg-6">
            <button class="btn-block" mat-button matStepperNext type="button">
              Next
            </button>
          </div>
        </div>
      </mat-step>
      <mat-step>
        <ng-template matStepLabel>Create Case</ng-template>
        <p>Click the button below to create a new case</p>
        <div class="row">
          <div class="col-lg-6">
            <button
              class="btn-block"
              mat-button
              matStepperPrevious
              type="button"
            >
              Back
            </button>
          </div>
          <div class="col-lg-6">
            <button
              class="btn-block btn-info"
              mat-button
              type="button"
              (click)="createNewCase()"
              [disabled]="isCreatingCase$ | async"
              [class.disabled]="isCreatingCase$ | async"
            >
              <span *ngIf="isCreatingCase$ | async"
                ><fa-icon
                  class="mr-2"
                  [icon]="['fas', 'spinner']"
                  [spin]="true"
                ></fa-icon
                >Please wait...</span
              >
              <span *ngIf="!(isCreatingCase$ | async)">Create</span>
            </button>
          </div>
        </div>
      </mat-step>
    </mat-vertical-stepper>
  </form>
  <mat-dialog-actions align="start">
    <button mat-button [mat-dialog-close]="" >CANCEL</button>
  </mat-dialog-actions>
<!-- </div> -->
<!-- </mat-card> -->
