import {
  MetadataActionTypes,
  MetadataActions
} from "../actions/metadata.actions";
import { Status } from "src/app/models/status.model";
import { Role } from "src/app/models/role.model";
import { Type } from "src/app/models/type.model";
import { Priority } from "src/app/models/priority.model";
import { StatusReason } from "src/app/models/status-reason.model";
import { ActivityType } from "src/app/models/activity-type.model";
import { PasswordStatus } from 'src/app/models/password-status.model';

export interface State {
  isLoading: boolean;
  statusReasonCodes: StatusReason[];
  stateCodes: Status[];
  priorityCodes: Priority[];
  typeCodes: Type[];
  roles: Role[];
  passwordStatusList: PasswordStatus[];
  activityTypes: ActivityType[];
}

export const initialState: State = {
  isLoading: false,
  statusReasonCodes: [],
  stateCodes: [],
  priorityCodes: [],
  typeCodes: [],
  roles: [],
  passwordStatusList: [],
  activityTypes: []
};

export function reducer(state = initialState, action: MetadataActions): State {
  switch (action.type) {
    // ########################## Status Reason Codes ##########################

    case MetadataActionTypes.GetStatusReasonCodes: {
      return {
        ...state,
        isLoading: true
      };
    }
    case MetadataActionTypes.GetStatusReasonCodesSuccess: {
      return {
        ...state,
        isLoading: false,
        statusReasonCodes: action.payload.statusCodes
      };
    }
    case MetadataActionTypes.GetStatusReasonCodesFailure: {
      return {
        ...state,
        isLoading: false
      };
    }
    // ########################## State Codes ##########################

    case MetadataActionTypes.GetStateCodes: {
      return {
        ...state,
        isLoading: true
      };
    }
    case MetadataActionTypes.GetStateCodesSuccess: {
      return {
        ...state,
        isLoading: false,
        stateCodes: action.payload.stateCodes
      };
    }
    case MetadataActionTypes.GetStateCodesFailure: {
      return {
        ...state,
        isLoading: false
      };
    }

    // ########################## Priority Codes ##########################

    case MetadataActionTypes.GetPriorityCodes: {
      return {
        ...state,
        isLoading: true
      };
    }
    case MetadataActionTypes.GetPriorityCodesSuccess: {
      return {
        ...state,
        isLoading: false,
        priorityCodes: action.payload.priorityCodes
      };
    }
    case MetadataActionTypes.GetPriorityCodesFailure: {
      return {
        ...state,
        isLoading: false
      };
    }

    // ########################## Type Codes ##########################

    case MetadataActionTypes.GetTypeCodes: {
      return {
        ...state,
        isLoading: true
      };
    }
    case MetadataActionTypes.GetTypeCodesSuccess: {
      return {
        ...state,
        isLoading: false,
        typeCodes: action.payload.typeCodes
      };
    }
    case MetadataActionTypes.GetTypeCodesFailure: {
      return {
        ...state,
        isLoading: false
      };
    }

    // ########################## Roles ##########################

    case MetadataActionTypes.GetRoles: {
      return {
        ...state,
        isLoading: true
      };
    }
    case MetadataActionTypes.GetRolesSuccess: {
      return {
        ...state,
        isLoading: false,
        roles: action.payload.roles
      };
    }
    case MetadataActionTypes.GetRolesFailure: {
      return {
        ...state,
        isLoading: false
      };
    }

    // ########################## Activity Type ##########################

    case MetadataActionTypes.GetActivityTypes: {
      return {
        ...state,
        isLoading: true
      };
    }

    case MetadataActionTypes.GetActivityTypesSuccess: {
      return {
        ...state,
        isLoading: false,
        activityTypes: action.payload.activityTypes
      };
    }

    case MetadataActionTypes.GetActivityTypesFailure: {
      return {
        ...state,
        isLoading: false
      };
    }

    // ########################## Password Status List ##########################

    case MetadataActionTypes.GetPasswordStatusList: {
      return {
        ...state,
        isLoading: true
      };
    }
    case MetadataActionTypes.GetPasswordStatusListSuccess: {
      return {
        ...state,
        isLoading: false,
        passwordStatusList: action.payload.passwordStatusList
      };
    }
    case MetadataActionTypes.GetPasswordStatusListFailure: {
      return {
        ...state,
        isLoading: false
      };
    }

    default: {
      return state;
    }
  }
}

export const getIsLoading = (state: State) => state.isLoading;
export const getStatusReasonCodes = (state: State) => state.statusReasonCodes;
export const getStateCodes = (state: State) => state.stateCodes;
export const getPriorityCodes = (state: State) => state.priorityCodes;
export const getTypeCodes = (state: State) => state.typeCodes;
export const getRoles = (state: State) => state.roles;
export const getPasswordStatusList = (state: State) => state.passwordStatusList;
export const getActivityTypes = (state: State) => state.activityTypes;
