import { User } from "../../models/user.model";
import { AuthActionTypes, All } from "../actions/auth.actions";

export interface State {
  // is a user authenticated?
  isAuthenticated: boolean;
  // if authenticated, there should be a user object
  user: User;
  isChangingPassword: boolean;
  isLoggingIn: boolean;
}

export const initialState: State = {
  isAuthenticated: false,
  user: null,
  isChangingPassword: false,
  isLoggingIn: false
};

export function reducer(state = initialState, action: All): State {
  switch (action.type) {
    case AuthActionTypes.LOGIN: {
      return {
        ...state,
        isLoggingIn: true
      };
    }
    case AuthActionTypes.LOGIN_SUCCESS: {
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        isLoggingIn: false
      };
    }
    case AuthActionTypes.LOGIN_FAILURE: {
      return {
        ...state,
        isLoggingIn: false
      };
    }
    case AuthActionTypes.LOGOUT: {
      return {
        ...state,
        user: null,
        isAuthenticated: false
      };
    }

    case AuthActionTypes.LOGIN_CALLBACK: {
      return {
        ...state
      };
    }

    case AuthActionTypes.LOGIN_INITIAL_LOAD: {
      return {
        ...state
      };
    }

    case AuthActionTypes.LOGIN_VALIDATION: {
      return {
        ...state
      };
    }

    case AuthActionTypes.LOGIN_VALIDATED: {
      return {
        ...state,
        user: action.payload.user,
        isAuthenticated: true
      };
    }

    case AuthActionTypes.LOGIN_NOT_VALIDATED: {
      return {
        ...state,
        user: null,
        isAuthenticated: false
      };
    }

    case AuthActionTypes.CHANGE_PASSWORD: {
      return {
        ...state,
        isChangingPassword: true
      };
    }
    case AuthActionTypes.CHANGE_PASSWORD_SUCCESS: {
      return {
        ...state,
        isChangingPassword: false
      };
    }
    case AuthActionTypes.CHANGE_PASSWORD_FAILURE: {
      return {
        ...state,
        isChangingPassword: false
      };
    }

    default: {
      return state;
    }
  }
}

export const getLoggedInUser = (state: State) => state.user;
export const getLoggedInUserIsAuthenticated = (state: State) =>
  state.isAuthenticated;
export const getIsChangingPassword = (state: State) => state.isChangingPassword;

export const getIsLoggingIn = (state: State) => state.isLoggingIn;
