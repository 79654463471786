import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Observable, of } from "rxjs";
import { UserActionTypes } from "../actions/user.actions";
import { mergeMap, map, catchError, exhaustMap } from "rxjs/operators";
import * as UserActions from "../actions/user.actions";
import * as CaseActions from "../actions/case.actions";
import { UserService } from "src/app/services/user.service";
import { Action } from "@ngrx/store";
import { MatDialog } from "@angular/material/dialog";
import { AddUserDialogComponent } from "src/app/components/manage/users/add-user-dialog/add-user-dialog.component";
import { AddUserRequest } from "src/app/models/add-user-request.model";
import { ErrorService } from "src/app/services/error.service";
import { ProfileComponent } from 'src/app/components/profile/profile.component';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { NotificationService } from 'src/app/services/notification.service';
import { RequestUserDialogComponent } from 'src/app/components/manage/users/request-user-dialog/request-user-dialog.component';

@Injectable()
export class UserEffects {
  constructor(
    private actions$: Actions,
    private userService: UserService,
    private errorService: ErrorService,
    private notificationService: NotificationService,
    private dialog: MatDialog
  ) {}
  
  getUsers$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<UserActions.GetUsers>(UserActionTypes.GetUsers),
    mergeMap(action => {
      return this.userService.getUsers().pipe(
        map(users => {
          return new UserActions.GetUsersSuccess({ users });
        }),
        catchError(error => {
          console.error("Error: ", error);
          return of(new UserActions.GetUsersFailure());
        })
      );
    })
  ));

  
  addNewPortalUser$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<UserActions.AddNewPortalUser>(UserActionTypes.AddNewPortalUser),
    mergeMap(action => {
      return this.userService
        .addNewPortalUser(action.payload.addUserRequest)
        .pipe(
          map(user => {
            this.notificationService.showSuccess("User added","close");
            return new UserActions.AddNewPortalUserSuccess({ user });
          }),
          catchError(error => {
            this.errorService.handleError(
              error,
              "Sorry, there's been a problem creating this user"
            );
            return of(new UserActions.AddNewPortalUserFailure());
          })
        );
    })
  ));

  
  openAddNewPortalUserDialog = createEffect(() => this.actions$.pipe(
    ofType<UserActions.OpenAddNewPortalUserDialog>(
      UserActionTypes.OpenAddNewPortalUserDialog
    ),
    exhaustMap(action => {
      const dialogRef = this.dialog.open(AddUserDialogComponent, {
        autoFocus: false
      });
      return dialogRef.afterClosed();
    }),
    map((data: any) => {
      if (data == null || data.req == null) {
        return new UserActions.CloseAddNewPortalUserDialog();
      } else {
        return new UserActions.AddNewPortalUser({
          addUserRequest: data.req
        });
      }
    })
  ));

  
  deactivateUser$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<UserActions.ToggleUserIsActivate>(UserActionTypes.ToggleUserIsActivate),
    mergeMap(action => {
      return this.userService
        .toggleUserIsActivate(action.payload.userId, action.payload.currentUserId)
        .pipe(
          map(user => {
            return new UserActions.ToggleUserIsActivateSuccess({ user });
          }),
          catchError(error => {
            this.errorService.handleError(error,"Error changing active status for user");
            return of(new UserActions.ToggleUserIsActivateFailure());
          })
        );
    })
  ));

  
  changeCustomerUserType$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<UserActions.ChangeCustomerUserType>(UserActionTypes.ChangeCustomerUserType),
    mergeMap(action => {
      return this.userService
        .changeCustomerUserType(action.payload.userId)
        .pipe(
          map(user => {
            this.notificationService.showSuccess("User type updated","close");
            return new UserActions.ChangeCustomerUserTypeSuccess({ user });
          }),
          catchError(error => {
            this.errorService.handleError(error,"Error changing user type");
            return of(new UserActions.ChangeCustomerUserTypeFailure());
          })
        );
    })
  ));

  
  openProfileDialog = createEffect(() => this.actions$.pipe(
    ofType<UserActions.OpenProfileDialog>(
      UserActionTypes.OpenProfileDialog
    ),
    exhaustMap(action => {
      const dialogRef = this.dialog.open(ProfileComponent, {
        autoFocus: false
      });
      return dialogRef.afterClosed();
    }),
    map((data: any) => {
      return new UserActions.CloseProfileDialog();
    })
  ));

  
  deleteUser$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<UserActions.DeleteUser>(UserActionTypes.DeleteUser),
    mergeMap(action => {
      return this.userService
        .deleteUser(action.payload.userId)
        .pipe(
          map(user => {
            this.notificationService.showSuccess("Deleted user "+user.email,"close");
            return new UserActions.DeleteUserSuccess({ user });
          }),
          catchError(error => {
            this.errorService.handleError(error,"Error deleting user");
            return of(new UserActions.DeleteUserFailure());
          })
        );
    })
  ));

  
  openConfirmDeleteDialog = createEffect(() => this.actions$.pipe(
    ofType<UserActions.OpenConfirmDeleteDialog>(
      UserActionTypes.OpenConfirmDeleteDialog
    ),
    exhaustMap(action => {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        autoFocus: false,
        data: { userId: action.payload.user.id,
                title: "Delete "+action.payload.user.email,
                message: "Are you sure you want to delete the portal account for "+action.payload.user.email+"?" }
      });
      return dialogRef.afterClosed();
    }),
    map((data: any) => {
      if(data == null || data.req == false){
        return new UserActions.CloseConfirmDialog();
      }
      else if(data.data.userId != null && data.data.userId != undefined){
        return new UserActions.DeleteUser({userId: data.data.userId});
      }
      else{
        return new UserActions.CloseConfirmDialog();
      }
    })
  ));

  
  openConfirmSendEmailDialog = createEffect(() => this.actions$.pipe(
    ofType<UserActions.OpenConfirmSendEmailDialog>(
      UserActionTypes.OpenConfirmSendEmailDialog
    ),
    exhaustMap(action => {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        autoFocus: false,
        data: { user: action.payload.user,
                title: "Send Email to "+action.payload.user.email,
                message: "Are you sure you want to send a welcome email to "+action.payload.user.email+"?" }
      });
      return dialogRef.afterClosed();
    }),
    map((data: any) => {
      console.log(data.data.user);
      if(data == null || data.req == false){
        return new UserActions.CloseConfirmDialog();
      }
      else if(data.data.user != null && data.data.user != undefined){
        return new UserActions.SendWelcomeEmail({user: data.data.user});
      }
      else{
        return new UserActions.CloseConfirmDialog();
      }
    })
  ));

  
  openConfirmChangeUserTypeDialog = createEffect(() => this.actions$.pipe(
    ofType<UserActions.OpenConfirmChangeUserTypeDialog>(
      UserActionTypes.OpenConfirmChangeUserTypeDialog
    ),
    exhaustMap(action => {
      let titleMsg;
      let msg;
      if(action.payload.user.role.id == 1){
        titleMsg = "Promote to Customer Superuser";
        msg = "Are you sure you would like to give "+action.payload.user.crmContact.firstName+" "+action.payload.user.crmContact.lastName+" access to user management and token reporting?";
      }
      else{
        titleMsg = "Demote to Customer User";
        msg = "Are you sure you would like to remove "+action.payload.user.crmContact.firstName+" "+action.payload.user.crmContact.lastName+"'s access to user management and token reporting?";
      }
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        autoFocus: false,
        data: { user: action.payload.user,
                title: titleMsg,
                message: msg }
      });
      return dialogRef.afterClosed();
    }),
    map((data: any) => {
      console.log(data);
      if(data == null || data == undefined){
        return new UserActions.CloseConfirmDialog();
      }
      else if(data.data.user != null && data.data.user != undefined){
        return new UserActions.ChangeCustomerUserType({userId: data.data.user.id});
      }
      else{
        return new UserActions.CloseConfirmDialog();
      }
    })
  ));


  
  sendWelcomeEmail$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<UserActions.SendWelcomeEmail>(UserActionTypes.SendWelcomeEmail),
    mergeMap(action => {
      return this.userService
        .sendWelcomeEmail(action.payload.user)
        .pipe(
          map(user => {
            this.notificationService.showSuccess("Sent welcome email to "+user.email,"close");
            return new UserActions.SendWelcomeEmailSuccess({user});
          }),
          catchError(error => {
            this.errorService.handleError(error,"Error finding user");
            return of(new UserActions.SendWelcomeEmailFailure());
          })
        );
    })
  ));

  
  openRequestUserDialog = createEffect(() => this.actions$.pipe(
    ofType<UserActions.OpenRequestUserDialog>(
      UserActionTypes.OpenRequestUserDialog
    ),
    exhaustMap(action => {
      const dialogRef = this.dialog.open(RequestUserDialogComponent, {
        autoFocus: false,
        data: { currentUser: action.payload.currentUser}
      });
      return dialogRef.afterClosed();
    }),
    map((data: any) => {
      if(data == null || data == undefined){
        return new UserActions.CloseRequestUserDialog();
      }
      else if(data.case != null && data.case != undefined){
        return new CaseActions.CreateNewCase({case: data.case, redirect: false});
      }
      else{
        return new UserActions.CloseRequestUserDialog();
      }
    })
  ));


  // @Effect()
  // requestUser$: Observable<Action> = this.actions$.pipe(
  //   ofType<UserActions.RequestUser>(UserActionTypes.RequestUser),
  //   mergeMap(action => {
  //     return this.userService
  //       .requestUser(action.payload.reqUser)
  //       .pipe(
  //         map(reqUser => {
  //           this.notificationService.showSuccess("Requested User Account for "+reqUser.firstName +" "+reqUser.lastName,"close");
  //           return new UserActions.RequestUserSuccess();
  //         }),
  //         catchError(error => {
  //           this.errorService.handleError(
  //             error,
  //             "Sorry, there's been a problem requesting this user"
  //           );
  //           return of(new UserActions.RequestUserFailure());
  //         })
  //       );
  //   })
  // );


}
