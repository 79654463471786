import { Component, OnInit } from "@angular/core";
import { Store, select } from "@ngrx/store";
import * as fromReducers from "../../../store/reducers";
import * as fromCustomerActions from "../../../store/actions/customer.actions";
import * as EntitlementActions from "../../../store/actions/entitlement.actions";
import * as fromUserActions from "../../../store/actions/user.actions";
import { combineLatest } from "rxjs";
import { map } from "rxjs/operators";
import { ThemeService } from 'src/app/services/theme.service';
import { TokenEvent, TokenEventTypes } from 'src/app/models/tokens/token-event.model'
import { Case } from 'src/app/models/case.model';
import { User } from 'src/app/models/user.model';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ExportToCsvService } from 'src/app/services/export-to-csv.service';
import { NotificationService } from 'src/app/services/notification.service';
import { Entitlement } from 'src/app/models/tokens/entitlement.model';
import { TokenMonth } from 'src/app/models/tokens/token-month.model';
import { AttachSession } from 'protractor/built/driverProviders';
import { SupportActivity } from 'src/app/models/support-activity.model';
import { Customer } from "src/app/models/customer.model";
import { TypeaheadMatch } from "ngx-bootstrap";

@Component({
  selector: 'app-tokens',
  templateUrl: './tokens.component.html',
  styleUrls: ['./tokens.component.scss']
})
export class TokensComponent implements OnInit {

  constructor(
    private store: Store<fromReducers.State>,
    private themeService : ThemeService,
    private exportService: ExportToCsvService,
    private notificationService: NotificationService
  ) { }

  temp: TokenEvent[];
  rows: TokenEvent[];
  caseFilterEvent: TokenEvent;
  tokenMonths: TokenMonth[];
  last12MonthStrings: string[] = [];
  tokenBalance;
  entitlement$ = this.store.pipe(
    select(fromReducers.getEntitlementsAll)
  );
  entitlementSubscrip;
  isLoading$ = this.store.pipe(select(fromReducers.getEntitlementsIsLoading));
  isLoadingTokenEvents$ = this.store.pipe(select(fromReducers.getEntitlementsIsLoadingTokenEvents));
  tokenEvents$ = this.store.pipe(select(fromReducers.getTokenEvents));
  loggedInUser$ = this.store.pipe(select(fromReducers.getLoggedInUser));
  userSubscription;
  tokenEventSubscription;
  currentUser: User;
  tokenEvents: TokenEvent[];
  tokenEvents2: TokenEvent[];
  last12Months: number[] = [];
  last12MonthsYears: number[] = [];
  monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  customers$ = this.store.pipe(select(fromReducers.getCustomersAll));
  selectedCustomer: Customer;


  customerForm: UntypedFormGroup = new UntypedFormGroup({
    customerControl: new UntypedFormControl("")
  });


  tokenEventData$ = combineLatest(
    this.tokenEvents$
    ).pipe(
    map(([tokenEvents]) => {
      if (tokenEvents == null) {
        return;
      }
      this.tokenEvents2 = tokenEvents;
      //Track the balance over multiple months
      this.last12MonthStrings = this.last12Months.map(m=>{
        return this.monthNames[m] +" "+ this.last12MonthsYears[this.last12Months.indexOf(m)];
      });
      const data = this.last12Months.map(m=>{
        // Default values for the graph are 0 
        var values = {
          name:this.monthNames[m] +" "+ this.last12MonthsYears[this.last12Months.indexOf(m)],
          series:[
            {name: "Tokens Used",value: 0},
            {name: "Tokens Purchased",value: 0}
          ]
        };
        
        // Get token events for given month
        let monthTokenEvents = tokenEvents.filter(t => 
          new Date(t.createdOn).getFullYear() === this.last12MonthsYears[this.last12Months.indexOf(m)] &&
          new Date(t.createdOn).getMonth() === m
          );
        if(monthTokenEvents != null && monthTokenEvents != []){
          // Get tokens used 
          let monthTokensUsed = 0;
          monthTokenEvents.filter(t => t.eventType == TokenEventTypes.Activity || t.eventType == TokenEventTypes.Adjustment ).forEach(t => { monthTokensUsed=monthTokensUsed+t.tokensDebited });
          // Get tokens added
          let monthTokensAdded = 0;
          monthTokenEvents.filter(t => t.eventType == TokenEventTypes.Purchase ).forEach(t => { monthTokensAdded=monthTokensAdded+t.tokensAdded });
          values = 
            {
              name:this.monthNames[m] +" "+ this.last12MonthsYears[this.last12Months.indexOf(m)],
              series:[
                {name: "Tokens Purchased",value: monthTokensAdded},
                {name: "Tokens Used",value: monthTokensUsed}
              ]
            };
        }
        return values;
      });
      this.tokenMonths = data.map(d=>{
        let tokenMonth = {name:d.name,tokensPurchased:+d.series[0].value,tokensUsed:+d.series[1].value} as TokenMonth
        return tokenMonth;
      }).filter(tm=>tm.tokensPurchased != 0 || tm.tokensUsed != 0).reverse();
        return data;
    }));

  testData = [
       {
      "name":"Sep 20",
      "series":[
        {"name": "Tokens Used","value": "9"},
        {"name": "Token Balance","value": "13"}
      ]
    }
  ];

  colorScheme2 = {
    domain: ['#29bb9c', '#e96b56', '#55acd2',
             '#a8385d', '#66bd6d', '#faa026',
             '#b56979', '#3b6f82', '#e45296'
  ]
  };

  ngOnInit() {
    this.store.dispatch(new fromCustomerActions.GetCustomers());


    this.userSubscription = this.loggedInUser$.subscribe(user=>{
      if(user !=null){
        this.currentUser = user;
        this.store.dispatch(new EntitlementActions.GetTokenEvents({customerId: this.currentUser.crmContact.customer.id}));
      }
    });

    this.tokenEventSubscription = this.tokenEventData$.subscribe(tokenEvents=>{
      if(tokenEvents !=null){
        this.temp = this.tokenEvents2;
        this.rows = this.temp.filter(te=>te.eventType != TokenEventTypes.Activity);
      }
    });
    this.entitlementSubscrip = this.entitlement$.subscribe(entitlements=>{
      if(entitlements !=null){
        this.calculateTokenBalance(entitlements);
      }
    });

    let month = new Date().getMonth();
    for (let i = 0; i < 12; i++) {
      const moddedMonth = (month + 12) % 12;
      this.last12Months.push(moddedMonth);
      month--;

      let todayDate = new Date();
      todayDate.setMonth(todayDate.getMonth() - i);
      this.last12MonthsYears.push(todayDate.getFullYear());
    }
    this.last12Months.reverse();  
    this.last12MonthsYears.reverse();
  }
  ngOnDestroy(){
    if(this.userSubscription != null){
      this.userSubscription.unsubscribe();
    };
    if(this.tokenEventSubscription != null){
      this.tokenEventSubscription.unsubscribe();
    };
    if (this.entitlementSubscrip != null) {
      this.entitlementSubscrip.unsubscribe();
    };
  }
  
  get otherTheme(): boolean {
    return this.themeService.otherTheme;
  }
  changeTheme(){
    this.themeService.changeTheme();
  }


  back(){
    this.rows = this.temp.filter(te=>te.eventType != TokenEventTypes.Activity)
    this.caseFilterEvent = null;
  }

  updateFilter(incident: TokenEvent) {
    this.rows = this.temp.filter(te=>te.eventType == TokenEventTypes.Activity && te.id == incident.id);
    this.caseFilterEvent = incident;
  }

  refresh(){
    this.store.dispatch(new EntitlementActions.GetTokenEvents({customerId: this.currentUser.crmContact.customer.id}));
    
  }

  calculateTokenBalance(entitlementList: Entitlement[]){
    this.tokenBalance = 0;
    entitlementList.forEach(ent => {
      if(ent.stateCode == 1 && ent.usesTokens){
        this.tokenBalance+=ent.tokenManagement.tokensBalance
      }
    });
  }

 export(allDataBool){
    let exportData: TokenEvent[];
    if(allDataBool){
      let nonCaseExportData: TokenEvent[] = this.temp.filter(te=>te.eventType != TokenEventTypes.Case);
      exportData = nonCaseExportData.map(te=>{
        if(te.eventType == TokenEventTypes.Activity){
          // For each activity, find the case event to get the case number
          let caseNumber = this.temp.find(t=>(t.id == te.id && t.eventType == TokenEventTypes.Case)).caseNumber;
          let newTe = {
            ...te,
            caseNumber
          } as TokenEvent
          return newTe;
        }
        return te;
      });
    }
    else{
      exportData = this.rows;
    }
    if (exportData.length>0){
      this.exportService.exportAsCSV(exportData,"Token Events");
    } else{
      this.notificationService.showError("Sorry there has been a problem exporting data: No Data", "Close");
    };
  }

  updateSelectedCustomer(event: TypeaheadMatch) {
    const customer = event.item as Customer;
    this.selectedCustomer = customer;
    this.store.dispatch(new EntitlementActions.GetEntitlements({customerId: customer.id}));
    this.store.dispatch(new EntitlementActions.GetTokenEvents({customerId: customer.id}));

  }


}
