import { Action } from "@ngrx/store";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { Customer } from "src/app/models/customer.model";
import {
  CustomerActionTypes,
  CustomerActions
} from "../actions/customer.actions";

export const customerFeatureKey = "customer";

export interface State extends EntityState<Customer> {
  isLoading: boolean;
}

export const adapter: EntityAdapter<Customer> = createEntityAdapter<Customer>();

export const initialState: State = adapter.getInitialState({
  isLoading: false
});

export function reducer(state = initialState, action: CustomerActions): State {
  switch (action.type) {
    case CustomerActionTypes.GetCustomers: {
      return {
        ...state,
        isLoading: true
      };
    }

    case CustomerActionTypes.GetCustomersSuccess: {
      const newState = adapter.upsertMany(action.payload.customers, state);
      return {
        ...newState,
        isLoading: false
      };
    }

    case CustomerActionTypes.GetCustomersFailure: {
      return {
        ...state,
        isLoading: false
      };
    }
    default:
      return state;
  }
}

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal
} = adapter.getSelectors();

export const getIsLoading = (state: State) => state.isLoading;
