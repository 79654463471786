import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

// NgxBootstrap Modules
import { TypeaheadModule } from "ngx-bootstrap/typeahead";

@NgModule({
  declarations: [],
  imports: [CommonModule, TypeaheadModule.forRoot()],
  exports: [TypeaheadModule]
})
export class NgxBootstrapModule {}
