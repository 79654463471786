<div style="overflow-x: auto;">
<ngx-datatable
  class="material"
  [rows]="cases"
  [columnMode]="'flex'"
  [headerHeight]="50"
  [footerHeight]="50"
  [rowHeight]="50"
  [limit]="10"
  [loadingIndicator]="loadingIndicator"
  style="min-width: 850px;"
  [sorts]="[{ prop: 'createdOn', dir: 'desc' }]"
>
  <ngx-datatable-column name="Case Number" [flexGrow]="1">
    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
      <div  matTooltip="{{ row.caseNumber }}" >
        <a [routerLink]="['/cases', row.id]">{{ row.caseNumber }}</a>
      </div>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Title" [flexGrow]="2">
    <ng-template let-value="value" ngx-datatable-cell-template>
      <div  matTooltip="{{value}}" >
        {{ value }}
      </div>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Description" [flexGrow]="3">
    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
      <div  matTooltip="{{value}}" >
        {{ value }}
      </div>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Status" [flexGrow]="2" prop="status.label">
    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
      <div *ngIf="(row.status?.label == 'Active')" matTooltip="{{ row.statusReason?.label }}">
        <span  *ngIf="!(row.statusReason?.label == 'Customer Closed') " style="background-color:#ffc107;">&nbsp;&nbsp;</span>
        <span *ngIf="row.statusReason?.label == 'Customer Closed' " style="background-color:#29bb9c">&nbsp;&nbsp;</span>
        {{ row.statusReason?.label }}
      </div>
      <div *ngIf="!(row.status?.label == 'Active')">
        <span *ngIf="row.status?.label == 'Canceled'" style="background-color:#bababa">&nbsp;&nbsp;</span>
        <span *ngIf="row.status?.label == 'Resolved' " style="background-color:#29bb9c">&nbsp;&nbsp;</span>
        {{ row.status?.label }}
      </div>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Priority" [flexGrow]="2" prop="priority.label">
    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
      {{ row.priority?.label }}
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Owner" [flexGrow]="1" prop="owner.lastName">
    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
      {{ row.owner?.firstName }} {{ row.owner?.lastName }}
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Case Contact" [flexGrow]="1" prop="customerContact.lastName">
    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
      <div  matTooltip="{{ row.customerContact?.firstName }} {{ row.customerContact?.lastName }}" >
      {{ row.customerContact?.firstName }}
      {{ row.customerContact?.lastName }}
    </div>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Created On" [flexGrow]="1">
    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
      <div  matTooltip=" {{ row.createdOn | date: 'dd/MM/yyyy HH:mm' }}" >
        {{ row.createdOn | date: "dd/MM/yyyy HH:mm" }}
      </div>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Modified On" [flexGrow]="1">
    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
      <div  matTooltip="{{ row.modifiedOn | date: 'dd/MM/yyyy HH:mm' }}" >
      {{ row.modifiedOn | date: "dd/MM/yyyy HH:mm" }}
    </div>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column *ngIf="error$ | async" name="" [flexGrow]="1">
    <ng-template ngx-datatable-header-template class="text-center">
      <div class="text-center full-width error-warning">
        <fa-icon
          [icon]="['fas', 'exclamation-circle']"
          size="2x"
          [title]="error$ | async"
        ></fa-icon>
      </div>
    </ng-template>
  </ngx-datatable-column>
</ngx-datatable>
</div>