import { Component, OnInit } from "@angular/core";
import { Store, select } from "@ngrx/store";
import { LogOut } from "../../store/actions/auth.actions";
import { Observable, combineLatest } from "rxjs";
import { Chart } from "chart.js";
import { Router } from "@angular/router";
import * as fromReducers from "../../store/reducers";
import * as fromCaseActions from "../../store/actions/case.actions";
import { AuthService } from "../../services/auth.service";
import * as MetadataActions from "../../store/actions/metadata.actions";
import { map } from "rxjs/operators";
import { ThemeService } from "src/app/services/theme.service";

import { Title } from '@angular/platform-browser';
import { UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { StatusReason } from 'src/app/models/status-reason.model';
import { EntitlementActionTypes } from 'src/app/store/actions/entitlement.actions';
import { ChartData } from 'src/app/models/tokens/chart-data.model';
import { Entitlement } from 'src/app/models/tokens/entitlement.model';

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"]
})
export class DashboardComponent implements OnInit {


  columns = [{ name: "Title" }, { name: "Category" }];

  constructor(
    private store: Store<fromReducers.State>,
    private router: Router,
    private authService: AuthService,
    private themeService : ThemeService,
    private titleService: Title
  ) {
    this.titleService.setTitle("Dashboard");
  }
  cases$ = this.store.pipe(select(fromReducers.getCasesAll));
  entitlement$ = this.store.pipe(
    select(fromReducers.getEntitlementsAll)
  );
  entitlementSubscrip;
  entitlements;
  loggedInUser$ = this.store.pipe(select(fromReducers.getLoggedInUser));
  states$ = this.store.pipe(select(fromReducers.getStateCodesAll));
  statusReasons$ = this.store.pipe(
    select(fromReducers.getStatusReasonCodesAll)
  );
  isLoading$ = this.store.pipe(select(fromReducers.getCasesIsLoading));
  isLoadingEntitlements$ = this.store.pipe(select(fromReducers.getEntitlementsIsLoading));

  last6Months: number[] = [];
  last6MonthYears: number[] = [];
  
  showMyCasesData = false;
  tokenBalance;

  showMyCasesDataForm: UntypedFormGroup = new UntypedFormGroup({
    showMyCasesData: new UntypedFormControl("")
  });

  monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];

  //active, resolved, canceled
  //yellow, green, grey
  colorScheme = {
    domain: ['#ffc107', '#29bb9c', '#bababa']
  };
  
  colorScheme2 = {
    domain: ['#29bb9c', '#e96b56', '#55acd2',
             '#a8385d', '#66bd6d', '#faa026',
             '#b56979', '#3b6f82', '#e45296',
             '#cbccd5', '#faec8a', '#67d18c'
  ]
  };

  //company cases
  casesData$ = combineLatest(this.cases$, this.states$).pipe(
    map(([cases, states]) => {
      if (cases == null || states == null) {
        return;
      }
      const data = this.last6Months.map(m => {
        return {
          name: this.monthNames[m],
          series: states.map(s => {
            return {
              name: s.label,
              value: cases.filter(
                c =>
                  c.stateCode === s.value &&
                  new Date(c.createdOn).getMonth() === m &&
                  new Date(c.createdOn).getFullYear() === this.last6MonthYears[this.last6Months.indexOf(m)] 
              ).length
            };
          })
        };
      });
      return data;
    })
  );

  casesPieChartData$ = combineLatest(this.cases$, this.statusReasons$).pipe(
    map(([cases, statusReasons]) => {
      if (cases == null || statusReasons == null) {
        return;
      }
      const data = statusReasons.map(s => {
        return {
          name: s.label,
          value: cases.filter(c => c.statusReasonCode === s.value &&  c.stateCode == "0").length
        };
      });
      return data.filter(point => point.value > 0);
    })
  );

  // This shows closed cases as resolved 

  // casesPieChartData$ = combineLatest(this.cases$, this.statusReasons$).pipe(
  //   map(([cases, statusReasons]) => {
  //     if (cases == null || statusReasons == null) {
  //       return;
  //     }
  //     // if stateCode is resolved ignore status reason and just use resolved
  //     // same with canceled
  //     var reasons = new Array({label: "Resolved", value: null} as StatusReason);
  //     var newStatusReasons = reasons.concat(statusReasons);
  //     const mappedCases = cases.map(c=>{
  //       var statusReason
  //       if(c.stateCode == "1")
  //         statusReason = {label: "Resolved", value: null} as StatusReason;
  //       else if(c.stateCode == "2")
  //         statusReason = {label: "Canceled", value: "6"} as StatusReason;
  //       else
  //         statusReason = newStatusReasons.find(s=>s.value==c.statusReasonCode);
  //       return {...c, statusReason: statusReason}
  //     });
  //     const data = newStatusReasons.map(s=>{
  //       return {
  //           name: s.label,
  //           value: mappedCases.filter(c => c.statusReason.label === s.label).length
  //       };
  //     });
  //     return data.filter(point => point.value > 0);
  //   })
  // );

  //my cases
  myCasesData$ = combineLatest(this.cases$, this.states$, this.loggedInUser$).pipe(
    map(([cases, states, user]) => {
      if (cases == null || states == null) {
        return;
      }
      const data = this.last6Months.map(m => {
        return {
          name: this.monthNames[m],
          series: states.map(s => {
            return {
              name: s.label,
              value: cases.filter(
                c =>
                  c.stateCode === s.value &&
                  new Date(c.createdOn).getMonth() === m &&
                  new Date(c.createdOn).getFullYear() === this.last6MonthYears[this.last6Months.indexOf(m)] &&
                  c.customerContact.id === user.crmContact.id
              ).length
            };
          })
        };
      });
      return data;
    })
  );

  myCasesPieChartData$ = combineLatest(this.cases$, this.statusReasons$, this.loggedInUser$).pipe(
    map(([cases, statusReasons, user]) => {
      if (cases == null || statusReasons == null) {
        return;
      }
      const data = statusReasons.map(s => {
        return {
          name: s.label,
          value: cases.filter(c => 
            c.statusReasonCode === s.value &&
            c.customerContact.id === user.crmContact.id &&
            c.stateCode == "0"
            ).length
        };
      });
      return data.filter(point => point.value > 0);
    })
  );

  // myCasesPieChartData$ = combineLatest(this.cases$, this.statusReasons$, this.loggedInUser$).pipe(
  //   map(([cases, statusReasons, user]) => {
  //     if (cases == null || statusReasons == null) {
  //       return;
  //     }
  //     // if stateCode is resolved ignore status reason and just use resolved
  //     // same with canceled
  //     var reasons = new Array({label: "Resolved", value: null} as StatusReason);
  //     var newStatusReasons = reasons.concat(statusReasons);
  //     const mappedCases = cases.map(c=>{
  //       var statusReason
  //       if(c.stateCode == "1")
  //         statusReason = {label: "Resolved", value: null} as StatusReason;
  //       else if(c.stateCode == "2")
  //         statusReason = {label: "Canceled", value: "6"} as StatusReason;
  //       else
  //         statusReason = newStatusReasons.find(s=>s.value==c.statusReasonCode);
  //       return {...c, statusReason: statusReason}
  //     });
  //     const data = newStatusReasons.map(s=>{
  //       return {
  //           name: s.label,
  //           value: mappedCases.filter(c => 
  //             c.statusReason.label === s.label &&
  //             c.customerContact.id === user.crmContact.id).length
  //       };
  //     });
  //     return data.filter(point => point.value > 0);
  //   })
  // );

    
  ngOnInit() {
    let month = new Date().getMonth();
    for (let i = 0; i < 6; i++) {
      const moddedMonth = (month + 12) % 12;
      this.last6Months.push(moddedMonth);
      month--;

      let todayDate = new Date();
      todayDate.setMonth(todayDate.getMonth() - i);
      this.last6MonthYears.push(todayDate.getFullYear());
    }
    this.last6Months.reverse();  
    this.last6MonthYears.reverse();
    this.entitlementSubscrip = this.entitlement$.subscribe(entitlements=>{
      if(entitlements !=null){
        this.entitlements = entitlements;
        this.calculateTokenBalance(entitlements);
      }
    });
  }
  ngOnDestroy(){
    if (this.entitlementSubscrip != null) {
      this.entitlementSubscrip.unsubscribe();
    };
  }

  get otherTheme(): boolean {
    return this.themeService.otherTheme;
  }
  changeTheme(){
    this.themeService.changeTheme();
  }

  axisFormat(val) {
    if (val % 1 === 0) {
      return val.toLocaleString();
    } else {
      return '';
    }
  }

  calculateTokenBalance(entitlementList: Entitlement[]){
    this.tokenBalance = 0;
    entitlementList.forEach(ent => {
      if(ent.stateCode == 1 && ent.usesTokens){
        this.tokenBalance+=ent.tokenManagement.tokensBalance
      }
    });
  }

  
}
