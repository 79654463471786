import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Observable, of } from "rxjs";
import {
  tap,
  map,
  switchMap,
  catchError,
  mergeMap,
  exhaustMap
} from "rxjs/operators";
import { CaseActionTypes } from "../actions/case.actions";
import * as CaseActions from "../actions/case.actions";
import * as UserActions from "../actions/user.actions";
import { SupportActivityActionTypes } from "../actions/support-activity.actions";
import * as SupportActivityActions from "../actions/support-activity.actions";
import { CaseService } from "src/app/services/case.service";
import { MatDialog } from "@angular/material/dialog";
import { CloseCaseDialogComponent } from "src/app/components/cases/case/close-case-dialog/close-case-dialog.component";
import { ErrorService } from "src/app/services/error.service";
import { Action } from "@ngrx/store";
import { UploadFileDialogComponent } from "src/app/components/cases/case/support-activities/support-activity/upload-file-dialog/upload-file-dialog.component";
import { AddActivityDialogComponent } from "src/app/components/cases/case/support-activities/add-activity-dialog/add-activity-dialog.component"
import { NewCaseComponent } from 'src/app/components/cases/new-case/new-case.component';
import { NotificationService } from 'src/app/services/notification.service';
import { QuickUploadDialogComponent } from 'src/app/components/cases/case/quick-upload-dialog/quick-upload-dialog.component';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';

@Injectable()
export class CaseEffects {
  constructor(
    private actions: Actions,
    private caseService: CaseService,
    private dialog: MatDialog,
    private errorService: ErrorService,
    private notificationService: NotificationService
  ) {}

  
  getCompanyCases$: Observable<any> = createEffect(() => this.actions.pipe(
    ofType<CaseActions.GetCompanyCases>(CaseActionTypes.GetCompanyCases),
    mergeMap(action => {
      return this.caseService.getCompanyCases(action.payload.userCrmId).pipe(
        map(cases => {
          return new CaseActions.GetCompanyCasesSuccess({ cases });
        }),
        catchError(error => {
          const errorMessage = this.errorService.handleError(
            error,
            "Sorry, there's been a problem getting your cases"
          );
          return of(
            new CaseActions.GetCompanyCasesFailure({ error: errorMessage })
          );
        })
      );
    })
  ));

  
  createNewCase$: Observable<any> = createEffect(() => this.actions.pipe(
    ofType<CaseActions.CreateNewCase>(CaseActionTypes.CreateNewCase),
    mergeMap(action => {
      return this.caseService.createNewCase(action.payload.case, action.payload.redirect).pipe(
        map(newCase => {
          if(action.payload.redirect)
            this.notificationService.showSuccess("Case created! You can attach files to an update.","close");
          else
            this.notificationService.showSuccess("Request Sent!","close");
          return new CaseActions.CreateNewCaseSuccess({ case: newCase });
        }),
        catchError(error => {
          this.errorService.handleError(
            error,
            "Sorry, there's been a problem creating the case"
          );
          return of(new CaseActions.CreateNewCaseFailure());
        })
      );
    })
  ));

  
  openCloseCaseDialog = createEffect(() => this.actions.pipe(
    ofType<CaseActions.OpenCloseCaseDialog>(
      CaseActionTypes.OpenCloseCaseDialog
    ),
    exhaustMap(action => {
      const dialogRef = this.dialog.open(CloseCaseDialogComponent, {
        autoFocus: false,
        data: { caseCrmId: action.payload.caseCrmId }
      });
      return dialogRef.afterClosed();
    }),
    map((data: any) => {
      if (data == null || data.caseCrmId == null) {
        return new CaseActions.CloseCloseCaseDialog();
      } else {
        return new CaseActions.CloseCase({
          caseCrmId: data.caseCrmId,
          closureReason: data.closureReason,
          statusCode: data.statusCode
        });
      }
    })
  ));

  
  closeCase$: Observable<any> = createEffect(() => this.actions.pipe(
    ofType<CaseActions.CloseCase>(CaseActionTypes.CloseCase),
    mergeMap(action => {
      return this.caseService
        .closeCase(action.payload.caseCrmId, action.payload.closureReason, action.payload.statusCode)
        .pipe(
          map((closedCase) => {
            this.notificationService.showSuccess("Case resolved!","close");
            return new CaseActions.CloseCaseSuccess({
              closedCase: closedCase
            });
          }),
          catchError(error => {
            this.errorService.handleError(
              error,
              "Sorry, there's been a problem closing the case"
            );
            return of(new CaseActions.CloseCaseFailure());
          })
        );
    })
  ));

  
  openConfirmCustomerCloseDialog = createEffect(() => this.actions.pipe(
    ofType<CaseActions.OpenConfirmCustomerCloseDialog>(
      CaseActionTypes.OpenConfirmCustomerCloseDialog
    ),
    exhaustMap(action => {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        autoFocus: false,
        data: { caseId: action.payload.caseId,
                title: "Close Case",
                message: "Are you sure you want to close this case?" }
      });
      return dialogRef.afterClosed();
    }),
    map((data: any) => {
      if(data == null || data.req == false){
        return new UserActions.CloseConfirmDialog();
      }
      else if(data.data.caseId != null && data.data.caseId != undefined){
        return new CaseActions.CustomerCloseCase({caseCrmId: data.data.caseId});
      }
      else{
        return new UserActions.CloseConfirmDialog();
      }
    })
  ));

  
  customerCloseCase$: Observable<any> = createEffect(() => this.actions.pipe(
    ofType<CaseActions.CustomerCloseCase>(CaseActionTypes.CustomerCloseCase),
    mergeMap(action => {
      return this.caseService
        .customerCloseCase(action.payload.caseCrmId)
        .pipe(
          map((closedCase) => {
            this.notificationService.showSuccess("Case resolved!","close");
            return new CaseActions.CustomerCloseCaseSuccess({
              closedCase: closedCase
            });
          }),
          catchError(error => {
            this.errorService.handleError(
              error,
              "Sorry, there's been a problem closing the case"
            );
            return of(new CaseActions.CustomerCloseCaseFailure());
          })
        );
    })
  ));

  

  

  

  
  openUploadFileDialog = createEffect(() => this.actions.pipe(
    ofType<CaseActions.OpenUploadFileDialog>(
      CaseActionTypes.OpenUploadFileDialog
    ),
    exhaustMap(action => {
      const dialogRef = this.dialog.open(UploadFileDialogComponent, {
        autoFocus: false,
        data: {
          caseCrmId: action.payload.caseCrmId,
          activityCrmId: action.payload.activityCrmId
        }
      });
      return dialogRef.afterClosed();
    }),
    map((data: any) => {
      if (data == null || data.data.activityCrmId == null) {
        return new CaseActions.CloseUploadFileDialog();
      } else {
        return;
      }
    })
  ));

  
  openQuickUploadDialog = createEffect(() => this.actions.pipe(
    ofType<CaseActions.OpenQuickUploadDialog>(
      CaseActionTypes.OpenQuickUploadDialog
    ),
    exhaustMap(action => {
      const dialogRef = this.dialog.open(QuickUploadDialogComponent, {
        autoFocus: false,
        data: {
          caseCrmId: action.payload.case.id,
          case: action.payload.case
        }
      });
      return dialogRef.afterClosed();
    }),
    map((data: any) => {
      if (data == null || data.data.activityCrmId == null) {
        return new CaseActions.CloseQuickUploadDialog();
      } else {
        return;
      }
    })
  ));

  
  openCreateCaseDialog = createEffect(() => this.actions.pipe(
    ofType<CaseActions.OpenCreateCaseDialog>(
      CaseActionTypes.OpenCreateCaseDialog
    ),
    exhaustMap(action => {
      const dialogRef = this.dialog.open(NewCaseComponent, {
        autoFocus: false
      });
      return dialogRef.afterClosed();
    }),
    map((data: any) => {
      if (data == null || data.req == null) {
        return new CaseActions.CloseCreateCaseDialog();
      } else {
        return;
      }
    })
  ));
  

  

}
