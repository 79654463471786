<h2 matDialogTitle>Update Case</h2>
<hr>
<mat-dialog-content class="text-center dialog">

  <form [formGroup]="createNewActivityForm">

    <mat-form-field class="full-width">
      <mat-label>Subject</mat-label>
      <input
        matInput
        type="text"
        formControlName="subjectControl"
        required
      />
    </mat-form-field>

    <mat-form-field class="full-width">
      <mat-label>Description</mat-label>
        <textarea
          matInput
          rows="2"
          formControlName="descriptionControl"
        ></textarea>
    </mat-form-field>

  </form>

</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="">CANCEL</button>
  <button mat-button (click)="createNewActivity()">CREATE</button>
</mat-dialog-actions>

<!-- <ng-template #contactSearchTemplate let-contact="item" let-index="index">
  <p>
    {{ contact.firstName }} {{ contact.lastName }} ({{ contact.emailAddress }})
  </p>
</ng-template>

<ng-template #customerSearchTemplate let-customer="item" let-index="index">
  <p>
    {{ customer.name }}
  </p>
</ng-template> -->
