import { Action } from "@ngrx/store";
import { ContactActionTypes, ContactActions } from "../actions/contact.actions";
import { Contact } from "src/app/models/contact.model";
import { EntityState, createEntityAdapter, EntityAdapter } from "@ngrx/entity";
import { act } from "@ngrx/effects";

export const ContactFeatureKey = "Contact";

export interface State extends EntityState<Contact> {
  isLoading: boolean;
}

export const adapter: EntityAdapter<Contact> = createEntityAdapter<Contact>();

export const initialState: State = adapter.getInitialState({
  isLoading: false
});

export function reducer(state = initialState, action: ContactActions): State {
  switch (action.type) {
    case ContactActionTypes.GetContacts: {
      return {
        ...state,
        isLoading: true
      };
    }
    case ContactActionTypes.GetContactsSuccess: {
      const newState = adapter.upsertMany(action.payload.contacts, state);
      return {
        ...newState,
        isLoading: false
      };
    }
    case ContactActionTypes.GetContactsFailure: {
      return {
        ...state,
        isLoading: false
      };
    }
    default:
      return state;
  }
}

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal
} = adapter.getSelectors();

export const getIsLoading = (state: State) => state.isLoading;
