import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Observable, of, from } from "rxjs";
import {
  tap,
  map,
  switchMap,
  catchError,
  withLatestFrom,
  mergeMap
} from "rxjs/operators";

import { AuthService } from "../../services/auth.service";
import {
  AuthActionTypes,
  LogIn,
  LogInSuccess,
  LogInFailure,
  LogOut,
  GetStatus
} from "../actions/auth.actions";

import * as AuthActions from "../actions/auth.actions";
import * as UserActions from "../actions/user.actions";
import * as CaseActions from "../actions/case.actions";
import * as ContactActions from "../actions/contact.actions";
import * as EntitlementActions from "../actions/entitlement.actions";
import * as MetadataActions from "../actions/metadata.actions";
import { Store, select, Action } from "@ngrx/store";
import * as fromReducers from "../reducers";
import { NotificationService } from 'src/app/services/notification.service';
import { ErrorService } from 'src/app/services/error.service';
import { MatDialog } from '@angular/material/dialog';
import { ProfileComponent } from 'src/app/components/profile/profile.component';
import { User } from 'src/app/models/user.model';
import { Entitlement } from 'src/app/models/tokens/entitlement.model';

@Injectable()
export class AuthEffects {
  constructor(
    private actions: Actions,
    private authService: AuthService,
    private notificationService : NotificationService,
    private errorService: ErrorService,
    private router: Router,
    private store: Store<fromReducers.State>,
    private dialog: MatDialog
  ) {}

  
  LogIn: Observable<any> = createEffect(() => this.actions.pipe(
    ofType<LogIn>(AuthActionTypes.LOGIN),
    map((action: LogIn) => action),
    switchMap(payload => {
      return from(this.authService.login());
    })
  ), { dispatch: false });

  // @Effect({ dispatch: false })
  // LogInSuccess: Observable<any> = this.actions.pipe(
  //   ofType(AuthActionTypes.LOGIN_SUCCESS),
  //   tap(user => {
  //     localStorage.setItem("token", user.payload.token);
  //     this.router.navigateByUrl("/");
  //   }),
  // );
  user: User ;
  
  logInSuccess$: Observable<Action> = createEffect(() => this.actions.pipe(
    ofType<AuthActions.LogInSuccess>(AuthActionTypes.LOGIN_SUCCESS),
    map(action => {
      this.user= action.payload.user 
      return new AuthActions.LoginInitialLoad();
    }),
    tap(() => {
      if(this.user.isSimpsonsAdmin){
        this.router.navigateByUrl("/users");
      }
      else {
        this.router.navigateByUrl("/");
      };
    })
  ));

  
  LogInFailure: Observable<any> = createEffect(() => this.actions.pipe(
    ofType(AuthActionTypes.LOGIN_FAILURE)
  ), { dispatch: false });

  
  logout$: Observable<Action> = createEffect(() => this.actions.pipe(
    ofType<AuthActions.LogOut>(AuthActionTypes.LOGOUT),
    map(action => {
      this.authService.logout();
      return new AuthActions.LogOutSuccess();
    })
  ));

  
  GetStatus: Observable<any> = createEffect(() => this.actions.pipe(
    ofType(AuthActionTypes.GET_STATUS),
    map((action: GetStatus) => action),
    switchMap(payload => {
      return this.authService.getStatus();
    })
  ), { dispatch: false });

  // @Effect({ dispatch: false })
  // GetStatus: Observable<any> = this.actions.pipe(
  //   ofType(AuthActionTypes.GET_STATUS),
  //   switchMap(payload => {
  //     return this.authService.getStatus();
  //   }));

  
  logInValidation$: Observable<any> = createEffect(() => this.actions.pipe(
    ofType<AuthActions.LoginValidation>(AuthActionTypes.LOGIN_VALIDATION),
    switchMap(action => {
      return from(this.authService.validateLogin()).pipe(
        map(user => {
          if (user != null) {
            return new AuthActions.LoginValidated({ user });
          } else {
            return new AuthActions.LoginNotValidated();
          }
        })
      );
    })
  ));

  
  logInValidated$: Observable<Action> = createEffect(() => this.actions.pipe(
    ofType<AuthActions.LoginValidated>(AuthActionTypes.LOGIN_VALIDATED),
    map(action => {
      return new AuthActions.LoginInitialLoad();
    })
  ));

  
  logInInitialLoad$: Observable<Action> = createEffect(() => this.actions.pipe(
    ofType<AuthActions.LoginInitialLoad>(AuthActionTypes.LOGIN_INITIAL_LOAD),
    withLatestFrom(this.store.pipe(select(fromReducers.getLoggedInUser))),
    mergeMap(([action, loggedInUser]) => {
      const actions: (
        | CaseActions.GetCompanyCases
        | MetadataActions.GetRoles
        | ContactActions.GetContacts
        | EntitlementActions.GetEntitlements
        | EntitlementActions.GetTokenEvents
        | MetadataActions.GetStateCodes
        | MetadataActions.GetStatusReasonCodes
        | MetadataActions.GetTypeCodes
        | MetadataActions.GetPriorityCodes
        | MetadataActions.GetActivityTypes
        | MetadataActions.GetPasswordStatusList
      )[] = [
        new CaseActions.GetCompanyCases({
          userCrmId: loggedInUser.crmContact.id
        }),
        new MetadataActions.GetRoles(),
        new ContactActions.GetContacts(),
        new EntitlementActions.GetEntitlements({
          customerId: loggedInUser.crmContact.customer.id
        }),
        new EntitlementActions.GetTokenEvents({
          customerId: loggedInUser.crmContact.customer.id
        }),
        new MetadataActions.GetPriorityCodes(),
        new MetadataActions.GetTypeCodes(),
        new MetadataActions.GetStateCodes(),
        new MetadataActions.GetStatusReasonCodes(),
        new MetadataActions.GetActivityTypes(),
        new MetadataActions.GetPasswordStatusList()
      ];
      return actions;
    }),
    catchError(error => {
      console.error("Error: ", error);
      return of(new AuthActions.LogInFailure());
    })
  ));

  
  logInNotValidated$: Observable<any> = createEffect(() => this.actions.pipe(
    ofType<AuthActions.LoginNotValidated>(AuthActionTypes.LOGIN_NOT_VALIDATED)
    // tap(() => {
    //   this.authService.login();
    // })
  ), { dispatch: false });

  
  logInCallback$: Observable<Action> = createEffect(() => this.actions.pipe(
    ofType<AuthActions.LogInCallback>(AuthActionTypes.LOGIN_CALLBACK),
    mergeMap(action => {
      return from(this.authService.completeAuthentication()).pipe(
        map(user => {
          this.notificationService.showSuccess("Welcome to the Support Portal "+user.email,"close");
          return new AuthActions.LogInSuccess({ user });
        }),
        catchError(error => {
          console.error("Error: ", error);
          return of(new AuthActions.LogInFailure());
        })
      );
    })
  ));

  
  changePassword$: Observable<Action> = createEffect(() => this.actions.pipe(
    ofType<AuthActions.ChangePassword>(AuthActionTypes.CHANGE_PASSWORD),
    mergeMap(action => {
      return this.authService
        .changePassword(
          action.payload.currentPassword,
          action.payload.newPassword
        )
        .pipe(
          map(user => {
            return new AuthActions.ChangePasswordSuccess();
          }),
          // TODO: Add better error logging here.
          // E.g. was their password complex enough?
          // Probably best to create a notification service that can be used to provide errors throughout the app
          catchError(error => {
            console.error("Error: ", error);
            this.errorService.handleError(error,"Sorry there has been a problem changing your password");
            return of(new AuthActions.ChangePasswordFailure());
          })
        );
    })
  ));

  
  changePasswordSuccess$: Observable<Action> = createEffect(() => this.actions.pipe(
    ofType<AuthActions.ChangePasswordSuccess>(AuthActionTypes.CHANGE_PASSWORD_SUCCESS),
    map(action => {
      const dialogRef = this.dialog.closeAll();
      this.notificationService.showSuccess("Password successfully updated!","close");
      return new UserActions.CloseProfileDialog();
    }),
    tap(() => {
      this.router.navigateByUrl("/");
    })
  ));
}
