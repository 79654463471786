import { Component, OnInit, Input } from "@angular/core";
import { SupportActivity } from "src/app/models/support-activity.model";
import { Store, select } from "@ngrx/store";
import * as fromReducers from "../../../../store/reducers";
import * as fromSupportActivityActions from "../../../../store/actions/support-activity.actions";
import { NotificationService } from 'src/app/services/notification.service';
import { Case } from 'src/app/models/case.model';

@Component({
  selector: "app-support-activities",
  templateUrl: "./support-activities.component.html",
  styleUrls: ["./support-activities.component.scss"]
})
export class SupportActivitiesComponent implements OnInit {
  constructor(
    private store: Store<fromReducers.State>,
    private notificationService: NotificationService
    ) {}

  @Input() activities: SupportActivity[];
  @Input() loadingIndicator: boolean;
  @Input() caseClosed: boolean;
  @Input() currentCase: Case;


  ngOnInit() {}

  openDialog() {
    if(this.currentCase != undefined)
      this.store.dispatch(new fromSupportActivityActions.OpenAddNewSupportActivityDialog({currentCase: this.currentCase}));
  }
  isLoadingAttachments$ = this.store.pipe(select(fromReducers.getSupportActivitiesIsLoadingAttachments));

  notify(message){
    this.notificationService.showError(message,"close")
  }
  
}
