import { Component, OnInit } from "@angular/core";
import { Store, select } from "@ngrx/store";
import { LogOut } from "../../../store/actions/auth.actions";
import { Observable, combineLatest } from "rxjs";
import { Chart } from "chart.js";
import { Router } from "@angular/router";
import * as fromReducers from "../../../store/reducers";
import * as fromCaseActions from "../../../store/actions/case.actions";
import { map } from "rxjs/operators";
import { ThemeService } from 'src/app/services/theme.service';
import { Title } from '@angular/platform-browser';
import { ExportToCsvService } from 'src/app/services/export-to-csv.service';
import { Case } from 'src/app/models/case.model';
import { ExportCase } from 'src/app/models/export-models/export-case.model';
import { Status } from 'src/app/models/status.model';
import { NotificationService } from 'src/app/services/notification.service';

import * as CaseActions from "../../../store/actions/case.actions";
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
@Component({
  selector: "app-my-cases",
  templateUrl: "./my-cases.component.html",
  styleUrls: ["./my-cases.component.scss"]
})
export class MyCasesComponent implements OnInit {
  constructor(
    private store: Store<fromReducers.State>,
    private router: Router,
    private themeService : ThemeService,
    private titleService: Title,
    private exportService: ExportToCsvService,
    private notificationService: NotificationService
  ) {
    this.titleService.setTitle("My Cases");
  }

  temp;
  rows;
  showFilters = false;
  caseSubscription;
  statesSubscription;
  exportTitle = "SP My Cases";
  exportData = [];
  statesList: any[];
  openCasesBool = true;


  loggedInUser$ = this.store.pipe(select(fromReducers.getLoggedInUser));
  userSubscription;
  states$ = this.store.pipe(select(fromReducers.getStateCodesAll));
  statusReasons$ = this.store.pipe(
    select(fromReducers.getStatusReasonCodesAll)
  );
  priorities$ = this.store.pipe(select(fromReducers.getPriorityCodesAll));
  types$ = this.store.pipe(select(fromReducers.getTypeCodesAll));
  cases$ = combineLatest(
    this.store.pipe(select(fromReducers.getCasesAll)),
    this.loggedInUser$,
    this.states$,
    this.statusReasons$,
    this.priorities$,
    this.types$
  ).pipe(
    map(([cases, user, states, statusReasons, priorities, types]) => {
      if (
        cases == null ||
        user == null ||
        states == null ||
        statusReasons == null ||
        priorities == null ||
        types == null
      ) {
        return;
      }
      return cases
        .filter(x => x.customerContact.id === user.crmContact.id)
        .map(c => {
          return {
            ...c,
            status: states.find(s => s.value === c.stateCode),
            statusReason: statusReasons.find(
              s => s.value === c.statusReasonCode
            ),
            priority: priorities.find(p => +p.value === c.priorityCode)
          };
        });
    })
  );
  isLoading$ = this.store.pipe(select(fromReducers.getCasesIsLoading));

  ngOnInit() {
    
    console.log("my cases init");

    this.userSubscription = this.loggedInUser$.subscribe(user=>{
      if(user !=null){
        this.store.dispatch(new CaseActions.GetCompanyCases({userCrmId: user.id}));
      }
    });
    this.statesSubscription = this.states$.subscribe(states=>{
      this.statesList = states as Status[];
    });
    this.caseSubscription = this.cases$.subscribe(cases=>{
      if(cases != null){
        this.temp = cases as Case[];
        this.rows = cases.filter(c=>{
          if(c.statusReason != undefined){
            return c.stateCode=="0" && c.statusReason.label != 'Customer Closed'
          }
          else{
            return c.stateCode=="0"
          }
            
        }) as Case[];
        // this.exportData= cases.map(caseTemp=>this.convertCaseToExportCase(caseTemp));
      }
    });
    this.cases$ = combineLatest(
      this.store.pipe(select(fromReducers.getCasesAll)),
      this.loggedInUser$,
      this.states$,
      this.statusReasons$,
      this.priorities$,
      this.types$
    ).pipe(
      map(([cases, user, states, statusReasons, priorities, types]) => {
        if (
          cases == null ||
          user == null ||
          states == null ||
          statusReasons == null ||
          priorities == null ||
          types == null
        ) {
          return;
        }
        return cases
          .filter(x => x.customerContact.id === user.crmContact.id)
          .map(c => {
            return {
              ...c,
              status: states.find(s => s.value === c.stateCode),
              statusReason: statusReasons.find(
                s => s.value === c.statusReasonCode
              ),
              priority: priorities.find(p => +p.value === c.priorityCode)
            };
          });
      })
    );
    
  }
  ngOnDestroy(){
    if (this.userSubscription != null) {
      this.userSubscription.unsubscribe();
    };
    if (this.caseSubscription != null) {
      this.caseSubscription.unsubscribe();
    };
    if (this.statesSubscription != null) {
      this.statesSubscription.unsubscribe();
    };
  }

  get otherTheme(): boolean {
    return this.themeService.otherTheme;
  }

  changeTheme(){
    this.themeService.changeTheme();
  }

  toggleFilterBox(){
    this.openCasesBool = false;
    this.showFilters = !this.showFilters;
    // reset filters

    this.rows = this.temp;
  }

  updateFilter(event) {
    const val =  event.target.value.toLowerCase();


    // filter our data
    const temp1 = this.temp.filter(function (d: Case) {
      if(d != undefined && d.title != null){
        return d.title.toLowerCase().indexOf(val) !== -1 || !val;
      }
      else if (val == ""){
        return true;
      };
    });

    // update the rows
    this.rows = temp1;
    // Whenever the filter changes, always go back to the first page
    // this.table.offset = 0;
  }

  export(title) {
    this.exportData= this.rows.map(caseTemp=>this.convertCaseToExportCase(caseTemp));
    if (this.exportData.length>0){
      this.exportService.exportAsCSV(this.exportData,title);
    } else{
      this.notificationService.showError("Sorry there has been a problem exporting data: No Data", "Close");
    };
  }

  convertCaseToExportCase(caseIn: Case){
    let statusLabel, priorityLabel, ownerName, contactName = "";
    if(caseIn.stateCode != undefined && this.statesList.find(s => s.value === caseIn.stateCode)!= undefined){
      statusLabel = this.statesList.find(s => s.value === caseIn.stateCode).label;
    };
    if(caseIn.priority != undefined){
      priorityLabel = caseIn.priority.label;
    };
    if(caseIn.owner != undefined){
      if(caseIn.owner.firstName != undefined && caseIn.owner.lastName != undefined){
        ownerName = caseIn.owner.firstName+" "+caseIn.owner.lastName;
      }
      else if(caseIn.owner.firstName != undefined){
        ownerName = caseIn.owner.firstName;
      }
      else if(caseIn.owner.lastName != undefined){
        ownerName = caseIn.owner.lastName;
      }
    };
    if(caseIn.customerContact != undefined){
      if(caseIn.customerContact.firstName != undefined && caseIn.customerContact.lastName != undefined){
        contactName = caseIn.customerContact.firstName+" "+caseIn.customerContact.lastName;
      }
      else if(caseIn.customerContact.firstName != undefined){
        contactName = caseIn.customerContact.firstName;
      }
      else if(caseIn.customerContact.lastName != undefined){
        contactName = caseIn.customerContact.lastName;
      }
    };

    return {
      caseNumber: caseIn.caseNumber,
      title: caseIn.title,
      description: caseIn.description,
      status: statusLabel,
      priority: priorityLabel,
      owner: ownerName,
      caseContact: contactName,
      createdOn: caseIn.createdOn,
      modifiedOn: caseIn.modifiedOn
    } as ExportCase;
  }

  refreshCases(userId : string){
    this.showFilters = false;
    this.openCasesBool = true;
    this.store.dispatch(new CaseActions.GetCompanyCases({userCrmId: userId}));
  }

  openCasesForm: UntypedFormGroup = new UntypedFormGroup({
    openCasesBool: new UntypedFormControl("")
  });

  openCasesUpdate(){
    this.showFilters = false;
    if(!this.openCasesBool){
      this.rows = this.rows.filter(c=>c.stateCode=="0" && c.statusReason.label != 'Customer Closed');
    }
    else{
      this.rows = this.temp;
      this.showFilters = false;
    };
  }

}
