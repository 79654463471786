import { Directive } from "@angular/core";
import { ValidatorFn, UntypedFormGroup, ValidationErrors } from "@angular/forms";

@Directive({
  selector: "[appConfirmPasswordValidator]"
})
export class ConfirmPasswordValidatorDirective {
  constructor() {}
}

export const confirmPasswordValidator: ValidatorFn = (
  control: UntypedFormGroup
): ValidationErrors | null => {
  const newPassword = control.get("newPasswordControl");
  const confirmPassword = control.get("confirmPasswordControl");

  return newPassword &&
    confirmPassword &&
    newPassword.dirty &&
    confirmPassword.dirty &&
    newPassword.value !== confirmPassword.value
    ? { nonMatchingPasswords: true }
    : null;
};
