import { NgModule } from "@angular/core";
import { Routes, RouterModule, CanActivate } from "@angular/router";
import { WelcomeComponent } from "./components/welcome/welcome.component";
import { AuthGuardService as AuthGuard } from "./services/auth-guard.service";
import { NewCaseComponent } from "./components/cases/new-case/new-case.component";
import { MyCasesComponent } from "./components/cases/my-cases/my-cases.component";
import { CompanyCasesComponent } from "./components/cases/company-cases/company-cases.component";
import { TokensComponent } from "./components/manage/tokens/tokens.component";
import { ReportsComponent } from "./components/manage/reports/reports.component";
import { UsersComponent } from "./components/manage/users/users.component";
import { ProfileComponent } from "./components/profile/profile.component";
import { DashboardComponent } from "./components/dashboard/dashboard.component";
import { AuthCallbackComponent } from "./components/auth-callback/auth-callback.component";
import { CaseComponent } from "./components/cases/case/case.component";
import { SupportActivityComponent } from "./components/cases/case/support-activities/support-activity/support-activity.component";
import { HelpComponent } from './components/help/help.component';

const routes: Routes = [
  {
    path: "dashboard",
    component: DashboardComponent,
    canActivate: [AuthGuard]
  },
  { path: "auth-callback", component: AuthCallbackComponent },
  {
    path: "cases/new-case",
    component: NewCaseComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "cases/my-cases",
    component: MyCasesComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "cases/company-cases",
    component: CompanyCasesComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "cases/:caseId/activities/:activityId",
    component: SupportActivityComponent,
    canActivate: [AuthGuard]
  },
  { path: "cases/:id", component: CaseComponent, canActivate: [AuthGuard] },
  { path: "tokens", component: TokensComponent, canActivate: [AuthGuard] },
  { path: "reports", component: ReportsComponent, canActivate: [AuthGuard] },
  { path: "users", component: UsersComponent, canActivate: [AuthGuard] },
  { path: "help", component: HelpComponent, canActivate: [AuthGuard] },
  // { path: "profile", component: ProfileComponent, canActivate: [AuthGuard] },
  { path: "welcome", component: WelcomeComponent },
  // { path: 'status', component: StatusComponent, canActivate: [AuthGuard] },
  { path: "", component: DashboardComponent, canActivate: [AuthGuard] },
  { path: "**", redirectTo: "/" }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
