import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Observable, of } from "rxjs";

import * as ContactActions from "../actions/contact.actions";
import { ContactActionTypes } from "../actions/contact.actions";
import { mergeMap, map, catchError } from "rxjs/operators";
import { ContactService } from "src/app/services/contact.service";

@Injectable()
export class ContactEffects {
  constructor(
    private actions$: Actions,
    private contactService: ContactService
  ) {}

  
  getContacts$: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType<ContactActions.GetContacts>(ContactActionTypes.GetContacts),
    mergeMap(action => {
      return this.contactService.getContacts().pipe(
        map(contacts => {
          return new ContactActions.GetContactsSuccess({ contacts });
        }),
        catchError(error => {
          console.error("Error: ", error);
          return of(new ContactActions.GetContactsFailure());
        })
      );
    })
  ));
}
