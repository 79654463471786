import { Component, OnInit, ViewChild } from "@angular/core";
import { ThemeService } from "src/app/services/theme.service";
import { Title } from "@angular/platform-browser";
import { MatAccordion } from "@angular/material/expansion";
import { Store, select } from "@ngrx/store";
import * as fromReducers from "../../store/reducers";

@Component({
  selector: "app-help",
  templateUrl: "./help.component.html",
  styleUrls: ["./help.component.scss"],
})
export class HelpComponent implements OnInit {
  @ViewChild(MatAccordion) accordion: MatAccordion;
  constructor(
    private store: Store<fromReducers.State>,
    private themeService: ThemeService,
    private titleService: Title
  ) {
    this.titleService.setTitle("Help");
  }

  loggedInUser$ = this.store.pipe(select(fromReducers.getLoggedInUser));
  ngOnInit() {}

  get otherTheme(): boolean {
    return this.themeService.otherTheme;
  }
  changeTheme() {
    this.themeService.changeTheme();
  }

  scrollDown() {
    // window.scrollBy(0,100);
    window.scrollBy({
      top: 250,
      behavior: "smooth",
    });
  }
}
