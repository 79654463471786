import { User } from "../../models/user.model";
import { AuthActionTypes, All } from "../actions/auth.actions";
import { CaseActionTypes, CaseActions } from "../actions/case.actions";
import { Case } from "src/app/models/case.model";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { Action } from "rxjs/internal/scheduler/Action";
import { SupportActivity } from "src/app/models/support-activity.model";
import { Attachment } from "src/app/models/attachment.model";

export interface State extends EntityState<Case> {
  isLoading: boolean;
  selectedCaseId: string;
  isCreatingCase: boolean;
  isClosingCase: boolean;
  casesError: string;
  isLoadingSupportActivities: boolean;
  supportActivitiesError: string;
  supportActivities: SupportActivity[];
  isLoadingAttachments: boolean;
  attachments: Attachment[];
  isUploadingAttachment: boolean;
  isAddingNewSupportActivity: boolean;
}

export const adapter: EntityAdapter<Case> = createEntityAdapter<Case>();

export const initialState: State = adapter.getInitialState({
  isLoading: false,
  selectedCaseId: null,
  isCreatingCase: false,
  isClosingCase: false,
  casesError: null,
  isLoadingSupportActivities: false,
  supportActivitiesError: null,
  supportActivities: null,
  isLoadingAttachments: false,
  attachments: null,
  isUploadingAttachment: false,
  isAddingNewSupportActivity: false
});

export function reducer(state = initialState, action: CaseActions): State {
  switch (action.type) {
    case CaseActionTypes.GetCompanyCases: {
      return {
        ...state,
        isLoading: true,
        casesError: null
      };
    }
    case CaseActionTypes.GetCompanyCasesSuccess: {
      const newState = adapter.upsertMany(action.payload.cases, state);
      return {
        ...newState,
        isLoading: false
      };
    }
    case CaseActionTypes.GetCompanyCasesFailure: {
      return {
        ...state,
        isLoading: false,
        casesError: action.payload.error
      };
    }

    case CaseActionTypes.SelectCase: {
      return {
        ...state,
        selectedCaseId: action.payload.caseId
      };
    }

    case CaseActionTypes.CreateNewCase: {
      return {
        ...state,
        isCreatingCase: true
      };
    }
    case CaseActionTypes.CreateNewCaseSuccess: {
      const newState = adapter.upsertOne(action.payload.case, state);
      return {
        ...newState,
        isCreatingCase: false
      };
    }
    case CaseActionTypes.CreateNewCaseFailure: {
      return {
        ...state,
        isCreatingCase: false
      };
    }

    case CaseActionTypes.CloseCase: {
      return {
        ...state,
        isClosingCase: true
      };
    }
    case CaseActionTypes.CloseCaseSuccess: {
      const newState = adapter.upsertOne(action.payload.closedCase, state);
      return {
        ...newState,
        isClosingCase: false
      };
    }
    case CaseActionTypes.CloseCaseFailure: {
      return {
        ...state,
        isClosingCase: false
      };
    }

    case CaseActionTypes.CustomerCloseCase: {
      return {
        ...state,
        isClosingCase: true
      };
    }
    case CaseActionTypes.CustomerCloseCaseSuccess: {
      const newState = adapter.upsertOne(action.payload.closedCase, state);
      return {
        ...newState,
        isClosingCase: false
      };
    }
    case CaseActionTypes.CustomerCloseCaseFailure: {
      return {
        ...state,
        isClosingCase: false
      };
    }

    // case CaseActionTypes.GetSupportActivitesForCase: {
    //   return {
    //     ...state,
    //     isLoadingSupportActivities: true,
    //     supportActivitiesError: null,
    //     supportActivities: null
    //   };
    // }
    // case CaseActionTypes.GetSupportActivitesForCaseSuccess: {
    //   // TODO: Support activities should be upserted for better performance
    //   return {
    //     ...state,
    //     isLoadingSupportActivities: false,
    //     supportActivities: action.payload.activities
    //   };
    // }
    // case CaseActionTypes.GetSupportActivitesForCaseFailure: {
    //   return {
    //     ...state,
    //     isLoadingSupportActivities: false,
    //     supportActivitiesError: action.payload.error
    //   };
    // }

    default: {
      return state;
    }
  }
}

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal
} = adapter.getSelectors();


// export const getSupportActivites = (state: State) => state.supportActivities;
// export const getIsLoadingSupportActivites = (state: State) =>
//   state.isLoadingSupportActivities;
// export const getSupportActivitesError = (state: State) =>
//   state.supportActivitiesError;
export const getIsLoading = (state: State) => state.isLoading;
export const getSelectedCase = (state: State) =>
  state.selectedCaseId == null ? null : state.entities[state.selectedCaseId];
export const getIsCreatingNewCase = (state: State) => state.isCreatingCase;
export const getIsClosingCase = (state: State) => state.isClosingCase;
export const getCasesError = (state: State) => state.casesError;

// export const getSupportActivites = (state: State) => state.supportActivities;
// export const getIsLoadingSupportActivites = (state: State) =>
//   state.isLoadingSupportActivities;
// export const getSupportActivitesError = (state: State) =>
//   state.supportActivitiesError;
// export const getSupportActivityAttachments = (state: State) =>
//   state.attachments;
// export const getSupportActivityIsLoadingAttachments = (state: State) =>
//   state.isLoadingAttachments;
// export const getSupportActivityIsUploadingAttachment = (state: State) =>
//   state.isUploadingAttachment;
