<div [class.alternative]="otherTheme">
<div class="page-container" *ngIf="activity$ | async as activity">
  <button mat-raised-button class="btn btn-secondary" (click)="back()" style="float: left;">Back</button>
  <button
    *ngIf="(case$ | async).stateCode=='0'"
    class="btn btn-info"
    id="uploadButton"
    [class.disabled]="isUploadingAttachment$ | async"
    [disabled]="isUploadingAttachment$ | async"
    (click)="openUploadFileDialog()"
    [title]="'Click to select a file to upload'"
  >
    <span *ngIf="isUploadingAttachment$ | async">
      <fa-icon
        class="mr-2"
        [icon]="['fas', 'spinner']"
        [spin]="true"
      ></fa-icon>
      Uploading...
    </span>
    <span *ngIf="!(isUploadingAttachment$ | async)">
      <mat-icon style="vertical-align: bottom;">add</mat-icon>
      Attach file
    </span>
  </button>
  <button
    disabled
    *ngIf="!((case$ | async).stateCode=='0')"
    class="btn btn-info"
    id="uploadButton"
    (click)="openUploadFileDialog()"
    [title]="'Case is closed'"
  >
    <span *ngIf="isUploadingAttachment$ | async">
      <fa-icon
        class="mr-2"
        [icon]="['fas', 'spinner']"
        [spin]="true"
      ></fa-icon>
      Uploading...
    </span>
    <span *ngIf="!(isUploadingAttachment$ | async)">
      <mat-icon style="vertical-align: bottom;">add</mat-icon>
      Attach file
    </span>
  </button>
  <h1 style="text-align: center;">
    Activity Details
  </h1>
  <hr />

  <div class="row">
    <div class="col-lg-5">
      <h3>Activity Info</h3>
      <mat-card class="mat-elevation-z8">
        <!-- <h4 class="mb-3">{{ activity.subject }}</h4> -->
        <div class="row">
          <div class="col-lg-12">
            <mat-form-field class="full-width">
              <mat-label><span style="font-weight: bold;">Activity Subject</span></mat-label>
              <input matInput [value]="activity.subject" disabled />
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6">
            <mat-form-field class="full-width">
              <mat-label>Activity Type</mat-label>
              <input matInput [value]="activity.activityTypeCode" disabled />
            </mat-form-field>
          </div>
          <div class="col-lg-6">
            <mat-form-field class="full-width">
              <mat-label>Owner</mat-label>
              <input
                matInput
                [value]="
                activity.activityTypeCode == 'Support Activity'
                    ? activity.owner?.firstName + ' ' + activity.owner?.lastName
                    : 'System'
                "
                disabled
              />
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6">
            <mat-form-field class="full-width">
              <mat-label>Created On</mat-label>
              <input
                matInput
                [value]="activity.createdOn | date: 'dd/MM/yyyy HH:mm'"
                disabled
              />
            </mat-form-field>
          </div>
          <div class="col-lg-6">
            <mat-form-field class="full-width">
              <mat-label>Last Updated</mat-label>
              <input
                matInput
                [value]="activity.modifiedOn | date: 'dd/MM/yyyy HH:mm'"
                disabled
              />
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <mat-form-field class="full-width" *ngIf="activity.activityTypeCode!='email'">
              <mat-label>Description</mat-label>
               <textarea
                matInput
                [value]="activity.description"
                disabled
                rows="3"
              ></textarea> 
            </mat-form-field>
            <button
              mat-raised-button
              class="btn btn-primary"
              (click)="showEmail=!showEmail" 
              *ngIf="activity.activityTypeCode=='email'"
            >
            Show email
          </button>
          </div>
        </div>
      </mat-card>
    </div>

    <div class="col-lg-7">
      <h3>Attachments

      <button 
        mat-button 
        (click)="toggleFilterBox()"
        aria-label="Filter the table"
      >
      <mat-icon>filter_list</mat-icon>Filter
      </button>
      <button 
        mat-button
        aria-label="Export the table data"
        (click)="export(exportTitle)"
      >
        <mat-icon> get_app</mat-icon>Export
      </button>
      <button
        mat-button
        (click)="refreshFiles()"
      >
        <mat-icon>refresh</mat-icon>Refresh
      </button>
    </h3>

      <div 
          style="margin:auto; width: 80%; max-width: 500px;"
        >
          <mat-card *ngIf="showFilters">
            <button 
              mat-button 
              style="position: absolute;top:0;right:0;outline: none;"
              (click)="toggleFilterBox()"
            >
              <mat-icon>clear</mat-icon>
            </button>
            <h3 style="text-align: center;">Filter Attachments</h3>

            <mat-card-content>
              <mat-form-field style="margin:auto;width: 100%;">
                <input
                  #titleInp
                  matInput 
                  type="text"
                  placeholder="Type to filter by subject..."
                  (keyup)="updateFilter($event)"
                />
              </mat-form-field>
            </mat-card-content>
          
            <mat-card-actions>
            </mat-card-actions>
          </mat-card>
        </div>

      <div style="overflow-x: auto;">
      <ngx-datatable
        class="material"
        [rows]="rows"
        [columnMode]="'flex'"
        [headerHeight]="50"
        [footerHeight]="50"
        [rowHeight]="50"
        [limit]="10"
        [loadingIndicator]="isLoadingAttachments$ | async"
        style="min-width: 850px;"
      >
        <!-- <ngx-datatable-column name="Subject" [flexGrow]="2">
          <ng-template
            let-row="row"
            let-value="value"
            ngx-datatable-cell-template
          >
            {{ row.subject }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Description" [flexGrow]="2">
          <ng-template
            let-row="row"
            let-value="value"
            ngx-datatable-cell-template
          >
            {{ row.description }}
          </ng-template>
        </ngx-datatable-column> -->
        <ngx-datatable-column name="File Name" [flexGrow]="2">
          <ng-template
            let-row="row"
            let-value="value"
            ngx-datatable-cell-template
          >
            {{ row.fileName }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Created On" [flexGrow]="2">
          <ng-template
            let-row="row"
            let-value="value"
            ngx-datatable-cell-template
          >
            {{ row.createdOn | date: "dd/MM/yyyy HH:mm" }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Download" [flexGrow]="1">
          <ng-template
            let-row="row"
            let-value="value"
            ngx-datatable-cell-template
          >
            <button
              mat-button
              title="Click to download attachment"
              (click)="downloadAttachment(row.id)"
            >
              <mat-icon>cloud_download</mat-icon>
            </button>
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>
    </div>
    </div>

  </div>
  
<div class="row" *ngIf="showEmail">
  <div class="col-lg-6">
    <mat-card class="mat-elevation-z8">
      <div [innerHtml]="activity.description"></div>
    </mat-card>
  </div>
</div>

</div>
</div>