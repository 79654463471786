import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Observable, of } from "rxjs";
import {
  tap,
  map,
  switchMap,
  catchError,
  mergeMap,
  exhaustMap
} from "rxjs/operators";
import { SupportActivityActionTypes } from "../actions/support-activity.actions";
import * as SupportActivityActions from "../actions/support-activity.actions";
import * as CaseActions from "../actions/case.actions";
import { CaseService } from "src/app/services/case.service";
import { MatDialog } from "@angular/material/dialog";
import { CloseCaseDialogComponent } from "src/app/components/cases/case/close-case-dialog/close-case-dialog.component";
import { ErrorService } from "src/app/services/error.service";
import { Action } from "@ngrx/store";
import { UploadFileDialogComponent } from "src/app/components/cases/case/support-activities/support-activity/upload-file-dialog/upload-file-dialog.component";
import { AddActivityDialogComponent } from "src/app/components/cases/case/support-activities/add-activity-dialog/add-activity-dialog.component"
import { CaseActionTypes } from '../actions/case.actions';

@Injectable()
export class SupportActivityEffects {
  constructor(
    private actions: Actions,
    private caseService: CaseService,
    private dialog: MatDialog,
    private errorService: ErrorService
  ) {}

  
  getSupportActivitiesForCase$: Observable<Action> = createEffect(() => this.actions.pipe(
    ofType<SupportActivityActions.GetSupportActivitesForCase>(
      SupportActivityActionTypes.GetSupportActivitesForCase
    ),
    mergeMap(action => {
      return this.caseService
        .getSupportActivitiesForCase(action.payload.caseCrmId)
        .pipe(
          map(activities => {
            return new SupportActivityActions.GetSupportActivitesForCaseSuccess({
              activities
            });
          }),
          catchError(error => {
            const errorMessage = this.errorService.handleError(
              error,
              "Sorry, there's been a problem getting the support activities for the case"
            );
            return of(
              new SupportActivityActions.GetSupportActivitesForCaseFailure({
                error: errorMessage
              })
            );
          })
        );
    })
  ));
  

  
  getAttachmentsForSupportActivity$: Observable<Action> = createEffect(() => this.actions.pipe(
    ofType<SupportActivityActions.GetSupportActivityAttachments>(
      SupportActivityActionTypes.GetSupportActivityAttachments
    ),
    mergeMap(action => {
      return this.caseService
        .getAttachmentsForSupportActivity(
          action.payload.caseCrmId,
          action.payload.activityCrmId
        )
        .pipe(
          map(attachments => {
            return new SupportActivityActions.GetSupportActivityAttachmentsSuccess({
              attachments
            });
          }),
          catchError(error => {
            this.errorService.handleError(
              error,
              "Sorry, there's been a problem getting the attachments for the support activity"
            );
            return of(new SupportActivityActions.GetSupportActivityAttachmentsFailure());
          })
        );
    })
  ));

  
  getAttachmentsForCase$: Observable<Action> = createEffect(() => this.actions.pipe(
    ofType<SupportActivityActions.GetAttachmentsForCase>(
      SupportActivityActionTypes.GetAttachmentsForCase
    ),
    mergeMap(action => {
      return this.caseService
        .getAttachmentsForCase(
          action.payload.caseCrmId
        )
        .pipe(
          map(attachments => {
            return new SupportActivityActions.GetAttachmentsForCaseSuccess({
              attachments
            });
          }),
          catchError(error => {
            this.errorService.handleError(
              error,
              "Sorry, there's been a problem getting all the attachments for the case"
            );
            return of(new SupportActivityActions.GetAttachmentsForCaseFailure());
          })
        );
    })
  ));

  
  downloadAttachmentForSupportActivity$: Observable<void> = createEffect(() => this.actions.pipe(
    ofType<SupportActivityActions.DownloadSupportActivityAttachment>(
      SupportActivityActionTypes.DownloadSupportActivityAttachment
    ),
    map(action => {
      this.caseService.downloadAttachmentForSupportActivity(
        action.payload.caseCrmId,
        action.payload.activityCrmId,
        action.payload.attachmentCrmId
      );
    })
  ), { dispatch: false });

  
  
  openAddNewSupportActivityDialog = createEffect(() => this.actions.pipe(
    ofType<SupportActivityActions.OpenAddNewSupportActivityDialog>(
      SupportActivityActionTypes.OpenAddNewSupportActivityDialog
    ),
    exhaustMap(action => {
      const dialogRef = this.dialog.open(AddActivityDialogComponent, {
        autoFocus: false,
        data: {currentCase : action.payload.currentCase}
      });
      return dialogRef.afterClosed();
    }),
    map((data: any) => {
      if (data == null || data.req == null) {
        return new SupportActivityActions.CloseAddNewSupportActivityDialog();
      }
      //  else {
      //   return new SupportActivityActions.AddNewSupportActivity({
      //     addActivityRequest: data.req
      //   });
      // }
    })
  ));

  
  createNewSupportActivity: Observable<any> = createEffect(() => this.actions.pipe(
    ofType<SupportActivityActions.AddNewSupportActivity>(SupportActivityActionTypes.AddNewSupportActivity),
    mergeMap(action => {
      return this.caseService.createNewSupportActivity(action.payload.activity,action.payload.openActivity).pipe(
        map(newActivity => {
          return new SupportActivityActions.AddNewSupportActivitySuccess({ activity: newActivity });
        }),
        catchError(error => {
          this.errorService.handleError(
            error,
            "Sorry, there's been a problem creating the support activity"
          );
          return of(new SupportActivityActions.AddNewSupportActivityFailure());
        })
      );
    })
  ));

}
