import { Injectable } from "@angular/core";
import { BaseService } from "./base.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Customer } from "../models/customer.model";

const API_URL = `${environment.apiUrl}` + "/customers/";

@Injectable({
  providedIn: "root"
})
export class CustomerService extends BaseService {
  constructor(private http: HttpClient) {
    super();
  }

  private options = {
    headers: new HttpHeaders().set("Content-Type", "application/json")
  };

  getCustomers() {
    return this.http.get<Customer[]>(API_URL, this.options);
  }
}
