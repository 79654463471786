import { Component, OnInit } from "@angular/core";
import { Store, select } from "@ngrx/store";
import { Observable } from "rxjs";

import { User } from "../../models/user.model";
import { State, selectAuthState } from "../../store/reducers/index";
import * as AuthActions from "../../store/actions/auth.actions";

import * as fromReducers from "../../store/reducers";
import { AuthService } from "../../services/auth.service";

import { Title } from '@angular/platform-browser';

@Component({
  selector: "app-welcome",
  templateUrl: "./welcome.component.html",
  styleUrls: ["./welcome.component.scss"]
})
export class WelcomeComponent implements OnInit {
  constructor(
    private store: Store<State>,
    private titleService: Title
    ) {
      this.titleService.setTitle("Support Portal");
    }

  
  isLoggingIn$ = this.store.pipe(select(fromReducers.getCasesIsLoading));

  ngOnInit() {this.store.dispatch(new AuthActions.LogIn());}

  login(): void {
    
    document.getElementById("loader").style.display="block";

    /* log in using ngrx */
    this.store.dispatch(new AuthActions.LogIn());
    /* log in using service directly */
    // this.authService.login();
  }
}
