<h2 matDialogTitle>Upload Attachment</h2>
<hr>
<mat-dialog-content class="text-center dialog">
  <!-- <form [formGroup]="uploadFileForm">
    <div class="row">
      <div class="col-lg-12">
        <mat-form-field class="full-width">
          <mat-label>Title</mat-label>
          <input
            matInput
            formControlName="subjectControl"
            required
            autocomplete="off"
          />
          <mat-error>Please provide a subject for the attachment</mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <mat-form-field class="full-width">
          <mat-label>Description</mat-label>
          <textarea
            matInput
            formControlName="descriptionControl"
            rows="3"
            autocomplete="off"
          ></textarea>
        </mat-form-field>
      </div>
    </div>
  </form> -->

  <div class="upload-container">
    <div
      class="drop-container"
      ngFileDrop
      (uploadOutput)="onUploadOutput($event)"
      [uploadInput]="uploadInput"
      [ngClass]="{ 'is-drop-over': dragOver }"
      [options]="uploadOptions"
    >
      <h1 id="dropzone-text">Drag & Drop File</h1>
    </div>
    <small>or choose a file</small>
    <br>
    <button class="btn btn-info" (click)="fileInput.click()">
      <span>Select</span>
      <mat-icon style="vertical-align: bottom;margin-left: 5px;">folder</mat-icon>
      
      <input
      #fileInput
      style="display: none;"
      type="file"
      ngFileSelect
      (uploadOutput)="onUploadOutput($event)"
      [uploadInput]="uploadInput"
      [options]="uploadOptions"
    />
    </button>
      

    <div class="uploads" *ngIf="files?.length">
      <div class="upload-item" *ngFor="let f of files; let i = index">
        <div class="upload-item-top">
          <span class="filename">{{ f.name }}</span>
          <span class="progress-data">
            {{ f.progress.data.percentage }}% at
            {{ f.progress.data.speedHuman }} eta {{ f.progress.data.eta }}s
          </span>
          <span class="x" (click)="removeFile(f.id)">x</span>
          <mat-progress-bar mode="indeterminate" *ngIf="isUploadingAttachment$ | async"></mat-progress-bar>
        </div>
        <!-- <div class="progress-bar-container">
          <div
            class="bar"
            [style.width]="f.progress.data.percentage + '%'"
            [class.green]="f.progress.data.percentage === 100"
          ></div>
        </div> -->
      </div>
    </div>
  </div>

</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="">CANCEL</button>
  <button mat-button (click)="startUpload()">UPLOAD</button>
</mat-dialog-actions>
