<mat-dialog-content class="text-center dialog">
  <div *ngIf="loggedInUser$ | async as loggedInUser; else loadingForm">
    <!-- <mat-card class="profile-form-card mat-elevation-z8"> -->

    <h3 class="text-center">Profile</h3>

    <hr />
    <div *ngIf="!isChangingPassword">
      <div class="row">
        <div class="col-lg-12">
          <mat-form-field class="full-width">
            <mat-label>Email</mat-label>
            <input
              matInput
              autocomplete="off"
              disabled
              [value]="loggedInUser.email"
            />
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <mat-form-field
            class="full-width"
            *ngIf="!(loggedInUser$ | async)?.isSimpsonsAdmin"
          >
            <mat-label>Name</mat-label>
            <input
              matInput
              autocomplete="off"
              disabled
              [value]="(loggedInUser$ | async)?.firstName + ' ' + (loggedInUser$ | async)?.lastName"
            />
          </mat-form-field>
          <mat-form-field
            class="full-width"
            *ngIf="(loggedInUser$ | async)?.isSimpsonsAdmin"
          >
            <mat-label>Name</mat-label>
            <input
              matInput
              autocomplete="off"
              disabled
              [value]="'Simpson Associates Admin'"
            />
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <mat-form-field
            class="full-width"
            *ngIf="!(loggedInUser$ | async)?.isSimpsonsAdmin"
          >
            <mat-label>Company</mat-label>
            <input
              matInput
              autocomplete="off"
              disabled
              [value]="loggedInUser.crmContact.customer.name"
            />
          </mat-form-field>
          <mat-form-field
            class="full-width"
            *ngIf="(loggedInUser$ | async)?.isSimpsonsAdmin"
          >
            <mat-label>Company</mat-label>
            <input
              matInput
              autocomplete="off"
              disabled
              [value]="'Simpson Associates'"
            />
          </mat-form-field>
        </div>
      </div>
      <div [class.alternative]="otherTheme">
        <mat-slide-toggle
          [checked]="cookiesAllowed"
          (toggleChange)="updateCookies()"
        >
          Allow Cookies
        </mat-slide-toggle>
      </div>      
      <button
        *ngIf="!isChangingPassword"
        type="button"
        (click)="isChangingPassword = true"
        class="btn btn-primary btn-block mt-3"
      >
        Change Password
      </button>
    </div>
    <!-- </mat-card> -->

    <!-- Change Password From -->
    <!-- Add in the password requirements to this screen (e.g. must include digits, uppercase, lowercase etc. - still to need to confirm these) -->
    <mat-card
      class="change-password-form-card mat-elevation-z8 mt-3"
      *ngIf="isChangingPassword"
    >
      <button
        mat-button
        *ngIf="isChangingPassword"
        (click)="cancelChangePassword()"
        [disabled]="isChangingPassword$ | async"
        [class.disabled]="isChangingPassword$ | async"
        style="position: absolute; top: 0; right: 0; outline: none;"
      >
        <mat-icon>clear</mat-icon>
      </button>
      <h4 class="text-center">Change Password</h4>
      <hr />
      <form [formGroup]="changePasswordForm">
        <input
          matInput
          autocomplete="email"
          [value]="loggedInUser.email"
          type="email"
          hidden
        />
        <div class="row">
          <div class="col-lg-12">
            <mat-form-field class="full-width">
              <mat-label>Current Password</mat-label>
              <input
                matInput
                autocomplete="current-password"
                formControlName="currentPasswordControl"
                required
                type="password"
              />
              <mat-error>Please enter your current password</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <mat-form-field class="full-width">
              <mat-label>New Password</mat-label>
              <input
                matInput
                autocomplete="new-password"
                formControlName="newPasswordControl"
                required
                type="password"
              />
              <mat-error>Please enter a new password</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <mat-form-field class="full-width">
              <mat-label>Confirm Password</mat-label>
              <input
                matInput
                autocomplete="new-password"
                formControlName="confirmPasswordControl"
                required
                type="password"
              />
            </mat-form-field>
            <mat-error
              *ngIf="
                changePasswordForm.errors?.nonMatchingPasswords &&
                (changePasswordForm.touched || changePasswordForm.dirty)
              "
            >
              The passwords you have entered do not match
            </mat-error>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12 password-criteria">
            <p>Please note your password must meet the following criteria:</p>
            <ul>
              <li>Must be at least 8 characters long</li>
              <li>Must contain the following:</li>
              <li style="list-style: none;">
                <ul>
                  <li>At least one uppercase character</li>
                  <li>At least one lowercase character</li>
                  <li>At least one number</li>
                  <li>
                    At least one non-alphanumeric character (!, ?, £, $, %, +
                    etc.)
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
        <button
          (click)="changePassword()"
          class="btn btn-warning btn-block mt-3"
          [disabled]="isChangingPassword$ | async"
          [class.disabled]="isChangingPassword$ | async"
        >
          Change Password
        </button>
      </form>
    </mat-card>
  </div>
  <ng-template #loadingForm>
    Please wait...
  </ng-template>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="">CLOSE</button>
</mat-dialog-actions>
