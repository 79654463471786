import { Component, OnInit } from "@angular/core";
import * as fromReducers from "./store/reducers";
import { Store } from "@ngrx/store";
import * as AuthActions from "./store/actions/auth.actions";
import { Router, RoutesRecognized, RouterStateSnapshot } from "@angular/router";
import { take, map, first } from "rxjs/operators";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit {
  title = "Support Portal";

  constructor(
    private store: Store<fromReducers.State>,
    private router: Router
  ) {}

  ngOnInit() {
    this.router.events
      .pipe(first(e => e instanceof RoutesRecognized))
      .subscribe(e => {
        const url = (e as RoutesRecognized).url;
        if (url !== "/auth-callback") {
          this.store.dispatch(new AuthActions.LoginValidation());
        }
      });
  }
}
