import { Component, Input, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Case } from 'src/app/models/case.model';
import { TokenEvent } from 'src/app/models/tokens/token-event.model';
import * as fromReducers from "../../../../store/reducers";

@Component({
  selector: 'app-token-case-table',
  templateUrl: './token-case-table.component.html',
  styleUrls: ['./token-case-table.component.scss']
})
export class TokenCaseTableComponent implements OnInit {

  constructor(private store: Store<fromReducers.State>) { }
  @Input() rows: TokenEvent[];
  @Input() loadingIndicator: boolean;
  @Input() activityrows: TokenEvent[];
  
  showMonthTable = true;
  showCaseTable = true;
  showActivityTable = true;
  error$ = this.store.pipe(select(fromReducers.getCasesError));

  caseFilterString: string;
  monthFilterString: string;

  temp: TokenEvent[];
  filteredRows: TokenEvent[];
  activityTemp: TokenEvent[];
  activityFilteredRows: TokenEvent[];
  monthYearString;
  ngOnInit() {
    this.temp = this.rows;
    this.filteredRows = this.temp;
    this.activityTemp = this.activityrows;
    this.activityFilteredRows = this.activityTemp;
  }

  getMonthFromString(mon){
    return new Date(Date.parse(mon +" 1, 2012")).getMonth();
  }

  monthFilter(month: string){
    this.showMonthTable = false;
    this.showCaseTable = true;
    let monthFilter, yearFilter;
    this.monthFilterString = month;
    let dateStringArray = month.split(" ");
    let monthString = dateStringArray[0];
    let yearString = dateStringArray[1];
    monthFilter = this.getMonthFromString(monthString);
    yearFilter = +yearString;

    // var filteredData = this.rows.filter(d=>!d.isActivity);
    // filter by date
    // if(monthFilter != null && yearFilter != null){
    //   filteredData = filteredData.filter((tokenEvent: TokenEvent) => ((new Date(tokenEvent.createdOn)).getMonth() == monthFilter) &&
    //     ((new Date(tokenEvent.createdOn)).getFullYear() == yearFilter));
    // }
    // this.rows = filteredData;
  }

  updateFilter(caseNumber,date: Date) {
    this.caseFilterString = caseNumber;
    this.showActivityTable = true;
    this.showCaseTable = false;
    console.log("filtering activities");
    this.activityrows = this.activityrows
    .filter(te => {
      te.caseNumber == caseNumber &&
      ((new Date(te.createdOn)).getMonth() == date.getMonth()) &&
      ((new Date(te.createdOn)).getFullYear() == date.getFullYear())
    });
    
    }

  
  resetFilters(){
    this.showMonthTable = true;
    this.showCaseTable = false;
    this.showActivityTable = false;
    this.caseFilterString = null;
    this.monthFilterString = null;
  }

  resetCaseFilters(){
    this.showCaseTable = true;
    this.showActivityTable = false;
    this.caseFilterString = null;
  }
  
  // resetFilters(){
  //   // reset filters
  //   this.casesBool = true;
  //   this.purchasesBool = true;
  //   this.titleValue = "";
  //   this.caseNumValue = "";
  //   this.monthYearString = null;
  //   this.rows = this.temp;
  // }

  // toggleFilterBox(){
    
  //   this.showFilters = !this.showFilters;
  //   this.resetFilters();
  // }

  // updateFilter(fieldNum, event?, monthChosen?) {
  //   console.log("filtering");
  //   // Get current filter values
  //   let casesBool = this.casesBool;
  //   let purchasesBool = this.purchasesBool;
  //   let monthFilter = this.monthTableFilter;
  //   let yearFilter = this.yearTableFilter;

  //   // Update the specified filter
  //   if(event != null && fieldNum == 1){
  //     this.titleValue =  event.target.value.toLowerCase();
  //   }
  //   else if(event != null && fieldNum == 2){
  //     this.caseNumValue =  event.target.value.toLowerCase();
  //   }
  //   else if(event == null && fieldNum == 3){
  //     casesBool = !casesBool
  //   }
  //   else if(event == null && fieldNum == 4){
  //     purchasesBool = !purchasesBool
  //   }
  //   else if(event != null && fieldNum == 5 || monthChosen != null){
  //     let month;
  //     if(monthChosen != null || event.series != null){
  //       if(monthChosen != null)
  //         month = monthChosen as string;
  //       else
  //         month = event.series as string;
      
  //       this.monthYearString = month;
  //       let dateStringArray = month.split(" ");
  //       let monthString = dateStringArray[0];
  //       let yearString = dateStringArray[1];
  //       monthFilter = this.getMonthFromString(monthString);
  //       yearFilter = +yearString;
  //     }
  //   }

  //   var filteredData = this.temp.filter(d=>!d.isActivity);
  //   // filter by date
  //   if(monthFilter != null && yearFilter != null){
  //     filteredData = filteredData.filter((tokenEvent: TokenEvent) => ((new Date(tokenEvent.createdOn)).getMonth() == monthFilter) &&
  //       ((new Date(tokenEvent.createdOn)).getFullYear() == yearFilter));
  //   }

  //   // filter our checkboxes if not both checked
  //   if(!(casesBool && purchasesBool)){
  //     if(casesBool){
  //       filteredData = filteredData.filter(d=>d.caseNumber != null);
  //     }
  //     else if(purchasesBool) {
  //       filteredData = filteredData.filter(d=>d.caseNumber == null);
  //     }
  //   }
  //   // filter by casenumber
  //   if(this.caseNumValue != null && this.caseNumValue != ""){
  //     filteredData = filteredData.filter(d=>d.caseNumber!=null&&d.caseNumber.toLowerCase().includes(this.caseNumValue));
  //   }

  //   // filter by title
  //   if(this.titleValue != null && this.titleValue != ""){
  //     filteredData = filteredData.filter(d=>d.title.toLowerCase().includes(this.titleValue));
  //   }

  //   // update the rows
  //   this.rows = filteredData;
  // }

  // export(){
  //   let exportData= this.rows;
  //   if (exportData.length>0){
  //     this.exportService.exportAsCSV(exportData,"Token Events");
  //   } else{
  //     this.notificationService.showError("Sorry there has been a problem exporting data: No Data", "Close");
  //   };

  // }


}
