<div [class.alternative]="otherTheme">
<div class="page-container" *ngIf="case$ | async as case; else loading">
  <button mat-raised-button class="btn btn-secondary" (click)="back()" style="float: left;">Back</button>
  <button
    mat-raised-button
    class="btn btn-danger"
    [class.disabled]="(isClosingCase$ | async) || (case.stateCode != 0 || case.statusReasonCode == '894820002')"
    [disabled]="(isClosingCase$ | async) || (case.stateCode != 0 || case.statusReasonCode == '894820002')"
    (click)="openCustomerCloseDialog()"
    id="closeCaseButton"
    [title]="
      case.stateCode == 2
        ? 'This case is cancelled and so cannot be closed'
        : case.stateCode == 1
        ? 'This case is already closed'
        : 'Close this case'
    "
  >
    <span *ngIf="isClosingCase$ | async">
      <fa-icon
        class="mr-2"
        [icon]="['fas', 'spinner']"
        [spin]="true"
      ></fa-icon>
      Closing...</span
    >
    <span *ngIf="!(isClosingCase$ | async)">Close Case</span>
  </button>
  
  <h1 style="text-align: center;font-size: 2.3rem;">Case Details</h1>
  <hr />

  <div class="row">
    <div class="col-lg-4">
      <h3>Case Info</h3>
      
      <mat-card class="mat-elevation-z8">
        <!-- <h4 class="mb-3"> {{ case.title }}</h4> -->
        <div class="row">
          <div class="col-lg-12">
            <mat-form-field class="full-width">
              <mat-label><span style="font-weight: bold;">Case Title</span></mat-label>
              <input matInput [value]="case.title" disabled />
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6">
            <mat-form-field class="full-width">
              <mat-label>Case number</mat-label>
              <input matInput [value]="case.caseNumber" disabled />
            </mat-form-field>
          </div>
          <div class="col-lg-6">
            <mat-form-field class="full-width">
              <mat-label>Owner</mat-label>
              <input
                matInput
                [value]="case.owner?.firstName + ' ' + case.owner?.lastName"
                disabled
              />
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6">
            <mat-form-field class="full-width">
              <mat-label>Customer</mat-label>
              <input matInput [value]="case.customer?.name" disabled />
            </mat-form-field>
          </div>
          <div class="col-lg-6">
            <mat-form-field class="full-width">
              <mat-label>Customer Contact</mat-label>
              <input
                matInput
                [value]="
                  case.customerContact?.firstName == null
                    ? ''
                    : case.customerContact?.firstName +
                      ' ' +
                      case.customerContact?.lastName
                "
                disabled
              />
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6">
            <mat-form-field class="full-width">
              <mat-label>Status</mat-label>
              <input matInput [value]="case.state?.label" disabled />
            </mat-form-field>
          </div>
          <div class="col-lg-6">
            <mat-form-field class="full-width">
              <mat-label>Status Reason</mat-label>
              <input matInput [value]="case.statusReason?.label" disabled />
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6">
            <mat-form-field class="full-width">
              <mat-label>Created On</mat-label>
              <input
                matInput
                [value]="case.createdOn | date: 'dd/MM/yyyy HH:mm'"
                disabled
              />
            </mat-form-field>
          </div>
          <div class="col-lg-6">
            <mat-form-field class="full-width">
              <mat-label>Last Updated</mat-label>
              <input
                matInput
                [value]="case.modifiedOn | date: 'dd/MM/yyyy HH:mm'"
                disabled
              />
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6">
            <mat-form-field class="full-width">
              <mat-label>Priority</mat-label>
              <input matInput [value]="case.priority?.label" disabled />
            </mat-form-field>
          </div>
          <div class="col-lg-6">
            <mat-form-field class="full-width">
              <mat-label>Tokens Used</mat-label>
              <input matInput [value]="case.tokenSpend?.tokensSpent" disabled />
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <mat-form-field class="full-width">
              <mat-label>Description</mat-label>
              <textarea
                matInput
                [value]="case.description"
                disabled
                rows="10"
              ></textarea>
            </mat-form-field>
          </div>
          

        </div>
      </mat-card>
    </div>
  <!-- </div> -->
  
    

  <!-- <div class="row mt-5"> -->
    <div class="col-lg-8">
      <h3>
        Timeline 
      <button 
        mat-button 
        (click)="toggleFilterBox()"
        aria-label="Filter the table"
      >
        <mat-icon>filter_list</mat-icon>Filter
      </button>
      <button 
        mat-button
        aria-label="Export the table data"
        (click)="export(exportTitle)"
      >
        <mat-icon> get_app</mat-icon>Export
      </button>
      <button
        mat-button
        (click)="refreshActivities()"
      >
        <mat-icon>refresh</mat-icon>Refresh
      </button>
      
    </h3>

      <div 
        style="margin:auto; width: 80%; max-width: 500px;"
      >
        <mat-card *ngIf="showFilters">
          <button 
            mat-button 
            style="position: absolute;top:0;right:0;outline: none;"
            (click)="toggleFilterBox()"
          >
            <mat-icon>clear</mat-icon>
          </button>
          <h3 style="text-align: center;">Filter Activities</h3>

          <mat-card-content>
            <mat-form-field style="margin:auto;width: 100%;">
              <input
                #titleInp
                matInput 
                type="text"
                placeholder="Type to filter by subject..."
                (keyup)="updateFilter($event)"
              />
            </mat-form-field>
          </mat-card-content>
        </mat-card>
      </div>
      <app-support-activities
        [activities]="rows"
        [loadingIndicator]="isLoadingSupportActivities$ | async"
        [caseClosed]="(case$ | async).stateCode=='0'"
        [currentCase]="(case$ | async)"
      ></app-support-activities>
      <br>
      <h3> Attachments 
        <button
         *ngIf="(case$ | async).stateCode=='0'"
          mat-button
          aria-label="Attach file to case"
          (click)="attachFile()"
        >
          <mat-icon> attach_file</mat-icon>Attach File
        </button>
        <button
          *ngIf="!((case$ | async).stateCode=='0')"
          disabled
          mat-button
          aria-label="Attach file to case"
          (click)="attachFile()"
        >
          <mat-icon> attach_file</mat-icon>Attach File
        </button>
      <button
        mat-button
        (click)="refreshFiles()"
      >
        <mat-icon>refresh</mat-icon>Refresh
      </button>
      </h3>
      <div style="overflow-x: auto;">
      <ngx-datatable
      class="material"
      [rows]="caseAttachments$ | async"
      [columnMode]="'flex'"
      [headerHeight]="50"
      [footerHeight]="50"
      [rowHeight]="50"
      [limit]="10"
      [loadingIndicator]="isLoadingAttachments$ | async"
      style="min-width: 850px;"
    >
      <!-- <ngx-datatable-column name="Subject" [flexGrow]="1">
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          <div  matTooltip="{{value}}">
            {{value}}
          </div>
          
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="Description" [flexGrow]="1">
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          <div matTooltip="{{value}}">{{ value }}</div>
        </ng-template>
      </ngx-datatable-column> -->
      <ngx-datatable-column name="File Name" [flexGrow]="1"  prop="fileName">
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          {{ value }}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="Created On" [flexGrow]="1"  prop="createdOn">
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          {{ row.createdOn | date: "dd/MM/yyyy HH:mm" }}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="Activty" [flexGrow]="1"  prop="supportActivity.subject">
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          <a [routerLink]="['/cases', row.supportActivity.caseId, 'activities', row.supportActivity.id]">
            {{ row.supportActivity.subject }}</a>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="Download" [flexGrow]="1">
        <ng-template
          let-row="row"
          let-value="value"
          ngx-datatable-cell-template
        >
          <button
            mat-button
            title="Click to download attachment"
            (click)="downloadAttachment(row.id, row.supportActivity.id)"
          >
            <mat-icon>cloud_download</mat-icon>
          </button>
         
          
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
    </div>
    </div>
  </div>

  

</div>
</div>


<ng-template #loading>
  <app-loading></app-loading>
</ng-template>
