import { Action } from "@ngrx/store";
import { Customer } from "src/app/models/customer.model";

export enum CustomerActionTypes {
  GetCustomers = "[Customer] Get Customers",
  GetCustomersSuccess = "[Customer] Get Customers Success",
  GetCustomersFailure = "[Customer] Get Customers Failure"
}

export class GetCustomers implements Action {
  readonly type = CustomerActionTypes.GetCustomers;
}

export class GetCustomersSuccess implements Action {
  readonly type = CustomerActionTypes.GetCustomersSuccess;
  constructor(public payload: { customers: Customer[] }) {}
}

export class GetCustomersFailure implements Action {
  readonly type = CustomerActionTypes.GetCustomersFailure;
}

export type CustomerActions =
  | GetCustomers
  | GetCustomersSuccess
  | GetCustomersFailure;
