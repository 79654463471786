import {
  ActionReducer,
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
  MetaReducer
} from "@ngrx/store";
import { environment } from "../../../environments/environment";

import * as fromAuth from "./auth.reducer";
import * as fromCases from "./case.reducer";
import * as fromMetadata from "./metadata.reducer";
import * as fromContacts from "./contact.reducer";
import * as fromCustomers from "./customer.reducer";
import * as fromEntitlements from "./entitlement.reducer";
import * as fromUsers from "./user.reducer";

// (wrong spelling of "activities" but follows naming convention)
import * as fromSupportActivitys from "./support-activity.reducer";


export interface State {
  auth: fromAuth.State;
  case: fromCases.State;
  metadata: fromMetadata.State;
  contact: fromContacts.State;
  customer: fromCustomers.State;
  entitlement: fromEntitlements.State;
  user: fromUsers.State;
  supportActivity: fromSupportActivitys.State;
}

export const reducers: ActionReducerMap<State> = {
  auth: fromAuth.reducer,
  case: fromCases.reducer,
  metadata: fromMetadata.reducer,
  contact: fromContacts.reducer,
  customer: fromCustomers.reducer,
  entitlement: fromEntitlements.reducer,
  user: fromUsers.reducer,
  supportActivity: fromSupportActivitys.reducer

};

export const metaReducers: MetaReducer<State>[] = !environment.production
  ? []
  : [];

export const selectAuthState = createFeatureSelector<fromAuth.State>("auth");
export const getLoggedInUser = createSelector(
  selectAuthState,
  fromAuth.getLoggedInUser
);
export const getLoggedInUserIsAuthenticated = createSelector(
  selectAuthState,
  fromAuth.getLoggedInUserIsAuthenticated
);
export const getIsChangingPassword = createSelector(
  selectAuthState,
  fromAuth.getIsChangingPassword
);
export const getIsLoggingIn = createSelector(
  selectAuthState,
  fromAuth.getIsLoggingIn
);


export const selectCasesState = createFeatureSelector<fromCases.State>("case");
export const getCasesAll = createSelector(
  selectCasesState,
  fromCases.selectAll
);
export const getCasesIsLoading = createSelector(
  selectCasesState,
  fromCases.getIsLoading
);
export const getCasesSelectedCase = createSelector(
  selectCasesState,
  fromCases.getSelectedCase
);
export const getCasesIsCreatingCase = createSelector(
  selectCasesState,
  fromCases.getIsCreatingNewCase
);
export const getCasesIsClosingCase = createSelector(
  selectCasesState,
  fromCases.getIsClosingCase
);
export const getCasesError = createSelector(
  selectCasesState,
  fromCases.getCasesError
);
export const getIsCreatingNewCase = createSelector(
  selectCasesState,
  fromCases.getIsCreatingNewCase
);
export const getIsClosingCase = createSelector(
  selectCasesState,
  fromCases.getIsClosingCase
);




export const selectSupportActivitysState = createFeatureSelector<fromSupportActivitys.State>("supportActivity");
export const getSupportActivities = createSelector(
  selectSupportActivitysState,
  fromSupportActivitys.getSupportActivites
);
export const getIsLoadingSupportActivities = createSelector(
  selectSupportActivitysState,
  fromSupportActivitys.getIsLoadingSupportActivities
);
export const getSupportActivitiesError = createSelector(
  selectSupportActivitysState,
  fromSupportActivitys.getSupportActivitesError
);
export const getSupportActivitiesAttachments = createSelector(
  selectSupportActivitysState,
  fromSupportActivitys.getSupportActivityAttachments
);
export const getAttachmentsForCase = createSelector(
  selectSupportActivitysState,
  fromSupportActivitys.getAttachmentsForCase
);
export const getSupportActivitiesIsLoadingAttachments = createSelector(
  selectSupportActivitysState,
  fromSupportActivitys.getSupportActivityIsLoadingAttachments
);
export const getSupportActivitiesIsUploadingAttachment = createSelector(
  selectSupportActivitysState,
  fromSupportActivitys.getSupportActivityIsUploadingAttachment
);
export const getLatestActivity = createSelector(
  selectSupportActivitysState,
  fromSupportActivitys.getLatestActivity
);


export const selectMetadataState = createFeatureSelector<fromMetadata.State>(
  "metadata"
);
export const getStatusReasonCodesAll = createSelector(
  selectMetadataState,
  fromMetadata.getStatusReasonCodes
);
export const getStateCodesAll = createSelector(
  selectMetadataState,
  fromMetadata.getStateCodes
);
export const getPriorityCodesAll = createSelector(
  selectMetadataState,
  fromMetadata.getPriorityCodes
);
export const getTypeCodesAll = createSelector(
  selectMetadataState,
  fromMetadata.getTypeCodes
);
export const getRolesAll = createSelector(
  selectMetadataState,
  fromMetadata.getRoles
);
export const getPasswordStatusList = createSelector(
  selectMetadataState,
  fromMetadata.getPasswordStatusList
);
export const getActivityTypesAll = createSelector(
  selectMetadataState,
  fromMetadata.getActivityTypes
);

export const selectContactsState = createFeatureSelector<fromContacts.State>(
  "contact"
);
export const getContactsAll = createSelector(
  selectContactsState,
  fromContacts.selectAll
);
export const getContactsIsLoading = createSelector(
  selectContactsState,
  fromContacts.getIsLoading
);

export const selectCustomersState = createFeatureSelector<fromCustomers.State>(
  "customer"
);
export const getCustomersAll = createSelector(
  selectCustomersState,
  fromCustomers.selectAll
);
export const getCustomersIsLoading = createSelector(
  selectCustomersState,
  fromCustomers.getIsLoading
);


export const selectEntitlementsState = createFeatureSelector<fromEntitlements.State>(
  "entitlement"
);
export const getEntitlementsAll = createSelector(
  selectEntitlementsState,
  fromEntitlements.selectAll
);
export const getDefaultEntitlement = createSelector(
  selectEntitlementsState,
  fromEntitlements.getDefaultEntitlement
);
export const getEntitlementsIsLoading = createSelector(
  selectEntitlementsState,
  fromEntitlements.getIsLoading
);
export const getEntitlementsIsLoadingTokenEvents = createSelector(
  selectEntitlementsState,
  fromEntitlements.getIsLoadingTokenEvents
);
export const getTokenEvents = createSelector(
  selectEntitlementsState,
  fromEntitlements.GetTokenEvents
);


export const selectUserState = createFeatureSelector<fromUsers.State>("user");
export const getUsersAll = createSelector(selectUserState, fromUsers.selectAll);
export const getUsersIsLoading = createSelector(
  selectUserState,
  fromUsers.getIsLoading
);
export const getIsAddingNewPortalUser = createSelector(
  selectUserState,
  fromUsers.getIsAddingNewPortalUser
);
export const getIsTogglingUserIsActive = createSelector(
  selectUserState,
  fromUsers.getIsTogglingUserIsActive
);
export const getIsChangingUserType = createSelector(
  selectUserState,
  fromUsers.getIsChangingUserType
);
