import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cookie-banner',
  templateUrl: './cookie-banner.component.html',
  styleUrls: ['./cookie-banner.component.scss']
})
export class CookieBannerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    this.cookieDecision = localStorage.getItem("cookies-allowed");
    console.log("Cookies status: ",this.cookieDecision)
    
  }

  cookieDecision;

  acceptCookies(){
    localStorage.setItem('cookies-allowed', "true");
    this.cookieDecision = "true";
  }

  denyCookies(){
    localStorage.setItem('cookies-allowed', "false");
    this.cookieDecision = "false";


  }

}
