import { Action } from "@ngrx/store";
import { Entitlement } from "src/app/models/tokens/entitlement.model";
import { TokenEvent } from 'src/app/models/tokens/token-event.model';

export enum EntitlementActionTypes {
  GetEntitlements = "[Entitlements] Get Entitlements",
  GetEntitlementsSuccess = "[Entitlements] Get Entitlements Success",
  GetEntitlementsFailure = "[Entitlements] Get Entitlements Failure",
  GetTokenEvents = "[Entitlements] Get Token Events",
  GetTokenEventsSuccess = "[Entitlements] Get Token Events Success",
  GetTokenEventsFailure = "[Entitlements] Get Token Events Failure"
}

export class GetEntitlements implements Action {
  readonly type = EntitlementActionTypes.GetEntitlements;
  constructor(public payload: { customerId: string }) {}
}

export class GetEntitlementsSuccess implements Action {
  readonly type = EntitlementActionTypes.GetEntitlementsSuccess;
  constructor(public payload: { entitlements: Entitlement[] }) {}
}

export class GetEntitlementsFailure implements Action {
  readonly type = EntitlementActionTypes.GetEntitlementsFailure;
}


export class GetTokenEvents implements Action {
  readonly type = EntitlementActionTypes.GetTokenEvents;
  constructor(public payload: { customerId: string }) {}
}

export class GetTokenEventsSuccess implements Action {
  readonly type = EntitlementActionTypes.GetTokenEventsSuccess;
  constructor(public payload: { tokenEvents: TokenEvent[] }) {}
}

export class GetTokenEventsFailure implements Action {
  readonly type = EntitlementActionTypes.GetTokenEventsFailure;
}

export type EntitlementActions =
  | GetEntitlements
  | GetEntitlementsSuccess
  | GetEntitlementsFailure
  | GetTokenEvents
  | GetTokenEventsSuccess
  | GetTokenEventsFailure;
