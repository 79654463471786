import { User } from "../../models/user.model";
import { AuthActionTypes, All } from "../actions/auth.actions";
import { SupportActivityActionTypes, SupportActivityActions } from "../actions/support-activity.actions";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { Action } from "rxjs/internal/scheduler/Action";
import { SupportActivity } from "src/app/models/support-activity.model";
import { Attachment } from "src/app/models/attachment.model";

export interface State extends EntityState<SupportActivity> {
  isLoadingSupportActivities: boolean;
  supportActivitiesError: string;
  supportActivities: SupportActivity[];
  isLoadingAttachments: boolean;
  attachments: Attachment[];
  caseAttachments: Attachment[];
  isUploadingAttachment: boolean;
  isAddingNewSupportActivity: boolean;
  isLoading: boolean;
  latestActivity: SupportActivity;
}

export const adapter: EntityAdapter<SupportActivity> = createEntityAdapter<SupportActivity>();

export const initialState: State = adapter.getInitialState({
  isLoadingSupportActivities: false,
  supportActivitiesError: null,
  supportActivities: null,
  isLoadingAttachments: false,
  attachments: null,
  caseAttachments: null,
  isUploadingAttachment: false,
  isAddingNewSupportActivity: false,
  isLoading: false,
  latestActivity: null
});

export function reducer(state = initialState, action: SupportActivityActions): State {
  switch (action.type) {
    
    case SupportActivityActionTypes.GetSupportActivitesForCase: {
      return {
        ...state,
        isLoading: true,
        supportActivitiesError: null,
        supportActivities: null
      };
    }
    case SupportActivityActionTypes.GetSupportActivitesForCaseSuccess: {
      // TODO: Support activities should be upserted for better performance
      return {
        ...state,
        isLoading: false,
        supportActivities: action.payload.activities
      };
    }
    case SupportActivityActionTypes.GetSupportActivitesForCaseFailure: {
      return {
        ...state,
        isLoading: false,
        supportActivitiesError: action.payload.error
      };
    }

    case SupportActivityActionTypes.GetSupportActivityAttachments: {
      return {
        ...state,
        isLoadingAttachments: true,
        attachments: null
      };
    }
    case SupportActivityActionTypes.GetSupportActivityAttachmentsSuccess: {
      return {
        ...state,
        isLoadingAttachments: false,
        attachments: action.payload.attachments
      };
    }
    case SupportActivityActionTypes.GetSupportActivityAttachmentsFailure: {
      return {
        ...state,
        isLoadingAttachments: false
      };
    }

    case SupportActivityActionTypes.GetAttachmentsForCase: {
      return {
        ...state,
        isLoadingAttachments: true,
        caseAttachments: null
      };
    }
    case SupportActivityActionTypes.GetAttachmentsForCaseSuccess: {
      return {
        ...state,
        isLoadingAttachments: false,
        caseAttachments: action.payload.attachments
      };
    }
    case SupportActivityActionTypes.GetAttachmentsForCaseFailure: {
      return {
        ...state,
        isLoadingAttachments: false
      };
    }

    case SupportActivityActionTypes.DownloadSupportActivityAttachment: {
      return {
        ...state
      };
    }

    case SupportActivityActionTypes.UploadSupportActivityAttachment: {
      return {
        ...state,
        isUploadingAttachment: true
      };
    }
    case SupportActivityActionTypes.UploadSupportActivityAttachmentSuccess: {
      return {
        ...state,
        isUploadingAttachment: false
      };
    }
    case SupportActivityActionTypes.UploadSupportActivityAttachmentFailure: {
      return {
        ...state,
        isUploadingAttachment: false
      };
    }

    case SupportActivityActionTypes.AddNewSupportActivity: {
      return {
        ...state,
        isLoading: true,
        latestActivity: null
      };
    }
    case SupportActivityActionTypes.AddNewSupportActivitySuccess: {
      const newState = adapter.upsertOne(action.payload.activity, state);
      return {
        ...newState,
        isLoading: false,
        latestActivity: action.payload.activity
      };
    }
    case SupportActivityActionTypes.AddNewSupportActivityFailure: {
      return {
        ...state,
        isLoading: false,
        latestActivity: null
      };
    }

    default: {
      return state;
    }
  }
}

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal
} = adapter.getSelectors();

// export const getIsLoading = (state: State) => state.isLoading;
// export const getSelectedCase = (state: State) =>
//   state.selectedCaseId == null ? null : state.entities[state.selectedCaseId];
// export const getIsCreatingNewCase = (state: State) => state.isCreatingCase;
// export const getIsClosingCase = (state: State) => state.isClosingCase;
// export const getCasesError = (state: State) => state.casesError;
export const getSupportActivites = (state: State) => state.supportActivities;
export const getIsLoadingSupportActivities = (state: State) =>
  state.isLoading;
export const getSupportActivitesError = (state: State) =>
  state.supportActivitiesError;
export const getSupportActivityAttachments = (state: State) =>
  state.attachments;
export const getAttachmentsForCase = (state: State) =>
  state.caseAttachments;
export const getSupportActivityIsLoadingAttachments = (state: State) =>
  state.isLoadingAttachments;
export const getSupportActivityIsUploadingAttachment = (state: State) =>
  state.isUploadingAttachment;
export const getLatestActivity = (state: State) =>
  state.latestActivity;
