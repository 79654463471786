import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, BehaviorSubject, from } from "rxjs";

import { Store } from "@ngrx/store";

import { BaseService } from "./base.service";

import { User as UserModel } from "../models/user.model";
import { UserManager, UserManagerSettings, User } from "oidc-client";
import { LogInSuccess, LogOut } from "../store/actions/auth.actions";

import { State, selectAuthState } from "../store/reducers";
import { Customer } from "../models/customer.model";
import { Contact } from "../models/contact.model";
import { map, tap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { Router } from "@angular/router";
import { Role } from '../models/role.model';

const API_URL = `${environment.apiUrl}/`;

@Injectable()
export class AuthService extends BaseService {
  constructor(
    private http: HttpClient,
    private store: Store<State>,
    private router: Router
  ) {
    super();

    this.manager.getUser().then((user) => {
      this.user = user;
      this.authNavStatusSource.next(this.isAuthenticated());
    });
  }
  private BASE_URL = environment.authUrl;

  private options = {
    headers: new HttpHeaders().set("Content-Type", "application/json"),
  };

  private config = {
    authority: environment.authUrl,
    client_id: "spa",
    redirect_uri: window.location.origin + "/auth-callback",
    post_logout_redirect_uri: window.location.origin + "/",

    // these two will be done dynamically from the buttons clicked, but are
    // needed if you want to use the silent_renew
    response_type: "code",
    scope: "openid profile email crm IdentityServerApi",

    // this will toggle if profile endpoint is used
    loadUserInfo: true,

    // silent renew will get a new access_token via an iframe
    // just prior to the old access_token expiring (60 seconds prior)
    silent_redirect_uri: window.location.origin + "/silent-callback",
    automaticSilentRenew: true,
  };

  // Observable navItem source
  private authNavStatusSource = new BehaviorSubject<boolean>(false);
  // Observable navItem stream
  authNavStatus$ = this.authNavStatusSource.asObservable();

  private manager = new UserManager(this.config);
  private user: User | null;

  getUser() {
    return from(this.manager.getUser()).pipe(
      map((oidcUser) => this.convertToApplicationUser(oidcUser))
    );
  }

  getToken(): string {
    if (this.user != null) {
      return this.user.access_token;
    }
  }

  convertToApplicationUser(oidcUser: User): UserModel {
    const profile = oidcUser.profile;
    const user = {
      id: profile.crmcontactid,
      email: profile.email,
      role: {id: null, name: profile.role} as Role,
      crmContact: {
        id: profile.crmcontactid,
        customer: {
          id: profile.crmcustomerid,
          name: profile.crmcustomername,
        },
      } as Contact,
      isSimpsonsAdmin: profile.issimpsonsadmin,
    } as UserModel;

    return user;
  }

  async validateLogin() {
    const user = await this.manager.getUser();
    if (user != null) {
      if (user.expired) {
        return null;
      } else {
        return this.convertToApplicationUser(user);
      }
    }
    return null;
  }

  // takes user to login page (which is on server side)
  login() {
    return this.manager.signinRedirect();
  }

  logout() {
    this.manager.signoutRedirect();
  }

  isAuthenticated(): boolean {
    return this.user != null && !this.user.expired;
  }

  getStatus(): Observable<User> {
    const url = `${this.BASE_URL}/status`;
    return this.http.get<User>(url);
  }

  async completeAuthentication() {
    this.user = await this.manager.signinRedirectCallback();

    this.authNavStatusSource.next(this.isAuthenticated());
    if (this.isAuthenticated()) {
      return this.convertToApplicationUser(this.user);
    }

    return null;
  }

  handleCallback() {
    this.manager.signinRedirectCallback().then(
      function (user) {
        var hash = window.location.hash.substr(1);
        var result = hash.split("&").reduce(function (result, item) {
          var parts = item.split("=");
          result[parts[0]] = parts[1];
          return result;
        }, {});

        console.log(
          "handleCallback ",
          window.document.title +
            window.location.origin +
            window.location.pathname
        );
        // showTokens();

        window.history.replaceState(
          {},
          window.document.title,
          window.location.origin + window.location.pathname
        );
      },
      function (error) {
        console.log(error);
      }
    );
  }

  // Change a user's password, and redirect them to the confirmation screen
  changePassword(currentPassword: string, newPassword: string) {
    return this.http.post(
      API_URL + "users/change-password",
      JSON.stringify({ currentPassword, newPassword }),
      this.options
    );
  }
}

// export function getClientSettings(): UserManagerSettings {
//   return {
//       authority: 'http://localhost:44678',
//       client_id: 'angular_spa',
//       redirect_uri: 'http://localhost:44678/auth-callback',
//       post_logout_redirect_uri: 'http://localhost:44678/',
//       response_type:"id_token token",
//       scope:"openid profile email api.read",
//       filterProtocolClaims: true,
//       loadUserInfo: true,
//       automaticSilentRenew: true,
//       silent_redirect_uri: 'http://localhost:44678/silent-refresh.html'
//   };
// }
