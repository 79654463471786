import { Component, OnInit, OnDestroy } from "@angular/core";

import * as CaseActions from "../../../../../store/actions/case.actions";
import * as SupportActivityActions from "../../../../../store/actions/support-activity.actions";
import { Subscription, combineLatest } from "rxjs";
import { ActivatedRoute } from "@angular/router";
import { Store, select } from "@ngrx/store";

import * as fromReducers from "../../../../../store/reducers";
import { map } from "rxjs/operators";
import { ThemeService } from 'src/app/services/theme.service';
import { Attachment } from 'src/app/models/attachment.model';
import { ExportToCsvService } from 'src/app/services/export-to-csv.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ExportAttachment } from 'src/app/models/export-models/export-attachment.model';

@Component({
  selector: "app-support-activity",
  templateUrl: "./support-activity.component.html",
  styleUrls: ["./support-activity.component.scss"]
})
export class SupportActivityComponent implements OnInit, OnDestroy {
  constructor(
    private router: ActivatedRoute,
    private store: Store<fromReducers.State>,
    private themeService : ThemeService,
    private exportService : ExportToCsvService,
    private notificationService : NotificationService
  ) {}

  temp;
  rows;
  showFilters = false;
  attachmentsSubscription;
  exportTitle = "SP Activity Attachments";
  exportData = [];

  showEmail = false;

  caseId: string;
  private activityId: string;
  private routerSubscription: Subscription;

  isLoadingAttachments$ = this.store.pipe(
    select(fromReducers.getSupportActivitiesIsLoadingAttachments)
  );
  attachments$ = this.store.pipe(
    select(fromReducers.getSupportActivitiesAttachments)
  );

  isUploadingAttachment$ = this.store.pipe(
    select(fromReducers.getSupportActivitiesIsUploadingAttachment)
  );

  activityTypes$ = this.store.pipe(select(fromReducers.getActivityTypesAll));
  activity$ = combineLatest(
    this.store.pipe(select(fromReducers.getSupportActivities)),
    this.activityTypes$
  ).pipe(
    map(([activities, types]) => {
      if (activities == null || types == null) {
        return;
      }

      const activity = activities.find(
        x => x.caseId === this.caseId && x.id === this.activityId
      );

      if (activity == null) {
        return;
      }

      return {
        ...activity,
        activityType: types.find(x => x.value === activity.activityTypeCode)
      };
    })
  );

  stateCodes$ = this.store.pipe(select(fromReducers.getStateCodesAll));
  case$ = combineLatest(
    this.store.pipe(select(fromReducers.getCasesSelectedCase)),
    this.stateCodes$
  ).pipe(
    map(([currentCase, states]) => {
      if (
        currentCase == null ||
        this.isNullOrEmpty(states)
      ) {
        return;
      }

      return {
        ...currentCase,
        state: states.find(x => x.value === currentCase.stateCode)
      };
    })
  );

  isNullOrEmpty(x: any[]) {
    return x == null || x.length == 0 || x == undefined;
  }

  ngOnInit() {
    this.routerSubscription = this.router.paramMap.subscribe(params => {
      this.caseId = params.get("caseId");
      this.activityId = params.get("activityId");
      this.store.dispatch(new CaseActions.SelectCase({ caseId: this.caseId }));
      this.store.dispatch(
        new SupportActivityActions.GetSupportActivitesForCase({
          caseCrmId: this.caseId
        })
      );
      this.store.dispatch(
        new SupportActivityActions.GetSupportActivityAttachments({
          caseCrmId: this.caseId,
          activityCrmId: this.activityId
        })
      );
    });
    this.attachmentsSubscription = this.attachments$.subscribe(attachments=>{
      if(attachments!=null){
        this.rows = attachments as Attachment[];
        this.temp = attachments as Attachment[];
        // this.exportData= attachments.map(attachment=>this.convertAttachmentToExportAttachment(attachment));
      }      
    });
  }

  ngOnDestroy() {
    if (this.routerSubscription != null) {
      this.routerSubscription.unsubscribe();
    }
  }

  openUploadFileDialog() {
    this.store.dispatch(
      new CaseActions.OpenUploadFileDialog({
        caseCrmId: this.caseId,
        activityCrmId: this.activityId
      })
    );
  }

  downloadAttachment(attachmentId: string) {
    this.store.dispatch(
      new SupportActivityActions.DownloadSupportActivityAttachment({
        caseCrmId: this.caseId,
        activityCrmId: this.activityId,
        attachmentCrmId: attachmentId
      })
    );
  }

  back(){
    window.history.back();
  }

  get otherTheme(): boolean {
    return this.themeService.otherTheme;
  }

  changeTheme(){
    this.themeService.changeTheme();
  }

  toggleFilterBox(){
    this.showFilters = !this.showFilters;
    // reset filters
    this.rows = this.temp;
  }

  updateFilter(event) {
    const val =  event.target.value.toLowerCase();


    // filter our data
    const temp1 = this.temp.filter(function (d: Attachment) {
      if(d != undefined && d.subject != null){
        return d.subject.toLowerCase().indexOf(val) !== -1 || !val;
      }
      else if (val == ""){
        return true;
      };
    });

    // update the rows
    this.rows = temp1;
    // Whenever the filter changes, always go back to the first page
    // this.table.offset = 0;
  }

  export(title) {
    this.exportData= this.rows.map(attachment=>this.convertAttachmentToExportAttachment(attachment));
    if (this.exportData.length>0){
      this.exportService.exportAsCSV(this.exportData,title);
    } else{
      this.notificationService.showError("Sorry there has been a problem exporting data: No Data", "Close");
    };
  }

  convertAttachmentToExportAttachment(attachment: Attachment){
    return {
      subject: attachment.subject,
      description: attachment.description,
      createdOn: attachment.createdOn,
      fileName: attachment.fileName

    } as ExportAttachment;
  }

  refreshFiles(){
    this.store.dispatch(
      new SupportActivityActions.GetSupportActivityAttachments({
        caseCrmId: this.caseId,
        activityCrmId: this.activityId
      })
    );
  }
}
