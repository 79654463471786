<div style="overflow-x: auto;">
<ngx-datatable
  class="material"
  [rows]="activities"
  [columnMode]="'flex'"
  [headerHeight]="50"
  [footerHeight]="50"
  [rowHeight]="50"
  [limit]="10"
  style="min-width: 850px;"
>
  <ngx-datatable-column name="Subject" [flexGrow]="2">
    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
      <div  matTooltip="{{row.subject}}">
        <a [routerLink]="['/cases', row.caseId, 'activities', row.id]">{{
          row.subject
        }}</a>
      </div>
      
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Description" [flexGrow]="2">
    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
      <div *ngIf="row.activityTypeCode != 'email'"  matTooltip="{{value}}">{{ value }}</div>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Activity Type" [flexGrow]="1" prop="activityTypeCode">
    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
      <div matTooltip="{{ row.activityTypeCode | titlecase }}">
        {{ row.activityTypeCode | titlecase }}
      </div>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Activity Date" [flexGrow]="1"  prop="createdOn">
    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
      <div matTooltip="{{ row.createdOn | date: 'dd/MM/yyyy HH:mm' }}">
        {{ row.createdOn | date: "dd/MM/yyyy HH:mm" }}
      </div>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Owner" [flexGrow]="1"  prop="owner.lastName">
    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
      <div *ngIf="row.activityTypeCode == 'Support Activity'">
        {{ row.owner?.firstName }} {{ row.owner?.lastName }}
      </div>
      <div *ngIf="row.activityTypeCode != 'Support Activity'">System</div>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="" [flexGrow]="1"  prop="hasAttachment">
    <ng-template ngx-datatable-header-template>
      <div style="align-content: center;">
      <button
        *ngIf="caseClosed"
        class="btn btn-info btn-datatable"
        type="button"
        title="Add a new activity to the case"
        (click)="openDialog()"
        style="margin-left: -20px;"
      >
        Update Case
      </button>
      <button
        *ngIf="!caseClosed"
        class="btn btn-info btn-datatable"
        type="button"
        title="Case is closed"
        (click)="notify('Sorry, this case is closed')"
        style="margin-left: -20px;"
      >
        Update Case
      </button>
    </div>
    </ng-template>
    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template >
      <div
        matTooltip="Checking for attachments"
        *ngIf="isLoadingAttachments$ | async"
      >
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      </div>
      <div 
        matTooltip="This activity contains an attachment" 
        *ngIf="value"
        style="text-align: center;"
      >
        <a [routerLink]="['/cases', row.caseId, 'activities', row.id]">
          <mat-icon> attach_file</mat-icon>
        </a>
      </div>
    </ng-template>
  </ngx-datatable-column>
</ngx-datatable>
</div>