import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { EntitlementService } from "src/app/services/entitlement.service";
import { Observable, of } from "rxjs";
import { EntitlementActionTypes } from "../actions/entitlement.actions";
import { mergeMap, map, catchError } from "rxjs/operators";

import * as EntitlementActions from "../actions/entitlement.actions";
import { ErrorService } from 'src/app/services/error.service';

@Injectable()
export class EntitlementEffects {
  constructor(
    private actions$: Actions,
    private EntitlementService: EntitlementService,
    private errorService: ErrorService
  ) {}

  
  getEntitlements$: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType<EntitlementActions.GetEntitlements>(EntitlementActionTypes.GetEntitlements),
    mergeMap(action => {
      return this.EntitlementService.getEntitlements(action.payload.customerId).pipe(
        map(entitlements => {
          return new EntitlementActions.GetEntitlementsSuccess({ entitlements });
        }),
        catchError(error => {
        this.errorService.handleError(
            error,
            "Sorry, there's been a problem getting the entitlement for your account"
            );
          return of(new EntitlementActions.GetEntitlementsFailure());
        })
      );
    })
  ));

  
  getTokenEvents$: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType<EntitlementActions.GetTokenEvents>(EntitlementActionTypes.GetTokenEvents),
    mergeMap(action => {
      return this.EntitlementService.getTokenEvents(action.payload.customerId).pipe(
        map(tokenEvents => {
          return new EntitlementActions.GetTokenEventsSuccess({ tokenEvents });
        }),
        catchError(error => {
        this.errorService.handleError(
            error,
            "Sorry, there's been a problem getting the entitlement for your account"
            );
          return of(new EntitlementActions.GetTokenEventsFailure());
        })
      );
    })
  ));

}
