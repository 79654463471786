import { SupportActivity } from '../support-activity.model';

// TODO: Store event types
// eventType: 1= case, 2 = activity, 3 = adjustment, 4 = purchase

export interface TokenEvent {
    id: string;
    event: string;
    title: string;
    caseNumber: string;
    createdOn: Date;
    tokensAdded: number;
    tokensDebited: number;
    value: number;
    eventType: number;
    activities?: SupportActivity[];
 }


 export enum TokenEventTypes{
     Case = 1,
     Activity = 2,
     Adjustment = 3,
     Purchase = 4
 }
 