import { Component, OnInit } from '@angular/core';
import { ThemeService } from 'src/app/services/theme.service';
import { Store, select } from '@ngrx/store';
import * as fromReducers from "../../store/reducers";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor(
    private store: Store<fromReducers.State>,
    private themeService : ThemeService
    ){
  }
  
  loggedInUser$ = this.store.pipe(select(fromReducers.getLoggedInUser));
  
  ngOnInit() {
  }
  get otherTheme(): boolean {
    return this.themeService.otherTheme;
  }
  changeTheme(){
    this.themeService.changeTheme();
  }

}
