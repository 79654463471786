import { Injectable } from "@angular/core";
import { BaseService } from "./base.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Customer } from "../models/customer.model";
import { Entitlement } from '../models/tokens/entitlement.model';
import { TokenEvent } from '../models/tokens/token-event.model';

const API_URL = `${environment.apiUrl}` + "/entitlements/";


@Injectable({
  providedIn: 'root'
})
export class EntitlementService extends BaseService {

  constructor(private http: HttpClient) {
    super();
  }

  private options = {
    headers: new HttpHeaders().set("Content-Type", "application/json")
  };

  getEntitlements(customerId) {
    return this.http.get<Entitlement[]>(API_URL+customerId, this.options);
  }

  getTokenEvents(customerId) {
    return this.http.get<TokenEvent[]>(API_URL+"token-events/"+customerId, this.options);
  }
}



