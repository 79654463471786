import { Action } from "@ngrx/store";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { User } from "src/app/models/user.model";

import { UserActionTypes, UserActions } from "../actions/user.actions";

export const userFeatureKey = "user";

export interface State extends EntityState<User> {
  isLoading: boolean;
  isAddingNewPortalUser: boolean;
  isTogglingUserIsActive: boolean;
  isChangingUserType: boolean;
}
// TODO: change all these to just isLoading

export const adapter: EntityAdapter<User> = createEntityAdapter<User>();

export const initialState: State = adapter.getInitialState({
  isLoading: false,
  isAddingNewPortalUser: false,
  isTogglingUserIsActive: false,
  isChangingUserType: false

});

export function reducer(state = initialState, action: UserActions): State {
  switch (action.type) {
    case UserActionTypes.GetUsers: {
      return {
        ...state,
        isLoading: true
      };
    }
    case UserActionTypes.GetUsersSuccess: {
      const newState = adapter.upsertMany(action.payload.users, state);
      return {
        ...newState,
        isLoading: false
      };
    }
    case UserActionTypes.GetUsersFailure: {
      return {
        ...state,
        isLoading: false
      };
    }

    case UserActionTypes.AddNewPortalUser: {
      return {
        ...state,
        isAddingNewPortalUser: true
      };
    }
    case UserActionTypes.AddNewPortalUserSuccess: {
      const newState = adapter.upsertOne(action.payload.user, state);
      return {
        ...newState,
        isAddingNewPortalUser: false
      };
    }
    case UserActionTypes.AddNewPortalUserFailure: {
      return {
        ...state,
        isAddingNewPortalUser: false
      };
    }

    case UserActionTypes.ToggleUserIsActivate: {
      return {
        ...state,
        isTogglingUserIsActive: true
      };
    }
    case UserActionTypes.ToggleUserIsActivateSuccess: {
      const newState = adapter.upsertOne(action.payload.user, state);
      return {
        ...newState,
        isTogglingUserIsActive: false
      };
    }
    case UserActionTypes.ToggleUserIsActivateFailure: {
      return {
        ...state,
        isTogglingUserIsActive: false
      };
    }

    case UserActionTypes.ChangeCustomerUserType: {
      return {
        ...state,
        isChangingUserType: true
      };
    }
    case UserActionTypes.ChangeCustomerUserTypeSuccess: {
      const newState = adapter.upsertOne(action.payload.user, state);
      return {
        ...newState,
        isChangingUserType: false
      };
    }
    case UserActionTypes.ChangeCustomerUserTypeFailure: {
      return {
        ...state,
        isChangingUserType: false
      };
    }

    case UserActionTypes.DeleteUser: {
      return {
        ...state,
        isLoading: true
      };
    }
    case UserActionTypes.DeleteUserSuccess: {
      const newState = adapter.removeOne(action.payload.user.id, state);
      return {
        ...newState,
        isLoading: false
      };
    }
    case UserActionTypes.DeleteUserFailure: {
      return {
        ...state,
        isLoading: false
      };
    }

    case UserActionTypes.SendWelcomeEmail: {
      return {
        ...state,
        isLoading: true
      };
    }
    case UserActionTypes.SendWelcomeEmailSuccess: {
      const newState = adapter.upsertOne(action.payload.user, state);
      return {
        ...newState,
        isLoading: false
      };
    }
    case UserActionTypes.SendWelcomeEmailFailure: {
      return {
        ...state,
        isLoading: false
      };
    }

    default:
      return state;
  }
}

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal
} = adapter.getSelectors();

export const getIsLoading = (state: State) => state.isLoading;
export const getIsAddingNewPortalUser = (state: State) =>
  state.isAddingNewPortalUser;
export const getIsTogglingUserIsActive = (state: State) => 
  state.isTogglingUserIsActive;
export const getIsChangingUserType = (state: State) => 
  state.isChangingUserType;
