<div [class.alternative]="otherTheme">
    <div class="page-container">
        <h1 style="text-align: center;">Need Help?</h1>
        <hr />
        <p style="text-align: center;">Our contact information is available at the bottom of the page.
            <button (click)="scrollDown()" mat-icon-button style="outline: none;"><mat-icon  >arrow_downward</mat-icon></button>
        </p>
        <div class="example-action-buttons">
            <button mat-button (click)="accordion.openAll()">Expand All</button>
            <button mat-button (click)="accordion.closeAll()">Collapse All</button>
        </div>
<!-- YOUR ACCOUNT -->
        <mat-accordion class="example-headers-align" multi>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Your Account
                    </mat-panel-title>
                    <mat-panel-description>
                        How to manage your support portal account
                        <mat-icon>account_circle</mat-icon>
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <div class="row">
                    <div class="col-lg-12">
                        <h5>View Profile </h5>
                        <p>You can access your profile by clicking the drop-down arrow at the top right corner of the portal screen, just to the right of your email address, marked 1 in the image below. This will show you the Account menu, from which you can change the theme, access your profile (marked 2 in the image below) or log out and end your session. </p>
                        <div style="overflow-x: auto;">
                            <img src="../../../assets/images/help/help1.png" width="850px" style="margin: auto;display: block;">
                        </div>
                        <br>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <p>
                            Once you access your profile, you can see the email address you use to log in to the portal, your name as it is recorded in the helpdesk system, and the company your account is registered with. If any of these details are incorrect, please log a request with the helpdesk to have them updated. 
                        </p>
                        <p>
                            You can also change your password from this screen by clicking the Change Password button, marked 3 in the diagram below, or update your cookie preferences using the switch marked 4. 
                        </p>
                        <div style="overflow-x: auto;">
                            <img src="../../../assets/images/help/help2.png" height="450px" style="margin: auto;display: block;">
                        </div>
                        <br>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <h5>Change Password</h5>
                        <p>To change your password, first access your profile details using the instructions above. From there, click the Change Password button, which will bring up the Change Password dialog, as per the picture below.</p>
                        <p>To change your password:</p>
                        <ul>
                            <li>Enter your current password (1)</li>
                            <li>Type in your new password (2) noting the password requirements outlined in red in the image below</li>
                            <li>Confirm your new password (3) ensuring this matches the password you typed at 2</li>
                            <li>Click the Change Password button.</li>
                        </ul>
                        <p>Your password will be updated, and you will need to use the new password the next time you log in.</p>
                        <div style="overflow-x: auto;">
                            <img src="../../../assets/images/help/help3.png" height="450px" style="margin: auto;display: block;">
                        </div>
                        <br>
                        <p>If you have forgotten your password, you will need to reset it using the Forgotten Password link at the login page.</p>
                    </div>
                </div>
            </mat-expansion-panel>
<!-- CASES -->
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Cases
                    </mat-panel-title>
                    <mat-panel-description>
                        How to view and manage your cases
                        <mat-icon>assignment</mat-icon>
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <div class="row">
                    <div class="col-lg-12">
                        <h5>Create a case</h5>
                        <p>You can create a new case from anywhere in the support portal by accessing the <strong>Cases</strong> menu at the top left of the screen (marked 1 in the image below) and selecting the <strong>New Case</strong> option (2). Alternatively, from the Home Screen click on the <strong>Create New Case</strong> button (3).</p>
                        <div style="overflow-x: auto;">
                            <img src="../../../assets/images/help/help4.png" width="850px" style="margin: auto;display: block;">
                        </div>
                        <br>
                        <p>This will show the new Case dialog, pictured below, where you can:</p>
                        <ul>
                            <li>
                                <div class="row">
                                    <div class="col-lg-12">
                                        <p>
                                            Let us know the type of case you are raising:
                                        </p>
                                        <div style="overflow-x: auto;">
                                            <img src="../../../assets/images/help/help5.png" height="450px" style="display: block;">
                                        </div>
                                        <br>
                                        <p>
                                            The portal uses natural language to describe the kind of case. For those more familiar with ITIL case types, the table below explains how we allocate these descriptions. 
                                        </p>
                                        <table class="table" style="max-width: 1000px;">
                                            <thead class="thead-light">
                                                <tr>
                                                    <th scope="col">Description</th>
                                                    <th scope="col">ITIL case type </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>I have a fault (something is not working) </td>
                                                    <td>Incident </td>
                                                </tr>
                                                <tr>
                                                    <td>Something isn’t behaving quite right </td>
                                                    <td>Problem </td>
                                                </tr>
                                                <tr>
                                                    <td>I’d like you to do or change something </td>
                                                    <td>Request</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>  
                            </li>
                            <li>
                                <div class="row">
                                    <div class="col-lg-12">
                                        <p>
                                            Help us to prioritise your case. 
                                        </p>
                                        <p>
                                            We use natural language to describe the impact of your case in general terms, which allows us to make sure that cases are given an appropriate priority. 
                                        </p>
                                        <div style="overflow-x: auto;">
                                            <img src="../../../assets/images/help/help6.png" height="450px" style="display: block;">
                                        </div>
                                        <br>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-12">
                                        <p>
                                            Please note that if your application is completely unavailable, or your issue is having a major business impact, we recommend you call the Helpdesk on 01904 234539 as that will get the fastest response. You can, of course, log the case using the portal first and then follow up with a phone call if you would prefer to. 
                                        </p>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="row">
                                    <div class="col-lg-5">
                                        <p>
                                            Provide us with a Title and Description of your case. This will allow us to triage the case and start investigating it, or get back to you to request more information. The more information you can provide, the more quickly we can begin troubleshooting for you.
                                        </p>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="row">
                                    <div class="col-lg-5">
                                        <p>
                                            Once you’ve entered this information, click the Create Case button to register your case in the Helpdesk system. This will then be passed across to the support team who will triage the case and allocate it to one of the team for investigation. 
                                        </p>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <p>You will receive an email confirmation that your case has been logged.</p>
                        <p>You will also be taken straight to the Case Details page, from where you can add further information or upload one or more attachments to help us with troubleshooting.</p>
                    </div>
                </div>
                <hr>
                <div class="row">
                    <div class="col-lg-12">
                        <h5>View Cases</h5>
                        <p>You can view all of the cases logged by your company, or all of the cases logged by you, depending on what permissions you have on the portal.</p>
                        <p>You can do this from anywhere in the portal by accessing the <strong>Cases</strong> menu at the top left of the screen (marked 1 in the image below) and selecting the <strong>My Cases</strong> option (2) or the <strong>Company Cases</strong> option (3). Alternatively, from the Home Screen click on either the <strong>My Cases</strong> or the <strong>Company Cases</strong> button.</p>
                        <div style="overflow-x: auto;">
                            <img src="../../../assets/images/help/help7.png" width="850px" style="margin: auto;display: block;">
                        </div>
                        <br>
                        <p>From the <strong>My Cases</strong> or <strong>Company Cases</strong> screens, you can do the following:</p>
                        <div style="overflow-x: auto;">
                            <img src="../../../assets/images/help/help8.png" width="850px" style="margin: auto;display: block;">
                        </div>
                        <ol>
                            <li style="border-top: #ded9d9 1px solid;">
                                <div class="row">
                                    <div class="col-lg-5">
                                        <p>
                                            You can limit your view to show only <strong>Open Cases</strong>, or expand it to show all cases, including those that are resolved. By default the view only shows open cases, but unchecking the <strong>Open Cases</strong> box will show you resolved cases as well. If there is more than 1 page of cases, you will see pagination controls at the bottom right of the list.
                                        </p>
                                    </div>
                                    <div class="col-lg-7">
                                        <div style="overflow-x: auto;">
                                            <img src="../../../assets/images/help/help9.png" width="250px" style="margin: auto;display: block;">
                                        </div>
                                        <br>
                                    </div>
                                </div>
                            </li>
                            <li style="border-top: #ded9d9 1px solid;">
                                <div class="row">
                                    <div class="col-lg-5">
                                        <p>
                                            You can <strong>Filter</strong> cases based on the <strong>Case Title</strong>. Click the <strong>Filter</strong> button at the top left of the Cases screen and the Filter Cases dialogue will appear. Just start typing in this dialog to only show cases whose titles contain the characters you type. This will filter the list in realtime, so allows you to very quickly find a case.
                                        </p>
                                    </div>
                                    <div class="col-lg-7">
                                        <div style="overflow-x: auto;">
                                            <img src="../../../assets/images/help/help10.png" width="450px" style="margin: auto;display: block;">
                                        </div>
                                        <br>
                                    </div>
                                </div>
                            </li>
                            <li style="border-top: #ded9d9 1px solid;">
                                <div class="row">
                                    <div class="col-lg-5">
                                        <p>
                                            You can <strong>Export</strong> the case list to a CSV file. Just press the <strong>Export</strong> button and the full list of cases will be available in CSV format for you to save to your local device.
                                        </p>
                                    </div>
                                    <div class="col-lg-7">
                                        <div style="overflow-x: auto;">
                                            <img src="../../../assets/images/help/help11.png" width="250px" style="margin: auto;display: block;">
                                        </div>
                                        <br>
                                    </div>
                                </div>
                            </li>
                            <li style="border-top: #ded9d9 1px solid;">
                                <div class="row">
                                    <div class="col-lg-5">
                                        <p>
                                            You can <strong>View The Details</strong> for a particular case, by clicking on the blue case number in the first column. Clicking on this will take you to the Case Details screen for that case, from where you can <strong>Update the Case</strong>, <strong>Upload a File</strong>, or <strong>Close The Case</strong>.
                                        </p>
                                    </div>
                                    <div class="col-lg-7">
                                        <div style="overflow-x: auto;">
                                            <img src="../../../assets/images/help/help12.png" width="350px" style="margin: auto;display: block;">
                                        </div>
                                        <br>
                                    </div>
                                </div>
                            </li>
                            <li style="border-top: #ded9d9 1px solid;">
                                <div class="row">
                                    <div class="col-lg-5">
                                        <p>
                                            You can <strong>Sort</strong> the case list by clicking on any of the Column headers. Clicking the appropriate column header will sort the list of cases by that value, and clicking it again will reverse the sort order. A little arrow will appear next to the column header to show which column is currently being sorted by, and which direction the sort is applied. The default sort id by Case Number, ascending.
                                        </p>
                                    </div>
                                    <div class="col-lg-7">
                                        <div style="overflow-x: auto;">
                                            <img src="../../../assets/images/help/help13.png" width="250px" style="margin: auto;display: block;">
                                        </div>
                                        <br>
                                    </div>
                                </div>
                            </li>
                        </ol>
                    </div>
                </div>
                <hr>
                <div class="row">
                    <div class="col-lg-12">
                        <h5>Review or Update a case</h5>
                        <p>Clicking on a <strong>Case Number</strong> in the <strong>Cases</strong> screen (My Cases or Company Cases) will take you to the <strong>Case Details</strong> screen, as pictured below. From here you can see what’s been happening with your case, as well as <strong>Update a Case</strong>, <strong>Attach a File</strong>, or even <strong>Close a Case</strong>.</p>
                        <div style="overflow-x: auto;">
                            <img src="../../../assets/images/help/help14.png" width="850px" style="margin: auto;display: block;">
                        </div>
                        <br>
                        <p>The following actions can be performed from the Case Details screen:</p>
                        <ol>
                            <li><strong>Review Case Information</strong>. This panel contains all of the basic information about the case, including who logged it, the description, priority, status, and who is currently owning the case at Simpson Associates.</li>
                            <li><strong>View Case Timeline and Activities</strong>. This section shows all of the activities that have been recorded against the case. You can click on a specific activity to see more details. You can also</li>
                            <li><strong>Filter, Export, or Sort Activities</strong>. This uses the same mechanisms as filtering, exporting or Sorting cases.</li>
                            <li>
                                <p>You can provide an <strong>Update for a Case</strong> by clicking the <strong>Update Case</strong> button. This will show the Update Case dialogue where you can type a Subject and a Description for your update. Your update will be added to the case activities, and the case owner notified that you have provided further information.</p>
                                <div style="overflow-x: auto;">
                                    <img src="../../../assets/images/help/help15.png" width="450px" style="margin: auto;display: block;">
                                </div>
                                <br>
                            </li>
                            <li>
                                <p>You can <strong>Attach a File</strong> to a case if you are able to provide additional information such as a screen shot or data file that will help us to troubleshoot or resolve your case.</p>
                                <ol>
                                    <li>Click the <strong>Attach File</strong> button to bring up the <strong>Upload Attachment</strong> dialog.</li>
                                    <li>You can then either select a file using a browser, or drag and drop your file into the window.</li>
                                    <li>
                                        <p>Once you’ve selected a file the filename will appear at the bottom left of the window, and clicking UPLOAD will add the file to the case.</p>
                                        <div style="overflow-x: auto;">
                                            <img src="../../../assets/images/help/help16.png" width="350px" style="margin: auto;display: block;">
                                        </div>
                                        <br>
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <p>You can <strong>Close a Case</strong> by clicking the <strong>Close Case</strong> button. This will present you with a prompt asking you to confirm that you wish to close the case. The case will be marked as Closed by Customer, and the Simpson Associates Support Consultant who owns the case will be notified to resolve the case in the Helpdesk system.</p>
                                <div style="overflow-x: auto;">
                                    <img src="../../../assets/images/help/help17.png" width="350px" style="margin: auto;display: block;">
                                </div>
                                <br>
                            </li>
                        </ol>
                    </div>
                </div>
            </mat-expansion-panel>
<!-- TOKEN MANAGEMENT -->
            <mat-expansion-panel *ngIf="(loggedInUser$ | async)?.isSimpsonsAdmin || (loggedInUser$ | async)?.role?.name == 'Customer Superuser'">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                    Token Management
                    </mat-panel-title>
                    <mat-panel-description>
                    How to manage your support tokens
                    <mat-icon>assessment</mat-icon>
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <div class="row">
                    <div class="col-lg-12">
                        <h5>Review your Token Balance</h5>
                        <p>
                            If you have been allocated Super user permissions, and if your support agreement uses hourly support tokens, then when you log in to the portal you will be able to see your current support token balance on the Dashboard. 
                        </p>
                        <div style="overflow-x: auto;">
                            <img src="../../../assets/images/help/help18.png" width="850px" style="margin: auto;display: block;">
                        </div>
                        <br>
                        <p>
                            You can also review detailed information on Token utilisation by clicking on the Token Details button on the Dashboard, or on the Tokens link in the menu bar.
                        </p>

                        <h5>Manage Tokens</h5>
                        <p>
                            Clicking the <strong>Token Details</strong> button on the <strong>Dashboard</strong>, or the <strong>Tokens</strong> link in the menu bar, will take you to the <strong>Manage Tokens</strong> screen, where you can see token purchase and utilisation in graphical and tabular formats, as well as accessing detailed information about Token spend on support cases.
                        </p>
                        <div style="overflow-x: auto;">
                            <img src="../../../assets/images/help/help19.png" width="850px" style="margin: auto;display: block;">
                        </div>
                        <br>
                        <p>The following actions can be performed from the Manage Tokens screen:</p>
                        <ol>
                            <li>
                                <strong>Review Token Balance</strong>. The Support Token Balance is shown at the top of the Manage Tokens screen.
                            </li>
                            <li>
                                <strong>Review Token Purchases and Utilisation</strong>. The chart at the top of the Manage Token screen shows Tokens Purchased and Used over the last 12 months. Hovering over a bar will show you the exact number of tokens purchased or use in that month.  The table below show a more detailed breakdown of each Token Purchase, Token Adjustment, or Token Spend related to a case.
                            </li>
                            <li>
                                <strong>Export a detailed Token Report</strong>. Clicking the <strong>Export</strong> button will generate a CSV file of each case, adjustment and purchase that can be downloaded and manipulated offline as required. Clicking <strong>Export All</strong> will generate a more detailed report that shows each activity linked to the support cases where Tokens have been spent.
                            </li>
                            <li>
                                You can <strong>sort the table</strong> by clicking a column heading. A small arrow will show next to the current sort field, indicating the direction of sorting. Clicking a field again reverses the sort direction.
                            </li>
                            <li>
                                <strong>View detailed Case Activities</strong> by clicking a case description. This will allow you to see how Tokens have been spent on a case.
                            </li>
                            <li>
                                <strong>Navigate the token events table</strong> using the navigation controls at the bottom right. By default the table will show 10 events, which can be sorted using the header row. You can navigate to the first, previous, next, or last page, or to a specific page, using these controls. If you need to view more events, you can <strong>export</strong> the full table to a CSV file.
                            </li>
                        </ol>
                    
                    </div>
                </div>
            </mat-expansion-panel>
<!-- USER MANAGEMENT -->
            <mat-expansion-panel *ngIf="(loggedInUser$ | async)?.isSimpsonsAdmin || (loggedInUser$ | async)?.role?.name == 'Customer Superuser'">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                    User Management
                    </mat-panel-title>
                    <mat-panel-description>
                    How to manage your company's users
                    <mat-icon>people</mat-icon>
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <div class="row">
                    <div class="col-lg-12">
                        <h5>Manage Users</h5>
                        <p>
                            If you are a SuperUser, you can access the <strong>Manage Users</strong> screen from anywhere in the portal by clicking the Users menu item in the menu bar:
                        </p>
                        <div style="overflow-x: auto;">
                            <img src="../../../assets/images/help/help20.png" width="850px" style="margin: auto;display: block;">
                        </div>
                        <br>
                        <p>
                            From the <strong>Manage Users</strong> screen, you can view existing portal users and their roles (User or SuperUser), re-send a welcome email to a user, deniable a user, change a user’s role, or request a new portal user.
                        </p>
                        <mat-card class="mat-elevation-z8 mb-2" style="max-width:850px;margin: auto;border: #2196f3 3px solid;">
                            <h5 style="text-align: center;">A note on User Roles</h5>
                            <p>
                                All portal users can create tickets, update and close tickets, and view their own tickets, as well as those logged by others within your organisation.
                            </p>
                            <p>
                                A portal SuperUser can also view token balance and utilisation information, as well as managing your organisations’ users within the portal.
                            </p>
                        </mat-card>
                        <div style="overflow-x: auto;">
                            <img src="../../../assets/images/help/help21.png" width="850px" style="margin: auto;display: block;">
                        </div>
                        <br>
                        <p>The following actions can be performed from the Manage Users screen:</p>
                        <ol>
                            <li>
                                <strong>Review existing Users</strong>. The default table shows existing portal users, and also shows if the User is Active. Active Users can be disabled (and inactive Users enabled) from the vertical ellipsis at the far right of the table.
                            </li>
                            <li>
                                <strong>View Users’ Roles</strong>. Users can be standard portal <strong>Users</strong> or <strong>SuperUsers</strong> (see the boxed text above). A User’s role can be changed from the vertical ellipsis at the far right of the table.
                            </li>
                            <li>
                                <strong>View Users’ Password Status</strong>.  When a User is invited to the portal, they are sent a welcome email with a link to set a password. Password Status is either <mat-icon>email</mat-icon>, which means a welcome email has been sent but the User has not yet set a password, or <mat-icon>lock</mat-icon> ,which means the User has successfully set a password. The Password link expires after 24 hours, and a new welcome email with an updated link can be sent from the ellipsis at the far right of the table.
                            </li>
                            <li>
                                <strong>Request a new Portal User</strong>. If a new user is required, clicking the Request new Portal User button will present a simple form where you can provide contact details for the new User. This will be submitted to the Service Desk who will generate the User. You may receive a follow-up contact from the Service Desk to validate your request.
                            </li>
                            <li>
                                You can <strong>send a new welcome email</strong> to an existing user by clicking the ellipsis <mat-icon>more_vert</mat-icon> at the far right of the table, and selecting <strong>Send Welcome Email</strong> from the drop down menu. This option is only available for Users who have not yet set a password.
                            </li>
                            <li>
                                You can <strong>disable an active user</strong>, or <strong>enable an inactive user</strong>, by clicking the ellipsis <mat-icon>more_vert</mat-icon> at the far right of the table, and selecting the appropriate option. 
                            </li>
                            <li>
                                <strong>Changing user roles</strong> is achieved by clicking the ellipsis at the far right of the table, and selecting <strong>Make SuperUser</strong> or <strong>Remove SuperUser</strong> as required. See the boxed test above for more details of User roles.
                            </li>
                        </ol>
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div>