import { Action } from "@ngrx/store";
import { Contact } from "src/app/models/contact.model";

export enum ContactActionTypes {
  GetContacts = "[Contact] Get Contacts",
  GetContactsSuccess = "[Contact] Get Contacts Success",
  GetContactsFailure = "[Contact] Get Contacts Failure"
}

export class GetContacts implements Action {
  readonly type = ContactActionTypes.GetContacts;
}

export class GetContactsSuccess implements Action {
  readonly type = ContactActionTypes.GetContactsSuccess;
  constructor(public payload: { contacts: Contact[] }) {}
}

export class GetContactsFailure implements Action {
  readonly type = ContactActionTypes.GetContactsFailure;
}

export type ContactActions =
  | GetContacts
  | GetContactsSuccess
  | GetContactsFailure;
