<h2 matDialogTitle>Confirm Case Closure</h2>
<mat-dialog-content class="text-center dialog">
  <form [formGroup]="closeCaseForm">

    <!-- <mat-form-field class="full-width">
      <mat-label>Status Reason</mat-label>
      <mat-select formControlName="statusControl" required>
        <mat-option *ngFor="let reason of closureStatusCodes" [value]="reason">
          {{reason.label}}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="closeCaseForm.get('statusControl').invalid">Please update the status reason of the case</mat-error>
    </mat-form-field> -->
    
    <mat-form-field class="full-width">
      <mat-label>Closure Reason</mat-label>
      <textarea
        matInput
        type="text"
        formControlName="closureReasonControl"
        autocomplete="off"
        required
        rows="4"
      ></textarea>
      <mat-hint>Please provide any notes as to why you are closing the case</mat-hint>
      <mat-error *ngIf="closeCaseForm.get('closureReasonControl').invalid">Please provide any notes as to why you are closing the case</mat-error>
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="">CANCEL</button>
  <button mat-button (click)="closeCase()">CLOSE CASE</button>
</mat-dialog-actions>
