import { Component, OnInit, Input } from "@angular/core";
import { AuthService } from "src/app/services/auth.service";
import { UserManager, UserManagerSettings, User } from "oidc-client";
import { Store, select } from "@ngrx/store";
import * as fromReducers from "../../store/reducers";
import * as AuthActions from "../../store/actions/auth.actions";
import * as CaseActions from "../../store/actions/case.actions";
import * as UserActions from "../../store/actions/user.actions";

import { ThemeService } from "src/app/services/theme.service";
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"]
})
export class NavbarComponent implements OnInit {
  constructor(
    private store: Store<fromReducers.State>,
    private themeService : ThemeService,
    private notificationService: NotificationService
    ) {}

  // @Input() pageName: string;

  // otherTheme: boolean = this.themeService.otherTheme;

  loggedInUser$ = this.store.pipe(select(fromReducers.getLoggedInUser));
  isCreatingCase$ = this.store.pipe(select(fromReducers.getIsCreatingNewCase));
  isClosingCase$ = this.store.pipe(select(fromReducers.getIsClosingCase));
  isTogglingUserIsActive$ = this.store.pipe(select(fromReducers.getIsTogglingUserIsActive));
  isChangingCustomerUserType$ = this.store.pipe(select(fromReducers.getIsChangingUserType));
  isLoadingSupportActivities$ = this.store.pipe(select(fromReducers.getIsLoadingSupportActivities));
  isAddingNewUser$ = this.store.pipe(select(fromReducers.getIsAddingNewPortalUser));
  isLoadingContacts$ = this.store.pipe(select(fromReducers.getContactsIsLoading));
  isLoadingEntitlements$ = this.store.pipe(select(fromReducers.getEntitlementsIsLoading));


  impersonatingUser = false;
  impersonatingUserName = "";

  userSubscrip;

  cookiesAllowed;

  ngOnInit() {
    this.cookiesAllowed = localStorage.getItem("cookies-allowed") == "true";
    // if (this.pageName != null) this.highlightCurrentPageLink(this.pageName);
    this.userSubscrip = this.loggedInUser$.subscribe(user=>{
      if(user !=null){
        console.log(user);
      }
    });
  }

  updateCookies(){
    this.cookiesAllowed = localStorage.getItem("cookies-allowed") == "true";
  }

  logout() {
    this.store.dispatch(new AuthActions.LogOut());
  }

  get otherTheme(): boolean {
    return this.themeService.otherTheme;
  }

  changeTheme(){
    this.themeService.changeTheme();
  }

  // highlightCurrentPageLink(pageName: string) {
  //   switch (pageName) {
  //     case "homeLink":
  //       document.getElementById("homeLink").classList.add("active");
  //       break;
  //     case "casesLink":
  //       document.getElementById("casesLink").classList.add("active");
  //       break;
  //     case "manageLink":
  //       document.getElementById("manageLink").classList.add("active");
  //       break;
  //     default:
  //       break;
  //   }
  // }

  openNewCaseDialog() {
    this.store.dispatch(new CaseActions.OpenCreateCaseDialog());
  }
  openProfileDialog() {
    this.store.dispatch(new UserActions.OpenProfileDialog());
  }

  endImpersonation(){
    this.impersonatingUser = false;
    this.impersonatingUserName = "";
    this.notificationService.showSuccess("You are no longer in customer view.","close");
  }
}
