import { Component, OnInit, OnDestroy } from "@angular/core";

import { ActivatedRoute } from "@angular/router";
import { Subscription, combineLatest } from "rxjs";
import { Store, select } from "@ngrx/store";

import * as fromReducers from "../../../store/reducers";
import { map } from "rxjs/operators";
import * as CaseActions from "../../../store/actions/case.actions";
import * as SupportActivityActions from "../../../store/actions/support-activity.actions";
import * as MetadataActions from "../../../store/actions/metadata.actions";
import { Case } from "src/app/models/case.model";
import { ThemeService } from 'src/app/services/theme.service';
import { Title } from '@angular/platform-browser';
import { ExportToCsvService } from 'src/app/services/export-to-csv.service';
import { SupportActivity } from 'src/app/models/support-activity.model';
import { ExportActivity } from 'src/app/models/export-models/export-activity.model';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: "app-new-case",
  templateUrl: "./case.component.html",
  styleUrls: ["./case.component.scss"]
})
export class CaseComponent implements OnInit, OnDestroy {
  constructor(
    private router: ActivatedRoute,
    private store: Store<fromReducers.State>,
    private themeService : ThemeService,
    private titleService: Title,
    private exportService: ExportToCsvService,
    private notificationService: NotificationService
  ) {
    this.titleService.setTitle("View Case");
  }
  private caseId: string;
  private routerSubscription: Subscription;

  temp;
  rows;
  showFilters = false;
  activitiesSubscription;
  exportTitle = "SP Case Activities";
  exportData = [];
  statesList: any[];

  isClosingCase$ = this.store.pipe(select(fromReducers.getCasesIsClosingCase));
  isLoadingAttachments$ = this.store.pipe(select(fromReducers.getSupportActivitiesIsLoadingAttachments));
  caseSubscription;
  thisCase;

  currentCase: Case;
  statusReasons$ = this.store.pipe(
    select(fromReducers.getStatusReasonCodesAll)
  );
  stateCodes$ = this.store.pipe(select(fromReducers.getStateCodesAll));
  priorityCodes$ = this.store.pipe(select(fromReducers.getPriorityCodesAll));
  typeCodes$ = this.store.pipe(select(fromReducers.getTypeCodesAll));
  case$ = combineLatest(
    this.store.pipe(select(fromReducers.getCasesSelectedCase)),
    this.statusReasons$,
    this.stateCodes$,
    this.priorityCodes$,
    this.typeCodes$
  ).pipe(
    map(([currentCase, statusReasons, states, priorities, types]) => {
      if (
        currentCase == null ||
        this.isNullOrEmpty(statusReasons) ||
        this.isNullOrEmpty(states) ||
        this.isNullOrEmpty(priorities) ||
        this.isNullOrEmpty(types)
      ) {
        return;
      }

      return {
        ...currentCase,
        statusReason: statusReasons.find(
          x => x.value === currentCase.statusReasonCode
        ),
        state: states.find(x => x.value === currentCase.stateCode),
        priority: priorities.find(x => +x.value === currentCase.priorityCode),
        type: types.find(x => x.value === currentCase.typeCode)
      };
    })
  );

  activityTypes$ = this.store.pipe(select(fromReducers.getActivityTypesAll));
  caseAttachments$ = this.store.pipe(select(fromReducers.getAttachmentsForCase));
  supportActivities$ = combineLatest(
    this.store.pipe(select(fromReducers.getSupportActivities)),
    this.activityTypes$,
    this.caseAttachments$
  ).pipe(
    map(([activities, types, caseAttachments]) => {
      if (activities == null || types == null ) {
        return;
      }
      if(caseAttachments == null){
        return activities.map(a => {
          return {
            ...a,
            activityType: types.find(x => x.value === a.activityTypeCode)
          };
        });
      }
      return activities.map(a => {
        return {
          ...a,
          activityType: types.find(x => x.value === a.activityTypeCode),
          hasAttachment: (caseAttachments.find(x=>x.supportActivity.id == a.id) != null)
        };
      });
    })
  );
  isLoadingSupportActivities$ = this.store.pipe(
    select(fromReducers.getIsLoadingSupportActivities)
  );

  ngOnInit() {
    this.routerSubscription = this.router.paramMap.subscribe(params => {
      this.caseId = params.get("id");
      this.store.dispatch(new CaseActions.SelectCase({ caseId: this.caseId }));
      this.store.dispatch(
        new SupportActivityActions.GetSupportActivitesForCase({ caseCrmId: this.caseId })
      );
      this.store.dispatch(
        new SupportActivityActions.GetAttachmentsForCase({ caseCrmId: this.caseId })
      );
    });
    this.activitiesSubscription = this.supportActivities$.subscribe(activities=>{
      if(activities!=null){
        this.rows = activities as SupportActivity[];
        this.temp = activities as SupportActivity[];
      }      
    });
    this.caseSubscription = this.case$.subscribe(c=>{
      if(c!=null){
        this.thisCase = c;

      }
    });
  }

  isNullOrEmpty(x: any[]) {
    return x == null || x.length == 0 || x == undefined;
  }

  ngOnDestroy(): void {
    if (this.routerSubscription != null) {
      this.routerSubscription.unsubscribe();
    };
    if (this.activitiesSubscription != null) {
      this.activitiesSubscription.unsubscribe();
    }
    if (this.caseSubscription != null) {
      this.caseSubscription.unsubscribe();
    }
  }

  openCloseCaseDialog() {
    this.store.dispatch(
      new CaseActions.OpenCloseCaseDialog({ caseCrmId: this.caseId })
    );
  }

  back(){
    window.history.back();
  }

  get otherTheme(): boolean {
    return this.themeService.otherTheme;
  }

  changeTheme(){
    this.themeService.changeTheme();
  }

  toggleFilterBox(){
    this.showFilters = !this.showFilters;
    // reset filters

    this.rows = this.temp;
  }

  updateFilter(event) {
    const val =  event.target.value.toLowerCase();


    // filter our data
    const temp1 = this.temp.filter(function (d: SupportActivity) {
      if(d != undefined && d.subject != null){
        return d.subject.toLowerCase().indexOf(val) !== -1 || !val;
      }
      else if (val == ""){
        return true;
      };
    });

    // update the rows
    this.rows = temp1;
    // Whenever the filter changes, always go back to the first page
    // this.table.offset = 0;
  }

  export(title) {
    this.exportData= this.rows.map(activity=>this.convertActivityToExportActivity(activity));
    if (this.exportData.length>0){
      this.exportService.exportAsCSV(this.exportData,title);
    } else{
      this.notificationService.showError("Sorry there has been a problem exporting data: No Data", "Close");
    };
  }

  convertActivityToExportActivity(activity: SupportActivity){
    let activityLabel, ownerName, description = "";

    if(activity.activityTypeCode != undefined){
      activityLabel = activity.activityTypeCode;
      if(activity.activityTypeCode == "email"){
        description = null;
      }
      else{
        description = activity.description;
      };
    };
    if(activity.owner != undefined){
      if(activity.owner.firstName != undefined && activity.owner.lastName != undefined){
        ownerName = activity.owner.firstName+" "+activity.owner.lastName;
      }
      else if(activity.owner.firstName != undefined){
        ownerName = activity.owner.firstName;
      }
      else if(activity.owner.lastName != undefined){
        ownerName = activity.owner.lastName;
      }
    };
    return {
      subject: activity.subject,
      description: description,
      activityType: activityLabel,
      activityDate: activity.createdOn,
      owner: ownerName
    } as ExportActivity;
  }

  //quickly attach file to case
  attachFile(){
    // create activity in background and open file upload dialog
    // const newActivity = {
    //   subject: "Attached file to case",
    //   description: ""
    // } as SupportActivity;
    // this.store.dispatch(new SupportActivityActions.AddNewSupportActivity({ activity: newActivity, openFileUpload: true }));
    if(this.thisCase != null){
      this.store.dispatch(new CaseActions.OpenQuickUploadDialog({ case: this.thisCase }));

    }
  }

  downloadAttachment(attachmentId: string, activityId: string) {
    this.store.dispatch(
      new SupportActivityActions.DownloadSupportActivityAttachment({
        caseCrmId: this.caseId,
        activityCrmId: activityId,
        attachmentCrmId: attachmentId
      })
    );
  }

  refreshActivities(){
    this.store.dispatch(new SupportActivityActions.GetSupportActivitesForCase({caseCrmId: this.caseId}));
    this.store.dispatch(new SupportActivityActions.GetAttachmentsForCase({caseCrmId: this.caseId}));
  }

  refreshFiles(){
    this.store.dispatch(new SupportActivityActions.GetAttachmentsForCase({caseCrmId: this.caseId}));
  }

  openCustomerCloseDialog(){
    this.store.dispatch(new CaseActions.OpenConfirmCustomerCloseDialog({caseId: this.caseId}));
  }

}
