<div class="outer-div">
  <div id="loader">
    <app-loading ></app-loading>
  </div>
  <!-- <div class="wrapper fadeInDown">
    <div id="formContent">
      <div class="fadeIn first">
        <img
          src="../../assets/logos/logo_new.svg"
          id="icon"
          [routerLink]="['/']"
        />
        <br />
        <p>Support Portal</p>
      </div>
      
      <button
        (click)="login()"
        class="fadeIn fourth btn btn-primary"
      >
      Log in
    </button>
    
    </div>
  </div> -->
</div>
