import { Injectable } from "@angular/core";
import { BaseService } from "./base.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Case } from "../models/case.model";
import { environment } from "src/environments/environment";
import { Router } from "@angular/router";
import { tap, catchError } from "rxjs/operators";
import { SupportActivity } from "../models/support-activity.model";
import { Attachment } from "../models/attachment.model";
import { Subject } from 'rxjs';

const API_URL = `${environment.apiUrl}` + "/cases/";

@Injectable({
  providedIn: "root"
})
export class ThemeService {
  
    constructor()  {
        this.themeChange.subscribe((value) => {
            this.otherTheme = value
        });
    }
  
  otherTheme: boolean = localStorage.getItem('theme')=="true";

  themeChange: Subject<boolean> = new Subject<boolean>();

  changeTheme(){
    var themeString: string = String(!this.otherTheme)
    localStorage.setItem('theme', themeString);
    this.themeChange.next(!this.otherTheme);
  }
  
}
