import { Action } from "@ngrx/store";
import { Status } from "src/app/models/status.model";
import { Role } from "src/app/models/role.model";
import { Priority } from "src/app/models/priority.model";
import { Type } from "src/app/models/type.model";
import { ActivityType } from "src/app/models/activity-type.model";
import { PasswordStatus } from 'src/app/models/password-status.model';

export enum MetadataActionTypes {
  GetStatusReasonCodes = "[Metadata] Get Status Reason Codes",
  GetStatusReasonCodesSuccess = "[Metadata] Get Status Reason Codes Success",
  GetStatusReasonCodesFailure = "[Metadata] Get Status Reason Codes Failure",
  GetStateCodes = "[Metadata] Get State Codes",
  GetStateCodesSuccess = "[Metadata] Get State Codes Success",
  GetStateCodesFailure = "[Metadata] Get State Codes Failure",
  GetPriorityCodes = "[Metadata] Get Priority Codes",
  GetPriorityCodesSuccess = "[Metadata] Get Priority Codes Success",
  GetPriorityCodesFailure = "[Metadata] Get Priority Codes Failure",
  GetTypeCodes = "[Metadata] Get Type Codes",
  GetTypeCodesSuccess = "[Metadata] Get Type Codes Success",
  GetTypeCodesFailure = "[Metadata] Get Type Codes Failure",
  GetRoles = "[Metadata] Get Roles",
  GetRolesSuccess = "[Metadata] Get Roles Success",
  GetRolesFailure = "[Metadata] Get Roles Failure",
  GetActivityTypes = "[Metadata] Get Activity Types",
  GetActivityTypesSuccess = "[Metadata] Get Activity Types Success",
  GetActivityTypesFailure = "[Metadata] Get Activity Types Failure",
  GetPasswordStatusList = "[Metadata] Get Password Status List",
  GetPasswordStatusListSuccess = "[Metadata] Get Password Status List Success",
  GetPasswordStatusListFailure = "[Metadata] Get Password Status List Failure",
  OpenInformationDialog = "[Metadata] Open Information Dialog",
  CloseInformationDialog = "[Metadata] Close Information Dialog"
}

// ########################## Status Reason Codes ##########################

export class GetStatusReasonCodes implements Action {
  readonly type = MetadataActionTypes.GetStatusReasonCodes;
}

export class GetStatusReasonCodesSuccess implements Action {
  readonly type = MetadataActionTypes.GetStatusReasonCodesSuccess;
  constructor(public payload: { statusCodes: Status[] }) {}
}

export class GetStatusReasonCodesFailure implements Action {
  readonly type = MetadataActionTypes.GetStatusReasonCodesFailure;
}

// ########################## State Codes ##########################

export class GetStateCodes implements Action {
  readonly type = MetadataActionTypes.GetStateCodes;
}

export class GetStateCodesSuccess implements Action {
  readonly type = MetadataActionTypes.GetStateCodesSuccess;
  constructor(public payload: { stateCodes: Status[] }) {}
}

export class GetStateCodesFailure implements Action {
  readonly type = MetadataActionTypes.GetStateCodesFailure;
}

// ########################## Priority Codes ##########################

export class GetPriorityCodes implements Action {
  readonly type = MetadataActionTypes.GetPriorityCodes;
}

export class GetPriorityCodesSuccess implements Action {
  readonly type = MetadataActionTypes.GetPriorityCodesSuccess;
  constructor(public payload: { priorityCodes: Priority[] }) {}
}

export class GetPriorityCodesFailure implements Action {
  readonly type = MetadataActionTypes.GetPriorityCodesFailure;
}

// ########################## Type Codes ##########################

export class GetTypeCodes implements Action {
  readonly type = MetadataActionTypes.GetTypeCodes;
}

export class GetTypeCodesSuccess implements Action {
  readonly type = MetadataActionTypes.GetTypeCodesSuccess;
  constructor(public payload: { typeCodes: Type[] }) {}
}

export class GetTypeCodesFailure implements Action {
  readonly type = MetadataActionTypes.GetTypeCodesFailure;
}

// ########################## Roles ##########################

export class GetRoles implements Action {
  readonly type = MetadataActionTypes.GetRoles;
}

export class GetRolesSuccess implements Action {
  readonly type = MetadataActionTypes.GetRolesSuccess;
  constructor(public payload: { roles: Role[] }) {}
}

export class GetRolesFailure implements Action {
  readonly type = MetadataActionTypes.GetRolesFailure;
}

// ########################## Activity Types ##########################

export class GetActivityTypes implements Action {
  readonly type = MetadataActionTypes.GetActivityTypes;
}

export class GetActivityTypesSuccess implements Action {
  readonly type = MetadataActionTypes.GetActivityTypesSuccess;
  constructor(public payload: { activityTypes: ActivityType[] }) {}
}

export class GetActivityTypesFailure implements Action {
  readonly type = MetadataActionTypes.GetActivityTypesFailure;
}

// ########################## Password Status List ##########################

export class GetPasswordStatusList implements Action {
  readonly type = MetadataActionTypes.GetPasswordStatusList;
}

export class GetPasswordStatusListSuccess implements Action {
  readonly type = MetadataActionTypes.GetPasswordStatusListSuccess;
  constructor(public payload: { passwordStatusList: PasswordStatus[] }) {}
}

export class GetPasswordStatusListFailure implements Action {
  readonly type = MetadataActionTypes.GetPasswordStatusListFailure;
}

// ########################## General ##########################

export class OpenInformationDialog implements Action {
  readonly type = MetadataActionTypes.OpenInformationDialog;
  constructor(public payload: { title: string, information: string }) {}

}
export class CloseInformationDialog implements Action {
  readonly type = MetadataActionTypes.CloseInformationDialog;
}

export type MetadataActions =
  | GetStatusReasonCodes
  | GetStatusReasonCodesSuccess
  | GetStatusReasonCodesFailure
  | GetStateCodes
  | GetStateCodesSuccess
  | GetStateCodesFailure
  | GetPriorityCodes
  | GetPriorityCodesSuccess
  | GetPriorityCodesFailure
  | GetTypeCodes
  | GetTypeCodesSuccess
  | GetTypeCodesFailure
  | GetRoles
  | GetRolesSuccess
  | GetRolesFailure
  | GetActivityTypes
  | GetActivityTypesSuccess
  | GetActivityTypesFailure
  | GetPasswordStatusList
  | GetPasswordStatusListSuccess
  | GetPasswordStatusListFailure
  | OpenInformationDialog
  | CloseInformationDialog;
