<div *ngIf="cookieDecision == null" style="background-color: #ffc108;">
    <button 
                mat-button 
                style="position: absolute;top:0;right:0;outline: none;"
                (click)="denyCookies()"
            >
                <mat-icon>clear</mat-icon>
            </button>
    <p style="text-align: center;padding: 5px 35px 5px 35px;margin: 0;">
        Some features of this site use cookies. <a (click)="acceptCookies()" href="">Click here</a> to accept the use of cookies.
    </p>

</div>