import { Component, OnInit } from '@angular/core';

import * as CaseActions from "../../../store/actions/case.actions";
import * as SupportActivityActions from "../../../store/actions/support-activity.actions";
import * as fromReducers from "../../../store/reducers";
import { Store } from '@ngrx/store';
@Component({
  selector: 'app-dashboard-nav',
  templateUrl: './dashboard-nav.component.html',
  styleUrls: ['./dashboard-nav.component.scss']
})
export class DashboardNavComponent implements OnInit {

  constructor(
    private store: Store<fromReducers.State>
    ) { }

  ngOnInit() {
  }

  openNewCaseDialog() {
    this.store.dispatch(new CaseActions.OpenCreateCaseDialog());
  }

}
