<div [class.alternative]="otherTheme">
<div class="page-container">
  <h1 style="text-align: center;">Company Cases</h1>
  <hr />
  <div class="row">
    <div class="col-lg-6">
      <app-dashboard-nav></app-dashboard-nav>
    </div>
  </div>
  <button 
    mat-button 
    (click)="toggleFilterBox()"
    aria-label="Filter the table"
    class="table-buttons"
  >
    <mat-icon>filter_list</mat-icon>Filter
  </button>
  <button 
    mat-button
    aria-label="Export the table data"
    (click)="export(exportTitle)"
    
    class="table-buttons"
  >
    <mat-icon> get_app</mat-icon>Export
  </button>
  <button
    mat-button
    (click)="refreshCases(loggedInUser.id)"
    *ngIf="loggedInUser$ | async as loggedInUser"
    
    class="table-buttons"
  >
    <mat-icon>refresh</mat-icon>Refresh
  </button>
  <form 
    [formGroup]="openCasesForm"
    class="table-buttons"
    style="padding: 0 16px;vertical-align: sub;"
  >
    <mat-checkbox 
      [(ngModel)]="openCasesBool" 
      formControlName="openCasesBool" 
      color="primary"
      (click)="openCasesUpdate()"
      >
      Open Cases
    </mat-checkbox>
  </form>

  <div 
    style="margin:auto; width: 80%; max-width: 500px;"
  >
    <mat-card *ngIf="showFilters">
      <button 
        mat-button 
        style="position: absolute;top:0;right:0;outline: none;"
        (click)="toggleFilterBox()"
      >
        <mat-icon>clear</mat-icon>
      </button>
      <h3 style="text-align: center;">Filter All Cases</h3>

      <mat-card-content>
        <mat-form-field style="margin:auto;width: 100%;">
          <input
            #titleInp
            matInput 
            type="text"
            placeholder="Type to filter by title..."
            (keyup)="updateFilter($event)"
          />
        </mat-form-field>
      </mat-card-content>
    
      <mat-card-actions>
      </mat-card-actions>
    </mat-card>
  </div>
  <div class="row">
    <div class="col-lg-12">
      <app-cases-table
        [cases]="rows"
        [loadingIndicator]="isLoading$ | async"
      ></app-cases-table>
    </div>
  </div>
</div>
</div>