import { Action } from "@ngrx/store";
import { User } from "src/app/models/user.model";
import { AddUserRequest } from "src/app/models/add-user-request.model";

export enum UserActionTypes {
  GetUsers = "[User] Get Users",
  GetUsersSuccess = "[User] Get Users Success",
  GetUsersFailure = "[User] Get Users Failure",
  AddNewPortalUser = "[User] Add New Portal User",
  AddNewPortalUserSuccess = "[User] Add New Portal User Success",
  AddNewPortalUserFailure = "[User] Add New Portal User Failure",
  OpenAddNewPortalUserDialog = "[User] Open 'Add New Portal User' Dialog",
  CloseAddNewPortalUserDialog = "[User] Close 'Add New Portal User' Dialog",
  ToggleUserIsActivate = "[User] Toggle User IsActivate",
  ToggleUserIsActivateSuccess = "[User] Toggle User IsActivate Success",
  ToggleUserIsActivateFailure = "[User] Toggle User IsActivate Failure",
  ChangeCustomerUserType = "[User] Change Customer User Type",
  ChangeCustomerUserTypeSuccess = "[User] Change Customer User Type Success",
  ChangeCustomerUserTypeFailure = "[User] Change Customer User Type Failure",
  OpenProfileDialog = "[User] Open Profile Dialog",
  CloseProfileDialog = "[User] Close Profile Dialog",
  DeleteUser = "[User] Delete User",
  DeleteUserSuccess = "[User] Delete User Success",
  DeleteUserFailure = "[User] Delete User Failure",
  OpenConfirmDeleteDialog = "[User] Open Confirm Delete Dialog",
  OpenConfirmSendEmailDialog = "[User] Open Confirm Send Email Dialog",
  OpenConfirmChangeUserTypeDialog = "[User] Open Confirm Change User Type Dialog",
  CloseConfirmDialog = "[User] Close Confirm Dialog",
  SendWelcomeEmail = "[User] Send Welcome Email",
  SendWelcomeEmailSuccess = "[User] Send Welcome Email Success",
  SendWelcomeEmailFailure = "[User] Send Welcome Email Failure",
  OpenRequestUserDialog = "[User] Open Request User Dialog",
  CloseRequestUserDialog = "[User] Close Request User Dialog"

}

export class GetUsers implements Action {
  readonly type = UserActionTypes.GetUsers;
}
export class GetUsersSuccess implements Action {
  readonly type = UserActionTypes.GetUsersSuccess;
  constructor(public payload: { users: User[] }) {}
}
export class GetUsersFailure implements Action {
  readonly type = UserActionTypes.GetUsersFailure;
}

export class AddNewPortalUser implements Action {
  readonly type = UserActionTypes.AddNewPortalUser;
  constructor(public payload: { addUserRequest: AddUserRequest }) {}
}
export class AddNewPortalUserSuccess implements Action {
  readonly type = UserActionTypes.AddNewPortalUserSuccess;
  constructor(public payload: { user: User }) {}
}
export class AddNewPortalUserFailure implements Action {
  readonly type = UserActionTypes.AddNewPortalUserFailure;
}

export class OpenAddNewPortalUserDialog implements Action {
  readonly type = UserActionTypes.OpenAddNewPortalUserDialog;
}
export class CloseAddNewPortalUserDialog implements Action {
  readonly type = UserActionTypes.CloseAddNewPortalUserDialog;
}

export class ToggleUserIsActivate implements Action {
  readonly type = UserActionTypes.ToggleUserIsActivate;
  constructor(public payload: { userId: number, currentUserId: number }) {}
}
export class ToggleUserIsActivateSuccess implements Action {
  readonly type = UserActionTypes.ToggleUserIsActivateSuccess;
  constructor(public payload: { user: User }) {}
}
export class ToggleUserIsActivateFailure implements Action {
  readonly type = UserActionTypes.ToggleUserIsActivateFailure;
}

export class ChangeCustomerUserType implements Action {
  readonly type = UserActionTypes.ChangeCustomerUserType;
  constructor(public payload: { userId: number }) {}
}
export class ChangeCustomerUserTypeSuccess implements Action {
  readonly type = UserActionTypes.ChangeCustomerUserTypeSuccess;
  constructor(public payload: { user: User }) {}
}
export class ChangeCustomerUserTypeFailure implements Action {
  readonly type = UserActionTypes.ChangeCustomerUserTypeFailure;
}


export class OpenProfileDialog implements Action {
  readonly type = UserActionTypes.OpenProfileDialog;
}
export class CloseProfileDialog implements Action {
  readonly type = UserActionTypes.CloseProfileDialog;
}

export class DeleteUser implements Action {
  readonly type = UserActionTypes.DeleteUser;
  constructor(public payload: { userId: number }) {}
}
export class DeleteUserSuccess implements Action {
  readonly type = UserActionTypes.DeleteUserSuccess;
  constructor(public payload: { user: User }) {}
}
export class DeleteUserFailure implements Action {
  readonly type = UserActionTypes.DeleteUserFailure;
}

export class OpenConfirmDeleteDialog implements Action {
  readonly type = UserActionTypes.OpenConfirmDeleteDialog;
  constructor(public payload: { user: User }) {}
}
export class OpenConfirmSendEmailDialog implements Action {
  readonly type = UserActionTypes.OpenConfirmSendEmailDialog;
  constructor(public payload: { user: User }) {}
}
export class OpenConfirmChangeUserTypeDialog implements Action {
  readonly type = UserActionTypes.OpenConfirmChangeUserTypeDialog;
  constructor(public payload: { user: User }) {}
}
export class CloseConfirmDialog implements Action {
  readonly type = UserActionTypes.CloseConfirmDialog;
}

export class SendWelcomeEmail implements Action {
  readonly type = UserActionTypes.SendWelcomeEmail;
  constructor(public payload: { user: User }) {}
}
export class SendWelcomeEmailSuccess implements Action {
  readonly type = UserActionTypes.SendWelcomeEmailSuccess;
  constructor(public payload: { user: User }) {}
}
export class SendWelcomeEmailFailure implements Action {
  readonly type = UserActionTypes.SendWelcomeEmailFailure;
}

export class OpenRequestUserDialog implements Action {
  readonly type = UserActionTypes.OpenRequestUserDialog;
  constructor(public payload: { currentUser: User }) {}

}
export class CloseRequestUserDialog implements Action {
  readonly type = UserActionTypes.OpenRequestUserDialog;
}

// export class RequestUser implements Action {
//   readonly type = UserActionTypes.RequestUser;
//   constructor(public payload: { reqUser: RequestUser }) {}
// }
// export class RequestUserSuccess implements Action {
//   readonly type = UserActionTypes.RequestUserSuccess;
// }
// export class RequestUserFailure implements Action {
//   readonly type = UserActionTypes.RequestUserFailure;
// }


export type UserActions =
  | GetUsers
  | GetUsersSuccess
  | GetUsersFailure
  | AddNewPortalUser
  | AddNewPortalUserSuccess
  | AddNewPortalUserFailure
  | OpenAddNewPortalUserDialog
  | CloseAddNewPortalUserDialog
  | ToggleUserIsActivate
  | ToggleUserIsActivateSuccess
  | ToggleUserIsActivateFailure
  | ChangeCustomerUserType
  | ChangeCustomerUserTypeSuccess
  | ChangeCustomerUserTypeFailure
  | OpenProfileDialog
  | CloseProfileDialog
  | DeleteUser
  | DeleteUserSuccess
  | DeleteUserFailure
  | OpenConfirmDeleteDialog
  | OpenConfirmSendEmailDialog
  | CloseConfirmDialog
  | SendWelcomeEmail
  | SendWelcomeEmailSuccess
  | SendWelcomeEmailFailure
  | OpenRequestUserDialog
  | CloseRequestUserDialog;
