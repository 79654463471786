import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { CustomerService } from "src/app/services/customer.service";
import { Observable, of } from "rxjs";
import { CustomerActionTypes } from "../actions/customer.actions";
import { mergeMap, map, catchError } from "rxjs/operators";

import * as CustomerActions from "../actions/customer.actions";

@Injectable()
export class CustomerEffects {
  constructor(
    private actions$: Actions,
    private customerService: CustomerService
  ) {}

  
  getCustomers$: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType<CustomerActions.GetCustomers>(CustomerActionTypes.GetCustomers),
    mergeMap(action => {
      return this.customerService.getCustomers().pipe(
        map(customers => {
          return new CustomerActions.GetCustomersSuccess({ customers });
        }),
        catchError(error => {
          console.error("Error: ", error);
          return of(new CustomerActions.GetCustomersFailure());
        })
      );
    })
  ));
}
