import { Injectable } from "@angular/core";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";

const baseConfig: MatSnackBarConfig = {
  panelClass: [],
  verticalPosition: "top",
  horizontalPosition: "right"
};

@Injectable({
  providedIn: "root"
})
export class NotificationService {
  constructor(private snackBar: MatSnackBar) {}

  showError(message: string, action: string, config?: MatSnackBarConfig) {
    if (config == null) {
      config = baseConfig;
    }

    (config.panelClass as string[]).push("notification-error");

    this.snackBar.open(message, action, config);
  }

  showSuccess(message: string, action: string, config?: MatSnackBarConfig) {
    if (config == null) {
      config = baseConfig;
    }

    (config.panelClass as string[]).push("notification-success");

    this.snackBar.open(message, action, config);
  }

  showWarning(message: string, action: string, config?: MatSnackBarConfig) {
    if (config == null) {
      config = baseConfig;
    }

    (config.panelClass as string[]).push("notification-warning");

    this.snackBar.open(message, action, config);
  }

  showMessage(message: string) {}
}
