import { Component, OnInit } from "@angular/core";
import { UntypedFormGroup, UntypedFormControl, Validators } from "@angular/forms";
import { Priority } from "src/app/models/priority.model";

import * as fromReducers from "../../../store/reducers";
import { Store, select } from "@ngrx/store";

import * as CaseActions from "../../../store/actions/case.actions";
import { Case } from "src/app/models/case.model";
import { Customer } from "src/app/models/customer.model";
import { MatDialogRef } from '@angular/material/dialog';
import { ThemeService } from 'src/app/services/theme.service';
import { Type } from "src/app/models/type.model";

@Component({
  selector: "app-new-case",
  templateUrl: "./new-case.component.html",
  styleUrls: ["./new-case.component.scss"]
})
export class NewCaseComponent implements OnInit {
  newTitle: string;
  q3Message: string = "Please provide a Title for your Case and a Description";

  constructor(
    public dialogRef: MatDialogRef<NewCaseComponent>,
    private store: Store<fromReducers.State>,
    private themeService : ThemeService
    ) {}

  newCaseForm = new UntypedFormGroup({
    priorityGroup: new UntypedFormGroup({
      priorityControl: new UntypedFormControl("", [Validators.required])
    }),
    caseTypeGroup: new UntypedFormGroup({
      caseTypeControl: new UntypedFormControl("", [Validators.required])
    }),
    detailsGroup: new UntypedFormGroup({
      titleControl: new UntypedFormControl("", [Validators.required]),
      descriptionControl: new UntypedFormControl("", [Validators.required])
    })
  });

  priorities$ = this.store.pipe(select(fromReducers.getPriorityCodesAll));
  caseTypes = [
    {label: "I have a fault (something is not working) ", value: 1} as Type,
    {label: "Something isn’t behaving quite right ", value: 2} as Type,
    {label: "I’d like you to do or change something ", value: 3} as Type
  ];
  priorityTypes = [
    {label: "My application is down and nobody can use it at all  ", value: 1} as Type,
    {label: "This is affecting the majority of users and has a notable impact ", value: 2} as Type,
    {label: "This is affecting a few users and has a notable impact  ", value: 3} as Type,
    {label: "We can work around this for now ", value: 4} as Type,
    {label: "This is an enquiry or for your information  ", value: 5} as Type,
  ];
  isCreatingCase$ = this.store.pipe(
    select(fromReducers.getCasesIsCreatingCase)
  );

  ngOnInit() {}

  createNewCase() {
    if (this.newCaseForm.invalid) {
      return;
    } else {
      const newCase = {
        priorityCode: this.newCaseForm
          .get("priorityGroup")
          .get("priorityControl").value,
        typeCode: this.newCaseForm
          .get("caseTypeGroup")
          .get("caseTypeControl").value,
        title: this.newCaseForm.get("detailsGroup").get("titleControl").value,
        description: this.newCaseForm
          .get("detailsGroup")
          .get("descriptionControl").value
      } as Case;
      this.store.dispatch(new CaseActions.CreateNewCase({ case: newCase , redirect: true}));
      this.dialogRef.close();
    }
  }

  get otherTheme(): boolean {
    return this.themeService.otherTheme;
  }
  changeTheme(){
    this.themeService.changeTheme();
  }

  updateChoice(){
    let typeCode = this.newCaseForm
      .get("caseTypeGroup")
      .get("caseTypeControl").value;
    let endMsg;
    switch (typeCode){
      case 1:
        endMsg = " of what's not working.";
        break;
      case 2:
        endMsg = " of the behaviour you're seeing.";
        break;
      case 3:
        endMsg = " of what you would like.";
        break;
      default:
        endMsg = ".";
        break;
    }
    this.q3Message = "Please provide a Title for your Case and a Description"+endMsg;
  }

}
