import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

// FontAwesome Modules
import {
  FontAwesomeModule,
  FaIconLibrary
} from "@fortawesome/angular-fontawesome";

import {
  faSpinner,
  faBan,
  faHome,
  faClipboardList,
  faChartBar,
  faQuestion,
  faShareAlt,
  faCommentAlt,
  faUser,
  faBell,
  faPencilAlt,
  faPlus,
  faExclamationCircle,
  faLock,
  faCheck,
  faTimes,
  faCloudDownloadAlt,
  faRedoAlt,
  faExpand,
  faFileExport
} from "@fortawesome/free-solid-svg-icons";

@NgModule({
  declarations: [],
  imports: [CommonModule, FontAwesomeModule],
  exports: [FontAwesomeModule]
})
export class FontawesomeModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(faSpinner);
    library.addIcons(faBan);
    library.addIcons(faHome);
    library.addIcons(faClipboardList);
    library.addIcons(faChartBar);
    library.addIcons(faQuestion);
    library.addIcons(faShareAlt);
    library.addIcons(faCommentAlt);
    library.addIcons(faUser);
    library.addIcons(faBell);
    library.addIcons(faPencilAlt);
    library.addIcons(faPlus);
    library.addIcons(faExclamationCircle);
    library.addIcons(faLock);
    library.addIcons(faCheck);
    library.addIcons(faTimes);
    library.addIcons(faCloudDownloadAlt);
    library.addIcons(faRedoAlt);
    library.addIcons(faExpand);
    library.addIcons(faFileExport);
  }
}
