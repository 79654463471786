import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { NotificationService } from 'src/app/services/notification.service';
import * as fromReducers from "../../../../store/reducers";
import { RequestUser } from "src/app/models/request-user.model";
import { Case } from 'src/app/models/case.model';


@Component({
  selector: 'app-request-user-dialog',
  templateUrl: './request-user-dialog.component.html',
  styleUrls: ['./request-user-dialog.component.scss']
})
export class RequestUserDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<RequestUserDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private store: Store<fromReducers.State>,
    private notificationService: NotificationService
  ) 
  {

  }

  requestUserForm: UntypedFormGroup = new UntypedFormGroup({
    firstNameControl: new UntypedFormControl("", [Validators.required]),
    lastNameControl: new UntypedFormControl("", [Validators.required]),
    emailControl: new UntypedFormControl("", [Validators.required]),
    otherInfoControl: new UntypedFormControl("",[Validators.required])
  });

  ngOnInit() {
  }

  requestUser(){
    if (this.requestUserForm.invalid) {
      this.notificationService.showError("Sorry, the form is invalid. Check all required fields.","close");
      return;
    }
    const firstName: string = this.requestUserForm.get("firstNameControl").value;
    const lastName: string = this.requestUserForm.get("lastNameControl").value;
    const email: string = this.requestUserForm.get("emailControl").value;
    const otherInfo: string = this.requestUserForm.get("otherInfoControl").value;
    if(this.data.currentUser != null){
      const newCase = {
        priorityCode: 5,
        typeCode: 3,
        title: "Request For New User",
        description: "First Name: "+firstName+".\nLast Name: "+lastName+".\nEmail: "+email+".\nOther Info: "+otherInfo
      } as Case;
      this.dialogRef.close({
        case: newCase
      });
    }

  }



}
