import { Action } from "@ngrx/store";
import { User } from "src/app/models/user.model";

export enum AuthActionTypes {
  LOGIN = "[Auth] Login",
  LOGIN_CALLBACK = "[Auth] Login Callback",
  LOGIN_SUCCESS = "[Auth] Login Success",
  LOGIN_FAILURE = "[Auth] Login Failure",
  LOGOUT = "[Auth] Logout",
  LOGOUT_SUCCESS = "[Auth] Logout Success",
  GET_STATUS = "[Auth] GetStatus",
  LOGIN_VALIDATION = "[App Startup] Login Validation",
  LOGIN_INITIAL_LOAD = "[App Startup] Login Initial Load",
  LOGIN_VALIDATED = "[App Starup] Login Validated",
  LOGIN_NOT_VALIDATED = "[App Starup] Login Not Validated",
  CHANGE_PASSWORD = "[Profile] Change Password",
  CHANGE_PASSWORD_SUCCESS = "[Profile] Change Password Success",
  CHANGE_PASSWORD_FAILURE = "[Profile] Change Password Failure"
}

export class LogIn implements Action {
  readonly type = AuthActionTypes.LOGIN;
}

export class LogInCallback implements Action {
  readonly type = AuthActionTypes.LOGIN_CALLBACK;
}

export class LogInSuccess implements Action {
  readonly type = AuthActionTypes.LOGIN_SUCCESS;
  constructor(public payload: { user: User }) {}
}

export class LogInFailure implements Action {
  readonly type = AuthActionTypes.LOGIN_FAILURE;
}

export class LogOut implements Action {
  readonly type = AuthActionTypes.LOGOUT;
}

export class LogOutSuccess implements Action {
  readonly type = AuthActionTypes.LOGOUT_SUCCESS;
}

export class GetStatus implements Action {
  readonly type = AuthActionTypes.GET_STATUS;
}

export class LoginValidation implements Action {
  readonly type = AuthActionTypes.LOGIN_VALIDATION;
}

export class LoginInitialLoad implements Action {
  readonly type = AuthActionTypes.LOGIN_INITIAL_LOAD;
}

export class LoginValidated implements Action {
  readonly type = AuthActionTypes.LOGIN_VALIDATED;
  constructor(public payload: { user: User }) {}
}

export class LoginNotValidated implements Action {
  readonly type = AuthActionTypes.LOGIN_NOT_VALIDATED;
}

export class ChangePassword implements Action {
  readonly type = AuthActionTypes.CHANGE_PASSWORD;
  constructor(
    public payload: { currentPassword: string; newPassword: string }
  ) {}
}
export class ChangePasswordSuccess implements Action {
  readonly type = AuthActionTypes.CHANGE_PASSWORD_SUCCESS;
}
export class ChangePasswordFailure implements Action {
  readonly type = AuthActionTypes.CHANGE_PASSWORD_FAILURE;
}

export type All =
  | LogIn
  | LogInCallback
  | LogInSuccess
  | LogInFailure
  | LogOut
  | LogOutSuccess
  | GetStatus
  | LoginValidation
  | LoginInitialLoad
  | LoginValidated
  | LoginNotValidated
  | ChangePassword
  | ChangePasswordSuccess
  | ChangePasswordFailure;
