<div [class.alternative]="otherTheme">
  <div class="page-container">
    <h1 style="text-align: center;">Manage Users</h1>
    <hr />
    <button 
      mat-button 
      (click)="toggleFilterBox()"
      aria-label="Filter the table"
    >
      <mat-icon>filter_list</mat-icon>Filter
    </button>
    <button 
      mat-button
      aria-label="Export the table data"
      (click)="export(exportTitle)"
    >
      <mat-icon> get_app</mat-icon>Export
    </button>
    <button
        mat-button
        (click)="refreshUsers()"
      >
        <mat-icon>refresh</mat-icon>Refresh
      </button>
      <form 
        [formGroup]="hideAdminsBoolForm"
        class="table-buttons"
        style="padding: 0 16px;vertical-align: sub;display: inline;"
      >
        <mat-checkbox 
          [(ngModel)]="hideAdminsBool" 
          formControlName="hideAdminsBool" 
          color="primary"
          (click)="hideAdminsUpdate()"
          *ngIf="(loggedInUser$ | async)?.isSimpsonsAdmin"
          >
          Hide Admins
        </mat-checkbox>
      </form>
    <div 
      style="margin:auto; width: 80%; max-width: 500px;"
    >
      <mat-card *ngIf="showFilters">
        <button 
          mat-button 
          style="position: absolute;top:0;right:0;outline: none;"
          (click)="toggleFilterBox()"
        >
          <mat-icon>clear</mat-icon>
        </button>
        <h3 style="text-align: center;">Filter Users</h3>

        <mat-card-content>
          <mat-form-field style="margin:auto;width: 100%;">
            <input
              #customerInp
              matInput 
              type="text"
              placeholder="Type to filter by customer..."
              (keyup)="updateFilter($event,1)"
            />
          </mat-form-field>
          <mat-form-field style="margin:auto;width: 100%;">
            <input
              #emailInp
              matInput 
              type="text"
              placeholder="Type to filter by email..."
              (keyup)="updateFilter($event,2)"
            />
          </mat-form-field>
        </mat-card-content>
      
        <mat-card-actions>
        </mat-card-actions>
      </mat-card>
    </div>
    
    <div style="overflow-x: auto;">
      <ngx-datatable
        #table
        class="material"
        [rows]="rows "
        [columnMode]="'force'"
        [headerHeight]="50"
        [footerHeight]="50"
        [rowHeight]="50"
        [limit]="10"
        [rowClass]="getRowClass"
        [loadingIndicator]="isLoading$ | async"
        style="min-width: 850px;"
      >
        <ngx-datatable-column name="Active" [flexGrow]="1" prop="isActive">
          <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
            <mat-icon *ngIf="row.isActive">check</mat-icon>
            <div  matTooltip="Click to see details" *ngIf="!row.isActive" >
              <mat-icon (click)="showUserDisableInfo(row)" class="icon-button">close</mat-icon>
            </div>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Name" [flexGrow]="3" prop="crmContact.lastName">
          <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
            {{ row.crmContact?.firstName }} {{ row.crmContact?.lastName }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Email" [flexGrow]="3">
          <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
            {{ row.email }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Customer" [flexGrow]="3" prop="crmContact.customer.name">
          <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
            {{ row.crmContact?.customer?.name }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Role" [flexGrow]="4" prop="role.name">
          <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
            <div  matTooltip="Customer Superuser" *ngIf="row.role?.id == 2" matTooltipPosition="before">
              {{ row.role?.name }}
              <mat-icon style="vertical-align: bottom;font-size: 1.3rem;color: cornflowerblue;">check_circle_outline</mat-icon>
            </div>
            <span *ngIf="row.role?.id != 2">{{ row.role?.name }}</span>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Password Status" [flexGrow]="1" prop="passwordStatus">
          <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
            <div *ngIf="row.role?.id != 3">
              <div  matTooltip="Welcome email sent" *ngIf="row.passwordStatus == 1" matTooltipPosition="before"><mat-icon >email</mat-icon></div>
              <div  matTooltip="Pre-staged (no email sent)" *ngIf="row.passwordStatus == 2" matTooltipPosition="before"><mat-icon >watch_later</mat-icon></div>
              <div  matTooltip="Manually set up by admin" *ngIf="row.passwordStatus == 3"matTooltipPosition="before"><mat-icon >supervisor_account</mat-icon></div>
              <div  matTooltip="User has set password" *ngIf="row.passwordStatus == 4"matTooltipPosition="before"><mat-icon >lock</mat-icon></div>
            </div>
          </ng-template>
        </ngx-datatable-column>
      
        <ngx-datatable-column name="" [flexGrow]="1">
          <ng-template ngx-datatable-header-template>
            <button
              class="btn btn-info btn-datatable"
              type="button"
              title="Add a new user from CRM to the portal"
              (click)="openDialog()"
              *ngIf="(loggedInUser$ | async)?.isSimpsonsAdmin"
            >
              Add New Portal User
            </button>
            <button
              class="btn btn-info btn-datatable"
              type="button"
              title="Request a new user for the portal"
              (click)="openRequestUserDialog()"
              *ngIf="!(loggedInUser$ | async)?.isSimpsonsAdmin"
            >
              Request New Portal User
            </button>
          </ng-template>
          <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
            <button *ngIf="row.role?.name != simpAdminString && (loggedInUser$ | async)?.email != row.email" mat-icon-button [matMenuTriggerFor]="menu" aria-label="Options">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item *ngIf="!row.isActive" (click)="showUserDisableInfo(row)">
                <div>
                  <mat-icon>info</mat-icon>
                  <span>Info</span>
                </div>
              </button>
              <button mat-menu-item *ngIf="row.passwordStatus != 4" (click)="sendWelcomeEmail(row)">
                <div>
                  <mat-icon>email</mat-icon>
                  <span>Send Welcome Email</span>
                </div>
              </button>
              <button mat-menu-item (click)="toggleUserIsActive(row.id)">
                <div *ngIf="!row.isActive">
                  <mat-icon>sync</mat-icon>
                  <span>Enable User</span>
                </div>
                <div *ngIf="row.isActive">
                  <mat-icon>sync_disabled</mat-icon>
                  <span>Disable User</span>
                </div>
              </button>
              <button mat-menu-item (click)="deleteUser(row)" *ngIf="!row.isActive && (loggedInUser$ | async)?.isSimpsonsAdmin" class="btn btn-danger">
                <div>
                  <mat-icon>delete_forever</mat-icon>
                  <span>Delete User</span>
                </div>
              </button>
              
              <button mat-menu-item (click)="changeCustomerUserType(row)" >
                <div *ngIf="row.role?.id == 1">
                  <mat-icon>arrow_upward</mat-icon>
                  <span>Make Superuser</span>
                </div>
                <div *ngIf="row.role?.id == 2">
                  <mat-icon>arrow_downward</mat-icon>
                  <span>Remove Superuser</span>
                </div>
              </button>
              
              <!-- <button mat-menu-item (click)="impersonateUser(row.id)">
                <div>
                  <mat-icon>supervised_user_circle</mat-icon>
                  <span>Customer View</span>
                </div>
              </button> -->
            </mat-menu>
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>
    </div>

    

  </div>
</div>