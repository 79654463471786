<h2 matDialogTitle>Request New Portal User</h2>
<mat-dialog-content class="text-center dialog" style="min-height: 400px;">
  <form [formGroup]="requestUserForm">
    <mat-form-field class="full-width">
        <mat-label>First Name</mat-label>
        <input
        matInput
        type="text"
        formControlName="firstNameControl"
        autocomplete="off"
        required
        />
    </mat-form-field>
    <mat-form-field class="full-width">
        <mat-label>Last Name</mat-label>
        <input
            matInput
            type="text"
            formControlName="lastNameControl"
            autocomplete="off"
            required
        />
    </mat-form-field>
    <mat-form-field class="full-width">
        <mat-label>Email Address</mat-label>
        <input
            matInput
            type="email"
            formControlName="emailControl"
            autocomplete="off"
            required
        />
    </mat-form-field>
    <mat-form-field class="full-width">
        <mat-label>Other Info</mat-label>
        <input
            matInput
            type="text"
            formControlName="otherInfoControl"
            autocomplete="off"
        />
    </mat-form-field>
    

  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="">CANCEL</button>
  <button mat-button (click)="requestUser()">SEND REQUEST</button>
</mat-dialog-actions>
