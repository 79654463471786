import { Injectable } from "@angular/core";
import { BaseService } from "./base.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Router } from "@angular/router";
import { Status } from "../models/status.model";
import { Role } from "../models/role.model";
import { StatusReason } from "../models/status-reason.model";
import { Priority } from "../models/priority.model";
import { Type } from "../models/type.model";
import { ActivityType } from "../models/activity-type.model";
import { PasswordStatus } from '../models/password-status.model';

const API_URL = `${environment.apiUrl}` + "/metadata/";

@Injectable({
  providedIn: "root"
})
export class MetadataService extends BaseService {
  constructor(private http: HttpClient, private router: Router) {
    super();
  }

  private options = {
    headers: new HttpHeaders().set("Content-Type", "application/json")
  };

  getStatusReasonCodes() {
    return this.http.get<StatusReason[]>(API_URL + "statuscodes", this.options);
  }

  getStateCodes() {
    return this.http.get<Status[]>(API_URL + "statecodes", this.options);
  }

  getPriorityCodes() {
    return this.http.get<Priority[]>(API_URL + "prioritycodes", this.options);
  }

  getTypeCodes() {
    return this.http.get<Type[]>(API_URL + "typecodes", this.options);
  }

  getRoles() {
    return this.http.get<Role[]>(API_URL + "roles", this.options);
  }

  getActivityTypes() {
    return this.http.get<ActivityType[]>(
      API_URL + "activity-types",
      this.options
    );
  }

  getPasswordStatusList() {
    return this.http.get<PasswordStatus[]>(API_URL + "password-status-list", this.options);
  }
}
