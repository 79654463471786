import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MaterialModule } from "./material.module";
import { NgxBootstrapModule } from "./ngx-bootstrap.module";
import { FontawesomeModule } from "./fontawesome.module";

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    MaterialModule,
    NgxBootstrapModule,
    FontawesomeModule
  ],
  exports: [MaterialModule, NgxBootstrapModule, FontawesomeModule]
})
export class SharedModule {}
