import { Component, OnInit, Input } from "@angular/core";
import { Case } from "src/app/models/case.model";
import { Store, select } from "@ngrx/store";

import * as fromReducers from "../../../store/reducers";

@Component({
  selector: "app-cases-table",
  templateUrl: "./cases-table.component.html",
  styleUrls: ["./cases-table.component.scss"]
})
export class CasesTableComponent implements OnInit {
  constructor(private store: Store<fromReducers.State>) {}

  @Input() cases: Case[];
  @Input() loadingIndicator: boolean;

  error$ = this.store.pipe(select(fromReducers.getCasesError));

  ngOnInit() {}
}
