import { Injectable } from "@angular/core";
import { BaseService } from "./base.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Case } from "../models/case.model";
import { environment } from "src/environments/environment";
import { Router } from "@angular/router";
import { tap, catchError } from "rxjs/operators";
import { SupportActivity } from "../models/support-activity.model";
import { Attachment } from "../models/attachment.model";
import { Store } from '@ngrx/store';

import * as CaseActions from "../store/actions/case.actions";
import * as fromReducers from "../store/reducers";
import { StatusReason } from '../models/status-reason.model';

const API_URL = `${environment.apiUrl}` + "/cases/";

@Injectable({
  providedIn: "root"
})
export class CaseService extends BaseService {
  constructor(private http: HttpClient, private router: Router,
    private store: Store<fromReducers.State>) {
    super();
  }


  private options = {
    headers: new HttpHeaders().set("Content-Type", "application/json")
  };

  getCompanyCases(userId: string) {
    return this.http.get<Case[]>(API_URL + "company", this.options);
  }

  createNewCase(newCase: Case, redirect: boolean) {
    return this.http
      .post<Case>(API_URL + "new", JSON.stringify(newCase), this.options)
      .pipe(
        tap(newlyCreatedCase => {
          // newlyCreatedCase.statusReason = {label:"Open", value: "100000012" } as StatusReason; //this is just to locally set status as open initially (gets updated)
          if(redirect)
            this.goToCase(newlyCreatedCase.id);
        })
      );
  }
  goToCase(caseId: string) {
    this.router.navigate(["/cases", caseId]);
  }

  createNewSupportActivity(newActivity: SupportActivity, openActivity: Boolean) {
    //TODO change this to get the CaseCRMid instead of getting it from the url
    let fullUrl = this.router.url;
    let currentCaseId = fullUrl.substring(fullUrl.indexOf("/cases/")+7);
    console.log("newActivity",newActivity);
    let newActivity2 = {
      caseId: currentCaseId, 
      subject: newActivity.subject, 
      description: newActivity.description, 
      contact: newActivity.contact,
      owner: newActivity.owner
    } as SupportActivity;
    var test = JSON.stringify(newActivity2);
    console.log("test",test);
    return this.http
      .post<SupportActivity>(API_URL + currentCaseId + "/new-activity", JSON.stringify(newActivity2), this.options)
      .pipe(
        tap(newlyCreatedActivity => {
          newlyCreatedActivity.activityTypeCode = this.mapActivityTypeCode(newlyCreatedActivity.activityTypeCode);
          if(openActivity){
            this.router.navigate(["/cases/"+currentCaseId+"/activities/"+newlyCreatedActivity.id]);
          };
          
        })
      );
  }

  closeCase(caseCrmId: string, closureReason: string, statusCode: number) {
    return this.http.post<Case>(
      API_URL + caseCrmId + "/close",
      JSON.stringify({closureReason, statusCode}),
      this.options
    );
  }

  customerCloseCase(caseCrmId: string) {
    return this.http.post<Case>(
      API_URL + caseCrmId + "/customer-close",
      this.options
    );
  }

  

  getSupportActivitiesForCase(caseCrmId: string) {
    return this.http.get<SupportActivity[]>(
      API_URL + caseCrmId + "/activities",
      this.options
    ).pipe(
      tap(activities => {
        activities.map(activity=>{
          activity.activityTypeCode = this.mapActivityTypeCode(activity.activityTypeCode);
          return activity;
        }) as SupportActivity[];
        
      })
    );
    
  }

  getAttachmentsForSupportActivity(caseCrmId: string, activityCrmId: string) {
    return this.http.get<Attachment[]>(
      API_URL + caseCrmId + "/activities/" + activityCrmId + "/attachments",
      this.options
    );
  }

  getAttachmentsForCase(caseCrmId: string) {
    return this.http.get<Attachment[]>(
      API_URL + caseCrmId + "/activities/attachments",
      this.options
    );
  }

  downloadAttachmentForSupportActivity(
    caseCrmId: string,
    activityCrmId: string,
    attachmentId: string
  ) {
    window.open(
      API_URL +
        caseCrmId +
        "/activities/" +
        activityCrmId +
        "/attachments/" +
        attachmentId
    );
  }

  mapActivityTypeCode(activityTypeCode: string){
    var output;
    switch(activityTypeCode){
      case "new_servicedeskactivity":
        output= "Support Activity";
        break;
      case "saisl_portalactivity":
        output= "Portal Activity";
        break;
      case "adx_alertsubscription":
        output= "Alert Subscription";
        break;
      case "adx_portalcomment":
        output= "Portal Comment";
        break;
      case "itarapro_resourceusage":
        output= "Resource Usage";
        break;
      case "cdi_txtmessage":
        output= "Text Message";
        break;
      case "xrm1_trigger":
        output= "Trigger";
        break;
      default:
        output= activityTypeCode;
        break;
    }

    return output;
  }
}
