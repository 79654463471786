import { Component, OnInit } from "@angular/core";
import { AuthService } from "../../services/auth.service";
import { ActivatedRoute, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import * as fromReducers from "../../store/reducers";
import * as AuthActions from "../../store/actions/auth.actions";

@Component({
  selector: "app-auth-callback",
  templateUrl: "./auth-callback.component.html",
  styleUrls: ["./auth-callback.component.scss"]
})
export class AuthCallbackComponent implements OnInit {
  error: boolean;

  constructor(
    private store: Store<fromReducers.State>,
    private route: ActivatedRoute
  ) {}

  async ngOnInit() {
    // check for error
    if (
      this.route.snapshot.fragment != null &&
      this.route.snapshot.fragment.indexOf("error") >= 0
    ) {
      this.error = true;
      return;
    }
    this.store.dispatch(new AuthActions.LogInCallback());
  }
}
