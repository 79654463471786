import { Injectable } from '@angular/core';
import { ExportToCsv } from 'export-to-csv';

@Injectable({
  providedIn: 'root'
})
export class ExportToCsvService {

  constructor() { }

  exportAsCSV(data,title) {
    
     
    const options = { 
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true, 
      showTitle: true,
      filename: title,
      title: title,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
      // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
    };
   
  const csvExporter = new ExportToCsv(options);
   
  csvExporter.generateCsv(data);
}

}
