<div [class.alternative]="otherTheme">
<div class="page-container">
  <h1 style="text-align: center;">Dashboard</h1>
  <hr />
  <div class="row">
    <div class="col-lg-6">
      <app-dashboard-nav></app-dashboard-nav>
    </div>
    <div
     class="col-lg-6"
     *ngIf="(loggedInUser$ | async)?.isSimpsonsAdmin || (loggedInUser$ | async)?.role?.name == 'Customer Superuser'"
    >
      <div class="row">
        <div class="col-lg-8">
          <h5 style="margin-top: 0.5rem;text-align: center;">Hourly Support Token Balance: <strong style="color: #2196f3;">{{tokenBalance}}</strong> Tokens </h5>
        </div>
        <div class="col-lg-4">
          <mat-card
            class="mat-elevation-z8 text-center clickable-card hover-colour"
            [routerLink]="['/tokens']"
          >
            <h6>Token Details</h6>
          </mat-card>
      </div>
    </div>
     
        
      
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6">
      <mat-card class="mat-elevation-z8 chart-card">
        <form [formGroup]="showMyCasesDataForm">
          <mat-checkbox 
            [(ngModel)]="showMyCasesData" 
            formControlName="showMyCasesData" 
            style="position: absolute;top:2px;left:6px;"
            color="primary"
            >
            My cases
          </mat-checkbox>
        </form>

        <div class="cases-chart-container text-center" *ngIf=!showMyCasesData>
          <h5>Last 6 Months Cases</h5>
          <ngx-charts-bar-vertical-stacked
            [results]="casesData$ | async"
            [xAxis]="true"
            [yAxis]="true"
            [legend]="true"
            [scheme]="colorScheme"
            [showXAxisLabel]="true"
            [xAxisLabel]="'Month Opened'"
            [showYAxisLabel]="true"
            [yAxisLabel]="'Number Of Cases'"
            [yAxisTickFormatting] = "axisFormat" 
            [animations]="true"
          >
          </ngx-charts-bar-vertical-stacked>
        </div>

        <div class="cases-chart-container text-center" *ngIf=showMyCasesData>
          <h5>Last 6 Months Cases</h5>
          <ngx-charts-bar-vertical-stacked
            [results]="myCasesData$ | async"
            [xAxis]="true"
            [yAxis]="true"
            [legend]="true"
            [scheme]="colorScheme"
            [showXAxisLabel]="true"
            [xAxisLabel]="'Month Opened'"
            [showYAxisLabel]="true"
            [yAxisLabel]="'Number Of Cases'"
            [yAxisTickFormatting] = "axisFormat"
            [animations]="true"
          >
          </ngx-charts-bar-vertical-stacked>
        </div>
      </mat-card>
    </div>

    <div class="col-lg-6">
      <mat-card class="mat-elevation-z8 chart-card">
        <form [formGroup]="showMyCasesDataForm">
          <mat-checkbox 
            [(ngModel)]="showMyCasesData" 
            formControlName="showMyCasesData" 
            style="position: absolute;top:2px;left:6px;"
            color="primary"
            >
            My cases
          </mat-checkbox>
        </form>
        <div class="cases-chart-container text-center" *ngIf="!showMyCasesData">
          <h5>Open Cases by Status</h5>
          <ngx-charts-pie-grid
            [results]="casesPieChartData$ | async"
            [scheme]="colorScheme2"
          >
          </ngx-charts-pie-grid>
        </div>
        <div class="cases-chart-container text-center" *ngIf="showMyCasesData">
          <h5>Open Cases by Status</h5>
          <ngx-charts-pie-grid
            [results]="myCasesPieChartData$ | async"
            [scheme]="colorScheme2"
          >
          </ngx-charts-pie-grid>
        </div>
      </mat-card>
    </div>

  </div>
</div>
 
