import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Observable, of } from "rxjs";
import { tap, map, switchMap, catchError, mergeMap, exhaustMap } from "rxjs/operators";
import { MetadataActionTypes } from "../actions/metadata.actions";
import * as MetadataActions from "../actions/metadata.actions";
import { MetadataService } from "src/app/services/metadata.service";
import { Action } from "@ngrx/store";
import { InformationDialogComponent } from 'src/app/components/information-dialog/information-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Injectable()
export class MetadataEffects {
  constructor(
    private actions: Actions,
    private metadataService: MetadataService,
    private dialog: MatDialog
  ) {}

  
  getStatusCodes$: Observable<any> = createEffect(() => this.actions.pipe(
    ofType<MetadataActions.GetStatusReasonCodes>(
      MetadataActionTypes.GetStatusReasonCodes
    ),
    mergeMap(action => {
      return this.metadataService.getStatusReasonCodes().pipe(
        map(statusCodes => {
          return new MetadataActions.GetStatusReasonCodesSuccess({
            statusCodes
          });
        }),
        catchError(error => {
          console.error("Error: ", error);
          return of(new MetadataActions.GetStatusReasonCodesFailure());
        })
      );
    })
  ));

  
  getStateCodes$: Observable<any> = createEffect(() => this.actions.pipe(
    ofType<MetadataActions.GetStateCodes>(MetadataActionTypes.GetStateCodes),
    mergeMap(action => {
      return this.metadataService.getStateCodes().pipe(
        map(stateCodes => {
          return new MetadataActions.GetStateCodesSuccess({ stateCodes });
        }),
        catchError(error => {
          console.error("Error: ", error);
          return of(new MetadataActions.GetStateCodesFailure());
        })
      );
    })
  ));

  
  getPriorityCodes$: Observable<any> = createEffect(() => this.actions.pipe(
    ofType<MetadataActions.GetPriorityCodes>(
      MetadataActionTypes.GetPriorityCodes
    ),
    mergeMap(action => {
      return this.metadataService.getPriorityCodes().pipe(
        map(priorityCodes => {
          return new MetadataActions.GetPriorityCodesSuccess({ priorityCodes });
        }),
        catchError(error => {
          console.error("Error: ", error);
          return of(new MetadataActions.GetPriorityCodesFailure());
        })
      );
    })
  ));

  
  getTypeCodes$: Observable<any> = createEffect(() => this.actions.pipe(
    ofType<MetadataActions.GetTypeCodes>(MetadataActionTypes.GetTypeCodes),
    mergeMap(action => {
      return this.metadataService.getTypeCodes().pipe(
        map(typeCodes => {
          return new MetadataActions.GetTypeCodesSuccess({ typeCodes });
        }),
        catchError(error => {
          console.error("Error: ", error);
          return of(new MetadataActions.GetTypeCodesFailure());
        })
      );
    })
  ));

  
  getRoles$: Observable<any> = createEffect(() => this.actions.pipe(
    ofType<MetadataActions.GetRoles>(MetadataActionTypes.GetRoles),
    mergeMap(action => {
      return this.metadataService.getRoles().pipe(
        map(roles => {
          return new MetadataActions.GetRolesSuccess({ roles });
        }),
        catchError(error => {
          console.error("Error: ", error);
          return of(new MetadataActions.GetRolesFailure());
        })
      );
    })
  ));

  
  getActivityTypes$: Observable<Action> = createEffect(() => this.actions.pipe(
    ofType<MetadataActions.GetActivityTypes>(
      MetadataActionTypes.GetActivityTypes
    ),
    mergeMap(action => {
      return this.metadataService.getActivityTypes().pipe(
        map(activityTypes => {
          return new MetadataActions.GetActivityTypesSuccess({ activityTypes });
        }),
        catchError(error => {
          console.error("Error: ", error);
          return of(new MetadataActions.GetActivityTypesFailure());
        })
      );
    })
  ));


  
  getPasswordStatusList$: Observable<any> = createEffect(() => this.actions.pipe(
    ofType<MetadataActions.GetPasswordStatusList>(MetadataActionTypes.GetPasswordStatusList),
    mergeMap(action => {
      return this.metadataService.getPasswordStatusList().pipe(
        map(passwordStatusList => {
          return new MetadataActions.GetPasswordStatusListSuccess({ passwordStatusList });
        }),
        catchError(error => {
          console.error("Error: ", error);
          return of(new MetadataActions.GetPasswordStatusListFailure());
        })
      );
    })
  ));

  
  openInformationDialog = createEffect(() => this.actions.pipe(
    ofType<MetadataActions.OpenInformationDialog>(
      MetadataActionTypes.OpenInformationDialog
    ),
    exhaustMap(action => {
      const dialogRef = this.dialog.open(InformationDialogComponent, {
        autoFocus: false,
        data: { title: action.payload.title, information: action.payload.information}
      });
      return dialogRef.afterClosed();
    }),
    map((data: any) => {
      return new MetadataActions.CloseInformationDialog();
    })
  ));

}
