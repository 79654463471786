import { Component, OnInit, Inject, OnDestroy } from "@angular/core";
import { UntypedFormGroup, UntypedFormControl, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { User } from "src/app/models/user.model";
import { Contact } from "src/app/models/contact.model";
import { AddUserRequest } from "src/app/models/add-user-request.model";
import { Role } from "src/app/models/role.model";
import { Store, select } from "@ngrx/store";
import * as fromReducers from "../../../../../store/reducers";
import {
  map,
  debounceTime,
  distinctUntilChanged,
  withLatestFrom
} from "rxjs/operators";
import { Observable, combineLatest, Subscription } from "rxjs";
import { Customer } from "src/app/models/customer.model";
import { TypeaheadMatch } from "ngx-bootstrap/typeahead";
import { SupportActivity } from 'src/app/models/support-activity.model';
import * as SupportActivityActions from 'src/app/store/actions/support-activity.actions';
import { ActivityParty } from 'src/app/models/activity-party.model';

@Component({
  selector: 'app-add-activity-dialog',
  templateUrl: './add-activity-dialog.component.html',
  styleUrls: ['./add-activity-dialog.component.scss']
})
export class AddActivityDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<AddActivityDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private store: Store<fromReducers.State>
  ) {}

  createNewActivityForm: UntypedFormGroup = new UntypedFormGroup({
    subjectControl: new UntypedFormControl("", [Validators.required]),
    descriptionControl: new UntypedFormControl("")
  });

  loggedInUser$ = this.store.pipe(select(fromReducers.getLoggedInUser));
  userSubscription;
  currentUser: User;
  

  createNewActivity(){

    if (this.createNewActivityForm.invalid) {
      return;
    } else {
      const selectedSubject: string = this.createNewActivityForm.get("subjectControl").value;
      const selectedDescription: string = this.createNewActivityForm.get("descriptionControl").value;
      const currentContact = {id: this.currentUser.crmContact.id, emailAddress: this.currentUser.email} as Contact


      const newActivity = {
        subject: selectedSubject,
        description: selectedDescription,
        contact: currentContact,
        owner: this.data.currentCase.owner
      } as SupportActivity;
      this.store.dispatch(new SupportActivityActions.AddNewSupportActivity({ activity: newActivity,openActivity: true }));
    
      this.dialogRef.close();
    }    
  }

  ngOnInit() {
    this.userSubscription = this.loggedInUser$.subscribe(u=>{
      if(u != null){
        this.currentUser = u;
      }
    });
  }
  ngOnDestroy(){
    if(this.userSubscription != null){
      this.userSubscription.unsubscribe();
    }
  }

  updateVisibleContacts() {
    // if (this.selectedCustomer == null) {
    //   this.visibleContacts = [];
    // } else {
    //   this.visibleContacts = this.allContacts.filter(
    //     x => x.customer.id === this.selectedCustomer.id
    //   );
    // }
  }
}

