import { Injectable } from "@angular/core";
import { BaseService } from "./base.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Contact } from "../models/contact.model";
import { User } from "../models/user.model";
import { environment } from "src/environments/environment";
import { AddUserRequest } from "../models/add-user-request.model";
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';

const API_URL = `${environment.apiUrl}` + "/users/";

@Injectable({
  providedIn: "root"
})
export class UserService extends BaseService {
  constructor(private http: HttpClient,private router: Router) {
    super();
  }

  private options = {
    headers: new HttpHeaders().set("Content-Type", "application/json")
  };

  getUsers() {
    return this.http.get<User[]>(API_URL, this.options);
  }

  // add user as admin
  addNewPortalUser(addUserRequest: AddUserRequest) {
    console.log(addUserRequest);
    console.log(JSON.stringify(addUserRequest));

    return this.http.post<User>(
      API_URL,
      JSON.stringify(addUserRequest),
      this.options
    ).pipe(
      tap(newlyCreatedUser => {
        if(newlyCreatedUser.passwordUrl != null){
          // console.log("manual password setup selected");
          // window.location.href=newlyCreatedUser.passwordUrl;
          console.log("manual password not supported");
        };
        
      })
    );
  }

  toggleUserIsActivate(userId: number,currentUserId: number) {
    return this.http.post<User>(
      API_URL+"toggle/"+userId+"/"+currentUserId,
      this.options
    );
  }

  changeCustomerUserType(userId: number) {
    console.log("changing user type for user: ",userId);

    return this.http.post<User>(
      API_URL+"change-user-type/"+userId,
      this.options
    );
  }

  deleteUser(userId: number) {
    return this.http.post<User>(
      API_URL+"delete/"+userId,
      this.options
    );
  }

  sendWelcomeEmail(user: User) {
    return this.http.post<User>(
      API_URL+"email/"+user.id,
      this.options
    ).pipe(
      tap(user1 => {
        user1.role = user.role;        
      })
    );
  }

  // // request user as super user
  // requestUser(reqUser: RequestUser) {
  //   console.log("Requesting user",JSON.stringify(reqUser));
  //   return this.http.post<User>(
  //     API_URL+"req-user",
  //     JSON.stringify(reqUser),
  //     this.options
  //   );
  // }

}
