<div *ngIf="loggedInUser$ | async" [class.alternative]="otherTheme">
  <nav class="navbar navbar-expand-lg navbar-dark" color="primary">
    <a class="navbar-brand" [routerLink]="['/']">
      <img
        src="../../assets/logos/logo_new.svg"
        id="icon"
        height="50.36px"
        width="250px"
        alt="Simpson Associates"
      />
    </a>
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarNavDropdown"
      aria-controls="navbarNavDropdown"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon-support-portal"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNavDropdown">
      <ul class="navbar-nav">
        <li class="nav-item" id="homeLink">
          <a class="nav-link" [routerLink]="['/']">Home</a>
        </li>
        <li class="nav-item dropdown" id="casesLink">
          <a
            class="nav-link dropdown-toggle"
            href="#"
            id="navbarDropdownMenuLink"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Cases
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
            <a class="dropdown-item" [routerLink]="['/cases/my-cases']"
              >My Cases</a
            >
            <a class="dropdown-item" [routerLink]="['/cases/company-cases']"
              >Company Cases</a
            >
            <a
              class="dropdown-item"
              [routerLink]="[]"
              (click)="openNewCaseDialog()"
              >New Case</a
            >
          </div>
        </li>
        <!-- TODO: Update logged in User role Id from db -->
        <li
          class="nav-item"
          *ngIf="
            (loggedInUser$ | async)?.isSimpsonsAdmin ||
            (loggedInUser$ | async)?.role?.name == 'Customer Superuser'
          "
        >
          <a class="nav-link" [routerLink]="['/users']">Users</a>
        </li>
        <li
          class="nav-item"
          *ngIf="
            (loggedInUser$ | async)?.isSimpsonsAdmin ||
            (loggedInUser$ | async)?.role?.name == 'Customer Superuser'
          "
        >
          <a class="nav-link" [routerLink]="['/tokens']">Tokens</a>
        </li>

        <!-- <li
        class="nav-item dropdown"
        id="manageLink"
        *ngIf="(loggedInUser$ | async)?.isSimpsonsAdmin || (loggedInUser$ | async)?.role?.name == 'Customer Superuser'"
      >
        <a
          class="nav-link dropdown-toggle"
          href="#"
          id="navbarDropdownMenuLink"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          Manage
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
          <a class="dropdown-item" [routerLink]="['/tokens']">Tokens</a>
          <a class="dropdown-item" [routerLink]="['/reports']">Reports</a>
          <a class="dropdown-item" [routerLink]="['/users']">Users</a>
        </div>
      </li> -->
      </ul>
      <ul class="navbar-nav ml-auto nav-flex-icons">
        <li
          class="nav-item dropdown"
          *ngIf="loggedInUser$ | async as loggedInUser"
        >
          <a
            class="nav-link dropdown-toggle"
            href="#"
            id="navbarDropdownMenuLink-333"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            (click)="updateCookies()"
          >
            {{ loggedInUser.email }}
          </a>
          <div
            class="dropdown-menu dropdown-menu-right dropdown-default"
            aria-labelledby="navbarDropdownMenuLink-333"
          >
            <div matTooltip="Enable cookies in Profile" *ngIf="!cookiesAllowed">
              <button class="dropdown-item" disabled>Theme</button>
            </div>
            <button
              class="dropdown-item"
              (click)="changeTheme()"
              *ngIf="cookiesAllowed"
            >
              Theme
            </button>
            <!-- <a class="dropdown-item" [routerLink]="['/profile']">Profile</a> -->
            <a
              class="dropdown-item"
              [routerLink]="[]"
              (click)="openProfileDialog()"
              >Profile</a
            >
            <a class="dropdown-item" (click)="logout()" style="cursor: pointer"
              >Sign Out</a
            >
          </div>
        </li>
      </ul>
    </div>
  </nav>
  <div style="text-align: center" class="bg-warning" *ngIf="impersonatingUser">
    <mat-icon style="vertical-align: sub">supervised_user_circle</mat-icon
    >Viewing as {{ impersonatingUserName }} |
    <button
      mat-button
      style="color: red"
      matTooltip="End Customer View"
      (click)="endImpersonation()"
    >
      <mat-icon>clear</mat-icon>
    </button>
  </div>
  <!-- <mat-progress-bar 
  mode="indeterminate" 
  *ngIf="(
          isTogglingUserIsActive$ ||
          isCreatingCase$ || 
          isClosingCase$
         )
        | async"> -->
  <mat-progress-bar
    mode="indeterminate"
    *ngIf="isChangingCustomerUserType$ | async"
  ></mat-progress-bar>
  <mat-progress-bar
    mode="indeterminate"
    *ngIf="isTogglingUserIsActive$ | async"
  ></mat-progress-bar>
  <mat-progress-bar
    mode="indeterminate"
    *ngIf="isCreatingCase$ | async"
  ></mat-progress-bar>
  <mat-progress-bar
    mode="indeterminate"
    *ngIf="isClosingCase$ | async"
  ></mat-progress-bar>
  <mat-progress-bar
    mode="indeterminate"
    *ngIf="isLoadingSupportActivities$ | async"
  ></mat-progress-bar>
  <mat-progress-bar
    mode="indeterminate"
    *ngIf="isAddingNewUser$ | async"
  ></mat-progress-bar>
  <mat-progress-bar
    mode="indeterminate"
    *ngIf="isLoadingContacts$ | async"
  ></mat-progress-bar>
  <mat-progress-bar
    mode="indeterminate"
    *ngIf="isLoadingEntitlements$ | async"
  ></mat-progress-bar>
</div>
