import { Action } from "@ngrx/store";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import {
  EntitlementActionTypes,
  EntitlementActions
} from "../actions/entitlement.actions";
import { Entitlement } from 'src/app/models/tokens/entitlement.model';
import { TokenEvent } from 'src/app/models/tokens/token-event.model';

export const entitlementFeatureKey = "entitlement";

export interface State extends EntityState<Entitlement> {
  isLoading: boolean;
  isLoadingTokenEvents: boolean;
  defaultEntitlement: Entitlement;
  tokenEvents: TokenEvent[];
}

export const adapter: EntityAdapter<Entitlement> = createEntityAdapter<Entitlement>();

export const initialState: State = adapter.getInitialState({
  isLoading: false,
  isLoadingTokenEvents: false,
  defaultEntitlement: null,
  tokenEvents: []
});

export function reducer(state = initialState, action: EntitlementActions): State {
  switch (action.type) {
    case EntitlementActionTypes.GetEntitlements: {
      return {
        ...state,
        isLoading: true,

      };
    }

    case EntitlementActionTypes.GetEntitlementsSuccess: {
      const cleanState = adapter.removeAll(state);
      const newState = adapter.upsertMany(action.payload.entitlements, cleanState);
      return {
        ...newState,
        isLoading: false,
        defaultEntitlement: action.payload.entitlements.find(e=>e.isDefault)
      };
    }

    case EntitlementActionTypes.GetEntitlementsFailure: {
      return {
        ...state,
        isLoading: false
      };
    }


      case EntitlementActionTypes.GetTokenEvents: {
        return {
          ...state,
          isLoadingTokenEvents: true
        };
      }
  
      case EntitlementActionTypes.GetTokenEventsSuccess: {
        return {
          ...state,
          isLoadingTokenEvents: false,
          tokenEvents: action.payload.tokenEvents
        };
      }
  
      case EntitlementActionTypes.GetTokenEventsFailure: {
        return {
          ...state,
          isLoadingTokenEvents: false
        };
    }
    default:
      return state;
  }
}

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal
} = adapter.getSelectors();

export const getIsLoading = (state: State) => state.isLoading;
export const getIsLoadingTokenEvents = (state: State) => state.isLoadingTokenEvents;
export const getDefaultEntitlement = (state: State) => state.defaultEntitlement;
export const GetTokenEvents = (state: State) => state.tokenEvents;


