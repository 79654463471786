import { Injectable } from "@angular/core";
import { NotificationService } from "./notification.service";

@Injectable({
  providedIn: "root"
})
export class ErrorService {
  constructor(private notificationService: NotificationService) {}

  handleError(error: any, prependMessage: string = null): string {
    console.log(error);

    let applicationError = error.headers.get("Application-Error");

    // either application-error in header or model error in body
    if (applicationError) {
      if (prependMessage) {
        applicationError = prependMessage + ": " + applicationError;
      }
      this.notificationService.showError(applicationError, "Close");
      return applicationError;
    }

    let modelStateErrors = prependMessage ? prependMessage + ": " : "";

    // for now just concatenate the error descriptions, alternative we could simply pass the entire error response upstream
    if (typeof error.error === "string") {
      modelStateErrors += error.error;
    } else {
      for (const key in error.error) {
        if (error.error[key]) {
          modelStateErrors += error.error[key].description + "\n";
        }
      }
    }
    console.log(modelStateErrors);

    modelStateErrors = modelStateErrors = "" ? null : modelStateErrors;
    const errorMessage = modelStateErrors || "Server error";
    this.notificationService.showError(errorMessage, "Close");
    return errorMessage;
  }
}
