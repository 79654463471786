import { Component, OnInit, Inject } from "@angular/core";
import { UntypedFormGroup, UntypedFormControl, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import * as fromReducers from "../../../../store/reducers";
import { select, Store } from '@ngrx/store';
import { StatusReason } from 'src/app/models/status-reason.model';

@Component({
  selector: "app-close-case-dialog",
  templateUrl: "./close-case-dialog.component.html",
  styleUrls: ["./close-case-dialog.component.scss"]
})
export class CloseCaseDialogComponent implements OnInit {
  constructor(
    private store: Store<fromReducers.State>,
    public dialogRef: MatDialogRef<CloseCaseDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  closeCaseForm: UntypedFormGroup = new UntypedFormGroup({
    closureReasonControl: new UntypedFormControl("", [Validators.required])
  });

  closureStatusCodes: StatusReason[] = [];
  reasons$ = this.store.pipe(select(fromReducers.getStatusReasonCodesAll));
  ngOnInit() {
    this.reasons$.subscribe(reasons=>{
      this.closureStatusCodes = reasons.slice(7,17);
  });
  }

  closeCase() {
    if (this.closeCaseForm.invalid) {
      return;
    }
    var statusCodeInp = this.closeCaseForm.get("statusControl").value as StatusReason;
    var statusCodeLabel = statusCodeInp.label;
    var statusCodeObject = this.closureStatusCodes.filter(x=>x.label==statusCodeLabel)[0];
    const statusCode = +statusCodeObject.value;
    console.log("label: "+statusCodeLabel+", string : "+statusCodeObject+", int: "+statusCode);

    const closureReason: string = this.closeCaseForm.get("closureReasonControl")
      .value;

    this.dialogRef.close({
      caseCrmId: this.data.caseCrmId,
      closureReason,
      statusCode
    });
  }
}
