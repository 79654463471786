import { Component, OnInit, EventEmitter, Inject } from "@angular/core";

// NGX UPLOADER
import {
  UploadInput,
  UploadFile,
  UploadOutput,
  UploaderOptions
} from "ngx-uploader";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Store, select } from "@ngrx/store";
import * as fromReducers from "../../../../store/reducers";
import * as CaseActions from "../../../../store/actions/case.actions";
import * as SupportActivityActions from "../../../../store/actions/support-activity.actions";
import { NotificationService } from "src/app/services/notification.service";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { SupportActivity } from 'src/app/models/support-activity.model';
import { User } from 'src/app/models/user.model';
import { Contact } from 'src/app/models/contact.model';

@Component({
  selector: "app-quick-upload-dialog",
  templateUrl: "./quick-upload-dialog.component.html",
  styleUrls: ["./quick-upload-dialog.component.scss"]
})
export class QuickUploadDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<QuickUploadDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private store: Store<fromReducers.State>,
    private notificationService: NotificationService
  ) {}

  // uploadFileForm = new FormGroup({
  //   subjectControl: new FormControl("", Validators.required),
  //   descriptionControl: new FormControl("")
  // });

  uploadOptions: UploaderOptions = {
    concurrency: 1
  };
  uploadInput: EventEmitter<UploadInput> = new EventEmitter<UploadInput>();
  files: UploadFile[] = [];
  dragOver: boolean;
  url: string;
  disableButtons = false;
  latestActivitySubscription;
  latestActivity$ = this.store.pipe(select(fromReducers.getLatestActivity));

  loggedInUser$ = this.store.pipe(select(fromReducers.getLoggedInUser));
  userSubscription;
  currentUser: User;

  isUploadingAttachment$ = this.store.pipe(
    select(fromReducers.getSupportActivitiesIsUploadingAttachment)
  );

  ngOnInit() {
    this.userSubscription = this.loggedInUser$.subscribe(u=>{
      if(u != null){
        this.currentUser = u;
      }
    });
  }
  ngOnDestroy(){
    if(this.latestActivitySubscription != null){
      this.latestActivitySubscription.unsubscribe();
    }
    if(this.userSubscription != null){
      this.userSubscription.unsubscribe();
    }
  }

  onUploadOutput(output: UploadOutput): void {
    console.log(output);
    switch (output.type) {
      case "allAddedToQueue":
        // uncomment this if you want to auto upload files when added
        // const event: UploadInput = {
        //   type: 'uploadAll',
        //   url: '/upload',
        //   method: 'POST',
        //   data: { foo: 'bar' }
        // };
        // this.uploadInput.emit(event);
        break;
      case "addedToQueue":
        // Uncomment the code below to allow multiple files to be uploaded at once
        // if (typeof output.file !== 'undefined') {
        //   this.files.push(output.file);
        // }

        if (typeof output.file !== "undefined") {
          this.files = [];
          this.files.push(output.file);
        }
        break;
      case "uploading":
        if (typeof output.file !== "undefined") {
          // update current data in files array for uploading file
          const index = this.files.findIndex(
            file =>
              typeof output.file !== "undefined" && file.id === output.file.id
          );
          this.files[index] = output.file;
        }
        break;
      case "removed":
        // Remove the file from the array when removed
        this.files = this.files.filter(
          (file: UploadFile) => file !== output.file
        );
        break;
      case "rejected":
        console.log(output.file.name + " rejected");
        this.notificationService.showError(
          "Invalid file type (" +
            output.file.type +
            "). Please upload an Excel questionnaire file",
          "Close"
        );
        break;
      case "dragOver":
        this.dragOver = true;
        break;
      case "dragOut":
      case "drop":
        this.dragOver = false;
        break;
      case "done":
        // The file upload is done via an external package, hence this doesn't follow the normal flow for handling errors in the effect
        // Therefore, handle the errors here
        if (output.file.responseStatus !== 200) {
          let errorMessage =
            "Sorry, we're having trouble uploading the file. Please try again later.";
          if (output.file.responseStatus === 500) {
            errorMessage =
              "Sorry, we're having trouble uploading the file. Please try again later.";
            // This response is given if the user has the file open while trying to upload
          } else if (output.file.responseStatus === 0) {
            errorMessage =
              "Sorry, we're having trouble uploading the file. Please make sure the file is closed and not being used by another program.";
          } else {
            errorMessage = output.file.response;
          }
          this.notificationService.showError(errorMessage, "Close");
          this.store.dispatch(
            new SupportActivityActions.UploadSupportActivityAttachmentFailure()
          );
        } else {
          this.dialogRef.close();
          this.notificationService.showSuccess(
            "Thank you, the file has been successfully uploaded.",
            "Close"
          );
          this.store.dispatch(
            new SupportActivityActions.UploadSupportActivityAttachmentSuccess()
          );
          this.store.dispatch(
            new SupportActivityActions.GetSupportActivitesForCase({caseCrmId:this.data.caseCrmId})
          );
          this.store.dispatch(
            new SupportActivityActions.GetSupportActivitesForCase({caseCrmId:this.data.caseCrmId})
          );
          this.store.dispatch(
            new SupportActivityActions.GetAttachmentsForCase({caseCrmId:this.data.caseCrmId})
          );
        }
        // The file is downloaded
        break;
    }
  }

  getNumber(x: string){
    return +x;
  }

  startUpload(): void {
    

    const event: UploadInput = {
      type: "uploadFile", // Change to uploadAll to allow multiple file uploads at once
      file: this.files[0], // Remove this line to allow multiple file uploads at once
      url: this.url,
      method: "POST",
      data: {
        subject: this.files[0].name,
        description: ""
      }
    };

    this.uploadInput.emit(event);

    // Close the dialog, and trigger the effect
    this.store.dispatch(
      new SupportActivityActions.UploadSupportActivityAttachment({
        caseCrmId: this.data.caseCrmId,
        activityCrmId: this.data.activityCrmId
      })
    );
    // this.dialogRef.close();
  }

  cancelUpload(id: string): void {
    this.uploadInput.emit({ type: "cancel", id });
    this.store.dispatch(
      new SupportActivityActions.UploadSupportActivityAttachmentFailure()
    );
  }

  removeFile(id: string): void {
    this.uploadInput.emit({ type: "remove", id });
  }

  removeAllFiles(): void {
    this.uploadInput.emit({ type: "removeAll" });
  }

  startUploadProcess(){
    if (
      this.files.length === 0 ||
      this.files.filter(
        x => x.responseStatus == null || x.responseStatus !== 200
      ).length === 0
    ) {
      this.notificationService.showWarning(
        "Please select a file to upload",
        "Close"
      );
      return;
    }

    // if (this.uploadFileForm.invalid) {
    //   return;
    // }

    this.disableButtons = true;

    const currentContact = {id: this.currentUser.crmContact.id, emailAddress: this.currentUser.email} as Contact
    const newActivity = {
      subject: "Attached file to case",
      description: "",
      contact: currentContact,
      owner: this.data.case.owner

    } as SupportActivity;
    this.store.dispatch(new SupportActivityActions.AddNewSupportActivity({ activity: newActivity, openActivity: false }));
    this.latestActivitySubscription = this.latestActivity$.subscribe(activity=>{
      if(activity != null){
        this.data.activityCrmId = activity.id;
        this.url =
        "/api/cases/" +
        this.data.caseCrmId +
        "/activities/" +
        activity.id +
        "/attachments/upload";
        // console.log("activityId",this.data.activityCrmId);
        // console.log("files",this.files);
        // console.log("url",this.url);
        this.startUpload();
      }
    });
  }


}
